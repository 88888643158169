import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Software Development",
        para: "At our company, we are always looking for ways to incorporate IT innovations into our work. Our team has a wide range of skills and experience with different types of software development."
    },
    {
        id: 2,
        title: "Mobile Applications",
        para: "At Origin Softwares, we develop mobile apps that are designed to either supplement or replace web solutions. With 20+ mobile app development projects under our belt."
    },
    {
        id: 3,
        title: "Web & CMS Development",
        para: "We help brands use powerful new technologies to develop innovative cloud-based web applications that improve efficiency and exceed customer expectations."
    },
    {
        id: 4,
        title: "SEO & Digital Marketing",
        para: "Our Digtal Marketing services include marketing and awareness, traffic and leads, wider reach, and more. We can help you make the most of your digital presence and achieve your business goals."
    },
    {
        id: 5,
        title: "Corporate Branding",
        para: "We offer a full range of corporate branding services, from market research and brand strategy to brand design and advertising. Branding identity can be a lot like navigation."
    },
    {
        id: 6,
        title: "IT Staffing",
        para: "we pride ourselves on being a leading global IT staffing service provider. We use technology to identify competent full-time IT professionals or software developers."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Services"
                    title="Your Trusted IT Automation Partner"
                    description="We are an Information Technology services company providing automation needs of businesses across various domains and are promoted by a group of qualified and experienced professionals from the Information Technology industry, having a combined business experience of more than 09 man years."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;