import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import SEO from "../common/SEO";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import Technology from "../component/Technologies/Technology.js";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  services: [
    { title: "Software Consulting", link: "/Software-Consulting" },
    {
      title: "Custom Software Development",
      link: "/Custom-Software-Development-Company-in-India",
    },
    {
      title: "Software Development Outsourcing",
      link: "/Software-Development-Outsourcing",
    },
    {
      title: "Software Product Development",
      link: "/Software-Product-Development",
    },
    {
      title: "Software Development Automation",
      link: "/Software-Development-Automation",
    },
    {
      title: "Software Maintenance and support",
      link: "/Software-Maintenance-and-Support",
    },
  ],
};

const allData = [
  {
    id: 1,
    text: (
      <>
        <h6>Software Check</h6>
        <p>
          A software stack assessment is important for businesses to understand
          how their current software is performing and if it meets their needs.
          <br></br>
          By conducting an assessment, businesses can plan improvements to
          resolve any detected flaws and inefficiencies.<br></br>
          Suggested action points from the assessment may include software
          modernization, refactoring, or migration.
        </p>
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        <h6>Software Architecture Redesign</h6>
        <p>
          A software architecture assessment & redesign is necessary when there
          are flaws in the architecture that are affecting performance and
          security or limiting scalability.<br></br>
          The redesign focuses on resilience, quick changes, and optimized
          maintenance costs.
        </p>
      </>
    ),
  },
  {
    id: 3,
    text: (
      <>
        <h6>Software Modernization</h6>
        <p>
          Software Modernization can be a tricky process- you want to ensure
          that your software is up-to-date and using the best programming
          technologies, but you also don't want to disrupt any critical business
          operations.<br></br>
          Gradually transitioning your software is one way to minimize
          disruptions.
        </p>
      </>
    ),
  },
  {
    id: 4,
    text: (
      <>
        <h5>Software Development consulting</h5>
        <p>
          If you need assistance developing software solutions to cover your
          business needs, software consulting services may be the way to go.
        </p>
      </>
    ),
  },
  {
    id: 5,
    text: (
      <>
        <h5>Project management consulting</h5>
        <p>
          Project management consulting can help you set up processes and
          controls to keep project deliverables, schedules, and costs on track.
          By enabling continuous software delivery, you can accelerate project
          development and ensure successful completion.
        </p>
      </>
    ),
  },
  {
    id: 6,
    text: (
      <>
        <h5>Software product consulting</h5>
        <p>
          At Origin Softwares, we help get your software product ready for
          market entry. We plan and prioritize product features, keeping your
          future customers in mind. We also build multi-tenant architecture and
          provide skilled testing engineers to save you time and money.
        </p>
      </>
    ),
  },
  {
    id: 7,
    text: (
      <>
        <h5>Software Consulting</h5>
        <p>
          There's a lot that goes into making sure your software is compliant
          with industry laws and regulations. We can help you assess your
          software and figure out what requirements you need to license your
          product.
        </p>
      </>
    ),
  },
  {
    id: 8,
    text: (
      <>
        <h5>Business process analysis</h5>
        <p>
          Business process analysis is important for any business that wants to
          improve. By auditing your processes and planning improvements with
          software-only changes, you can increase efficiency, productivity, and
          profitability.
        </p>
      </>
    ),
  },
  {
    id: 9,
    text: (
      <>
        <h5>QA process setup</h5>
        <p>
          Technology is always changing, but our expert solution architects and
          software developers are experienced in a wide range of technologies.
          We can provide software development consulting services across many
          platforms.
        </p>
      </>
    ),
  },
  {
    id: 10,
    text: (
      <>
        <h5>Setting up DevOps processes</h5>
        <p>
          We can help you build an effective DevOps pipeline to speed up your
          software delivery process.
        </p>
      </>
    ),
  },
  {
    id: 11,
    text: (
      <>
        <h5>Team Augmentation</h5>
        <p>
          We can provide additional staff resources to support any role in your
          development team.
        </p>
      </>
    ),
  },
];

const SoftwareConsulting = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <SEO title="Software Consulting" />
      <main className="main-wrapper">
        <HeaderOne />
        <SideStickButton />
        <div className=" section-padding-equal">
          <div className="container">
            <div className="row row-40">
              <div className="col-lg-3 why-choose-us">
                <div className="sidebar-fixed">
                  <Accordion defaultActiveKey="1">
                    <AccordionItem
                      eventKey="1"
                      header="Software Development"
                      body={
                        <>
                          <ul className="a">
                            <li>
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  "/Software-Consulting"
                                }
                              >
                                {" "}
                                Software Consulting
                              </Link>
                            </li>
                            <br />
                            <li>
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  "/Custom-Software-Development-Company-in-India"
                                }
                              >
                                Custom Software Development
                              </Link>
                            </li>
                            <br />
                            <li>
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  "/Software-Development-Outsourcing"
                                }
                              >
                                {" "}
                                Software Development Outsourcing
                              </Link>
                            </li>
                            <br />
                            <li>
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  "/Software-Product-Development"
                                }
                              >
                                {" "}
                                Software Product Development
                              </Link>
                            </li>
                            <br />
                            <li>
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  "/Software-Development-Automation"
                                }
                              >
                                {" "}
                                Software Development Automation
                              </Link>
                            </li>
                            <br />
                            <li>
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  "/Software-Maintenance-and-Support"
                                }
                              >
                                {" "}
                                Software Maintenance and support
                              </Link>
                            </li>
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
              <div className="title-BC col-lg-9">
                <h1 className="color">Software Consulting</h1>
                <p>
                  <img
                    className="img mb--20"
                    src="../images/Software Consulting.png"
                    alt="Software-Consulting"
                  ></img>
                  Origin Softwares is a software consulting company that offers
                  expertise, and resources for software development projects.
                  With over 9 Years of success stories across various IT needs,
                  Origin Softwares can provide software consulting services for
                  different software solutions and industries.<br></br>
                  <Link
                    to={process.env.PUBLIC_URL + "/contact"}
                    className="axil-btn btn-fill-primary btn-small"
                  >
                    Get a Quote
                  </Link>
                  <br></br>
                  <br></br>
                </p>
                <div>
                  <div className="row">
                    <h3 className="mb--20">
                      Our Software Consulting Service Scope
                    </h3>
                    {allData.slice(0, 3).map((item) => (
                      <div key={item.id} className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="container mt-3">
                    <div className="row">
                      <h3 className="mb--20">Our Software Technologies</h3>
                      <div>
                        <Technology />
                      </div>
                    </div>
                  </div>
                  <h3 className="mb--20">Selection of software</h3>
                  <p>
                    When it comes to selecting software, it's important to
                    choose a vendor and pricing plan that fit your specific
                    requirements. A software comparison summary can be helpful
                    in pointing you in the right direction.
                  </p>
                  <div className="row">
                    {allData.slice(3, 11).map((item) => (
                      <div key={item.id} className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    <h4 className="mb--20">Our Services</h4>
                    {jsonData.services.map((service, index) => (
                      <div key={index} className="col-xl-6 col-lg-2">
                        <ul className="b">
                          <li>
                            <a href={process.env.PUBLIC_URL + service.link}>
                              {service.title}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
                <Accordion>
                  <h4 className="color">Software Consulting FAQ's</h4>
                  <AccordionItem
                    eventKey="1"
                    header="What client say about the origin software?"
                    body={
                      <>
                        <ul className="a">
                          <li>
                            {" "}
                            “ Really appreciate Origin Software Team. I’ve
                            worked with quite a few SEO firms and so far,
                            they’ve done the best. Thanks for supporting my
                            business as I needed.” -Nouman Dawer
                          </li>
                          <li>
                            {" "}
                            “ The team has put in extraordinary efforts to
                            finish the project and helped our business grow
                            fast. Very focused and dedicated collaboration.
                            Thanks.” -Mohammed Jawad
                          </li>
                          <li>
                            {" "}
                            “ One of the finest organisation have been
                            associated with in the recent times energetic with
                            full young enthusiastic team keep it up guys.”
                            -Owais Shamsuddin
                          </li>
                          <li>
                            {" "}
                            “ One of the finest organization to collaborate with
                            very awesome back hand team to support go a head
                            team origin good luck.” -Farha Ansari
                          </li>
                        </ul>
                      </>
                    }
                  />
                  <AccordionItem
                    eventKey="2"
                    header="Why Contact the origin software consulting company?"
                    body={
                      <>
                        <ul>
                          <p>
                            ► Technology is always changing, but our expert
                            solution architects and software developers are
                            experienced in a wide range of technologies and can
                            provide software consulting services across many
                            platforms.<br></br>
                            <Link
                              to={process.env.PUBLIC_URL + "/contact"}
                              className="axil-btn btn-fill-primary btn-small"
                            >
                              Contact Us
                            </Link>
                          </p>
                        </ul>
                      </>
                    }
                  />
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <FooterOne />
      </main>
    </>
  );
};

export default SoftwareConsulting;
