import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const Resources = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>
            Staffing Resources - A complete staffing solution| Origin Software
          </title>
          <link
            rel="canonical"
            href="https://www.originsoftwares.com/Staffing-Resources"
          />
          <meta
            name="description"
            content="Origin software Staffing Resources is a complete staffing solution. We are your partner in all aspects of your staffing needs."
          />
          <meta name="keywords" content="STAFFING RESOURCES" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="IT Staffing"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Recruitment-Services"
                                  }
                                >
                                  {" "}
                                  Recruitment
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Staffing-Resources"
                                  }
                                >
                                  Resourses
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Contract-Staffing-Services"
                                  }
                                >
                                  {" "}
                                  Contract Staffing
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={process.env.PUBLIC_URL + "/Direct-Hire"}
                                >
                                  {" "}
                                  Direct Hire
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL + "/Executive-Search"
                                  }
                                >
                                  {" "}
                                  Executive Search
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Offshore-Staffing"
                                  }
                                >
                                  {" "}
                                  Offshore Staffing
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Resources</h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/Resources.png"
                    alt="Staffing-Resources"
                  />
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                      With more than 9 years of experience in the staffing
                      industry,
                    </span>{" "}
                    Origin Softwares Staffing resources Solutions is a leading
                    provider of engineering, design, project management, and
                    other technical and professional personnel to industrial
                    clients worldwide.
                    <br />
                    <Link
                      to={process.env.PUBLIC_URL + "/contact"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      Contact Us
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default Resources;
// 98
