import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  services: [
    { title: "UI Design", link: "/UI-Design-Services" },
    { title: "UX Design", link: "/UX-Design-Services" },
    { title: "Responsive Web Design", link: "/Responsive-Web-Design-Services" },
    { title: "Responsive Web App Design", link: "/Responsive-Web-Application" },
    { title: "Website Redesign", link: "/Website-Redesign-Services" },
  ],
};

const allData = [
  {
    id: 1,
    text: "A mobile-first approach is key when it comes to content scaling, touch-screen gestures, and device-specific keys.",
  },
  {
    id: 1,
    text: "Interacting with the software on a mobile device is very different from a desktop computer, so it's important to design for mobile and desktop as two separate but connected entities.",
  },
  {
    id: 3,
    text: "By starting with mobile design and then moving to desktop design, you can create web solutions that look similar but have independent user experiences that feel natural on all devices.",
  },
  {
    id: 4,
    text: "Origin Softwares design team always strives to bring your business requirements together with the latest responsive website and web portal design trends.",
  },
  {
    id: 5,
    text: " Our team understands that every business is different, so we take the time to discuss all of your workflows and business goals before the project launch.",
  },
  {
    id: 6,
    text: "This way, we can deliver web designs that meet your most prioritized marketing objectives - ensuring a prolonged dwell time, increased user engagement, and improved conversion rate.",
  },
  {
    id: 7,
    text: "At our company, we make sure that you can see the potential results of our responsive web design services from the very beginning of the project.",
  },
  {
    id: 8,
    text: " We carry out extensive market research, business analysis, and user research to give you accurate and reliable data on what to expect – things like visitor session time, user engagement rate, and conversions.",
  },
  {
    id: 9,
    text: "You can request responsive design for your PWA as part of any of our three service options. Plus, all of our options can be delivered as a one-off design or as part of a continuous design process.",
  },
  {
    id: 10,
    text: "Approximately 70% of users do not finish their purchases on websites when they view them on mobile devices due to the impeding mobile UX. In addition, 94% of users state that they do not trust a company if their website or web portal has a poor mobile design.",
  },
  {
    id: 11,
    text: "Because of this, they will choose to switch to a competitor. While you are still evaluating the reasons for your investment, responsive web design has already become one of the major conditions for the success of your digital venture.",
  },
  {
    id: 12,
    text: "Let Origin Softwares handle the responsive design of your website or web portal to make it fully functional and easy to navigate.",
  },
];

const ResponsiveWebDesign = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>Responsive Web Design Services | Origin software</title>
          <link rel="canonical" href="./Responsive-Web-Design-Services" />
          <meta
            name="description"
            content="Origin software is the perfect partner to develop a fully responsive web design solution to fit your individual needs based on services."
          />
          <meta name="keywords" content="responsive web design services" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Website Design"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/UI-Design-Services"
                                  }
                                >
                                  UI Design
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/UX-Design-Services"
                                  }
                                >
                                  {" "}
                                  UX Design
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Responsive-Web-Design-Services"
                                  }
                                >
                                  {" "}
                                  Responsive Web Design
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Responsive-Web-Application"
                                  }
                                >
                                  {" "}
                                  Responsive Web App Design
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Website-Redesign-Services"
                                  }
                                >
                                  {" "}
                                  Website Redesign
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Responsive Web Design</h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/Responsive Web Design.png"
                    alt="Responsive-Web-Design-Services"
                  />

                  <p>
                    Responsive web design services help a web solution adjust to
                    the screen size it is being displayed on. This is important
                    because mobile devices have become increasingly popular as
                    the primary way people access the internet.
                    <br />
                    <Link
                      to={process.env.PUBLIC_URL + "/contact"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      Contact Us
                    </Link>
                  </p>

                  <p>
                    Origin Softwares follows the mobile-first strategy, which
                    means that we prioritize the design of a mobile version of
                    your web solution. This results in a digital experience that
                    is easy to use and converts well.
                    <br />
                    <br />
                    For more information on responsive web design, please see
                    our responsive web app design page.
                    <br />
                    <Link
                      to={process.env.PUBLIC_URL + "/Portfolio"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      Portfolio
                    </Link>
                  </p>

                  <div className="row">
                    <h3>Technologies we Offer</h3>
                    <div>
                      <Technology />
                    </div>
                  </div>

                  <h5>
                    Advantages of Responsive Web Design Services with Origin
                    Softwares
                  </h5>
                  <div className="row">
                    <h5>Mobile-first approach</h5>
                    {allData.slice(0, 3).map((item) => (
                      <div className="Box1 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h5>Driven by your business objectives</h5>
                    {allData.slice(3, 6).map((item) => (
                      <div className="Box1 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row mb--20">
                    <h4>Our Responsive Web Design Services Options </h4>
                    {allData.slice(6, 9).map((item) => (
                      <div className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h4>Make a Sure Investment in Responsive Web Design</h4>
                    {allData.slice(9, 12).map((item) => (
                      <div className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <br />

                  <div className="row">
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className="b">
                          <li>
                            <a href={process.env.PUBLIC_URL + services.link}>
                              {services.title}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3>Our Industries</h3>
                    <div>
                      <Industries />
                    </div>
                  </div>

                  <Accordion>
                    <h4 className="color">Responsive Web Design FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="Do you want your mobile visitors to convert effectively?"
                      body={
                        <>
                          <ul>
                            ► Origin Softwares can help. We deliver responsive
                            websites and web portals that are easy to navigate,
                            have mobile-specific interactive elements, and
                            perform well on any network.
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="2"
                      header="Why Origin Softwares?"
                      body={
                        <>
                          <ul>
                            <p>
                              When it comes to choosing a web development
                              partner, there are many factors to consider. One
                              of the most important is whether or not they offer
                              responsive web design. Here's why it matters:
                            </p>
                            ► Today, over half of all global website traffic
                            comes from mobile devices.
                            <br />
                            ► Google now uses "mobile-first" indexing for all
                            websites, so a responsive design is key for SEO
                            purposes.
                            <br />
                            ►Businesses that have responsive websites see an
                            average 68% increase in sales.
                            <br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="3"
                      header="Why does Responsive Web Design Matter?"
                      body={
                        <>
                          <ul>
                            ► Origin Softwares is a top choice for responsive
                            web design, with over 9 years of experience in IT
                            and a conversion-driven mindset. <br />
                            <br />► Our experienced UI/UX design teams create
                            beautiful, user-friendly designs that are also
                            compliant with SEO and WCAG2 requirements (if
                            requested).
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default ResponsiveWebDesign;
