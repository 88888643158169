import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import TestimonialTwo from "../component/testimonial/TestimonialTwo";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  services: [
    { title: "SEO", link: "/SEO-Services-in-Hyderabad" },
    { title: "SMM", link: "/Social-Media-Marketing-agency-in-India" },
    { title: "PPC", link: "/PPC-Services-India" },
  ],
};

const allData = [
  {
    id: 1,
    text: "At Origin Softwares, we understand that having a strong digital presence is essential for businesses in the modern age. We offer a platform designed to increase your digital presence and grow your business online.",
  },
  {
    id: 2,
    text: "Our digtal marketing services include marketing and awareness, traffic and leads, wider reach, and more. With our experience and industry knowledge, we can help you make the most of your digital presence and achieve your business goals.",
  },
  {
    id: 3,
    text: "At Origin Softwares, we are focused on being the best digital marketing agency in Hyderabad. We offer a wide range of services including SEO, SMM, PPC marketing, Branding services, and Email marketing services.",
  },
  {
    id: 4,
    text: "We are a well-known social media and digital marketing company in Hyderabad that can help improve your overall website performance and conversions.",
  },
  {
    id: 5,
    text: "Origin Softwares provides complete website solutions, which includes various website (including E-commerce) development services, Digital marketing services in Hyderabad, Branding and design.",
  },
  {
    id: 6,
    text: (
      <>
        <h5>Awareness</h5>
        <p>
          Brand awareness is key to successful digital marketing. We create
          quality content that is both creative and interesting, making sure you
          are heard, noticed, and remembered.
        </p>
      </>
    ),
  },
  {
    id: 7,
    text: (
      <>
        <h5>Consideration</h5>
        <p>
          In such a competitive world, it can be difficult to stand out, but we
          help you do so by strategically positioning your brand for maximum
          visibility.
        </p>
      </>
    ),
  },
  {
    id: 8,
    text: (
      <>
        <h5>Conversion</h5>
        <p>
          The goal of digital marketing is to convert potential leads into
          customers. We can help you improve your digital presence to increase
          your chances of generating leads and convert them into customers.
        </p>
      </>
    ),
  },
  {
    id: 9,
    text: (
      <>
        <h5>Engagement</h5>
        <p>
          It's just as important to retain existing customers as it is to
          acquire new ones. We engage your current customers with quality and
          innovative content to keep them coming back for more.
        </p>
      </>
    ),
  },
  {
    id: 10,
    text: (
      <>
        <h6>
          <Link to={process.env.PUBLIC_URL + "/Branding-Agency-in-Hyderabad"}>
            Branding Services
          </Link>
        </h6>
        <p>
          As the competition continues to heat up, it's more important than ever
          for businesses to have a strong branding strategy. Your branding
          should meet the highest standards of excellence in order to stand out
          from the crowd and attract customers. Our branding services in
          Hyderabad are designed to do just that. Our team of creative graphic
          designers have a deep understanding of human psychology and how it
          affects buying decisions. This allows us to create branding that will
          leave a lasting impression on potential customers.
        </p>
      </>
    ),
  },
  {
    id: 11,
    text: (
      <>
        <h6>
          <Link to={process.env.PUBLIC_URL + "/SEO-Services-in-Hyderabad"}>
            SEO Services
          </Link>
        </h6>
        <p>
          SEO is all about targeting the audiences that are most likely to
          convert into your potential customers.And currently, with so many
          ecommerce websites blooming in the market, you need a website that
          really stands out and gets noticed. That's where we come in.At Origin
          Softwares, we specialize in providing the best ecommerce website
          development and SEO services in Hyderabad. With our help, you can
          increase trust, visibility and ultimately sales conversion. So if
          you're looking for the best SEO services in Hyderabad, look no further
          than Origin Softwares
        </p>
      </>
    ),
  },
  {
    id: 12,
    text: (
      <>
        <h6>
          <Link to={process.env.PUBLIC_URL + "/PPC-Services-India"}>
            PPC Services
          </Link>
        </h6>
        <p>
          Different businesses have different needs when it comes to website
          growth. That's why it's important to have an agency that understands a
          variety of optimization tools, like SEM and PPC. With managed search
          engine marketing, you can tailor your website growth strategy to fit
          your specific goals, whether that's increasing brand awareness,
          driving website traffic, or boosting conversions.
        </p>
      </>
    ),
  },
  {
    id: 13,
    text: (
      <>
        <h6>
          <Link
            to={
              process.env.PUBLIC_URL + "/Social-Media-Marketing-agency-in-India"
            }
          >
            SMM Services
          </Link>
        </h6>
        <p>
          We offer a range of Social Media Marketing (SMM) services designed to
          help you transform your social media presence, with high-quality
          content and daily activity. We can integrate into your existing
          infrastructure as a full-service partner or provide specific services
          tailored to your needs. We have experience working with businesses of
          all types, both nationally and internationally.
        </p>
      </>
    ),
  },
];

const DigitalMarketing = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>
            Best Digital Marketing Agency In Hyderabad | Origin software
          </title>
          <link
            rel="canonical"
            href="./Digital-Marketing-Agency-in-Hyderabad"
          />
          <meta
            name="description"
            content="Origin software is a digital marketing agency in Hyderabad that helps SMEs to grow their business with the help of the Internet."
          />
          <meta
            name="keywords"
            content="Digital marketing agency in hyderabad"
          />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Digital Marketing"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/SEO-Services-in-Hyderabad"
                                  }
                                >
                                  SEO
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Social-Media-Marketing-agency-in-India"
                                  }
                                >
                                  SMM
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/PPC-Services-India"
                                  }
                                >
                                  PPC
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Digital Marketing</h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/Digital Marketing.png"
                    alt="Digital-Marketing-Agency-in-Hyderabad"
                  />
                  <p>
                    At our next generation digital marketing agency in
                    Hyderabad, we have a team of experts who know how to digital
                    growth hack. Our team is also creative, dynamic, and
                    collaborative. If you want to learn more about what we can
                    do for you, schedule a call with us now.
                    <br />
                    <Link
                      to={process.env.PUBLIC_URL + "/contact"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      Contact Us
                    </Link>
                  </p>

                  <div className="row mb--20">
                    <h4>Go Digital With Us</h4>
                    {allData.slice(0, 5).map((item) => (
                      <div className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Technologies we Offer</h3>
                    <div>
                      <Technology />
                    </div>
                  </div>

                  <div className="row mb--20">
                    <h4>Our Approach</h4>
                    {allData.slice(5, 9).map((item) => (
                      <div className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <h4 className="mb--20">
                    Our Specialization as Digital Marketing Agency
                  </h4>
                  <img
                    className="thumbnail"
                    src="../images/InfoGraphics/digitalmarketing.png"
                    alt="Thumbnail"
                  />

                  <h4 className="mb--20">Our Portfolio</h4>
                  <Link
                    to={process.env.PUBLIC_URL + "/Portfolio"}
                    className="axil-btn btn-fill-primary btn-small"
                  >
                    {" "}
                    Portfolio
                  </Link>

                  <div className="row">
                    <h4>Our clients</h4>
                    <TestimonialTwo />
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Our Industries</h3>
                    <div>
                      <Industries />
                    </div>
                  </div>

                  <div className="row">
                    <h4>Our Services</h4>
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className="b">
                          <li>
                            <a href={process.env.PUBLIC_URL + services.link}>
                              {services.title}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>

                  <div className="row mb--20">
                    {allData.slice(9, 13).map((item) => (
                      <div className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default DigitalMarketing;
// 346
