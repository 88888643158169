import React from 'react';
import {Link} from 'react-router-dom';
import LocationData from '../../data/contact/LocationData.json';

const allData = LocationData;

const ContactLocation = () => {
    return (
        <>
            {allData.map((data, index) => (
                <div className="col-xl-3 col-sm-6" key={data.id}>
                        <div className="team-grid">
                            <div className="thumbnail">
                                <Link to= {process.env.PUBLIC_URL + `/Contact`}>
                                    <img src={data.thumb} alt={data.title} />
                                </Link>
                            </div>
                            <div className="content">
                                <h4 className="title">
                                    <span to={process.env.PUBLIC_URL + data.title}>
                                        {data.title}
                                    </span>
                                </h4>
                                <span className="designation" dangerouslySetInnerHTML={{__html: data.address}}></span>
                            </div>
                        </div>
                    </div>
            ))}
        </>
    )
}

export default ContactLocation;