import React from 'react';
import TestimonialData from "../../data/testimonial/TestimonialData.json";
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

const allData = TestimonialData;

const TestimonialItem = ({ colSize, itemShow }) => {

    //     const settings = {
    //         dots: true,
    //         arrows: true,
    //         infinite: true,
    //         speed: 1000,
    //         autoplay: true,
    //         autoplayspeed: 5000,
    //         slidesToShow: 3, // Number of testimonials to show at once
    //         slidesToScroll: 1,
    //         prevArrow: <PrevArrow />,
    //         nextArrow: <NextArrow />,
    //     };

    //     function PrevArrow(props) {
    //         return (
    //             <button {...props} className="slick-prev">
    //                 Previous
    //             </button>
    //         );
    //     }

    //     function NextArrow(props) {
    //         return (
    //             <button {...props} className="slick-next">
    //                 Next
    //             </button>
    //         );
    //     }

    return (
        <>
            {/* <Slider {...settings}> */}
            {allData.slice(0, itemShow).map((data, index) => (
                <div className={`${colSize}`}>
                    <div className="testimonial-grid" key={index}>
                        <span className="social-media">
                            <img src={process.env.PUBLIC_URL + data.from} alt="Google" />
                        </span>
                        <p>{data.description}</p>
                        <div className="author-info">
                            <div className="thumb">
                                <img src={process.env.PUBLIC_URL + data.authorimg} alt="Google Review" />
                            </div>
                            <div className="content">
                                <span className="name">{data.authorname}</span>
                                <span className="designation">{data.authordesig}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {/* </Slider> */}
        </>
    )
}

export default TestimonialItem;