import React from 'react';
import { Link } from 'react-router-dom';
import {FaWhatsapp, FaFacebookF, FaTwitter, FaLinkedin, FaInstagram,} from "react-icons/fa";
// FaEnvelopeOpen, FaPinterestP,FaVimeoV, FaDribbble, FaBehance, 
import ServiceData from "../../data/service/ServiceMain.json";
import Logo from '../../elements/logo/Logo';

 // eslint-disable-next-line
const getServiceData = ServiceData;

const FooterOne = ({parentClass}) => {
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                        <li class="social-icons-whatsapp"><a href="https://wa.me/919614085555/" title="Whatsapp"><FaWhatsapp /></a></li>
                        <li class="social-icons-facebook"><a href="https://www.facebook.com/originsoftwares1/" title="Facebook"><FaFacebookF /></a></li>
                        <li class="social-icons-twitter"><a href="https://twitter.com/originsoftware1" title="Twitter"><FaTwitter /></a></li>
                        <li class="social-icons-linkedin"><a href="https://www.linkedin.com/company/originsoftwares/" title="Linkedin"><FaLinkedin /></a></li>
                        <li class="social-icons-instagram"><a href="https://www.instagram.com/originsoftwares/" title="Instagram"><FaInstagram /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <p><Logo limage="/images/logo-4.png"/></p>
                                    
                                    <p>Third Floor, Serene Heights Above Ratnadeep Masab Tank,<br/>
                                        Humayun Nagar Road,<br/>
                                        Hyderabad, Telangana 500028<br/>
                                       Ph no : <a href="tel: +91- 96140 85555">+91-96140 85555</a><br/>
                                       Email : <a href="mailto: info@originsoftwares.com">info@originsoftwares.com</a></p>  
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-6">
                            <div className="row">

                            <div className="col-sm-5">
                                    <div className="footer-widget">
                                    <h6 className="widget-title">Services</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/Web-Development-Company-in-Hyderabad"}>Web Development</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/E-Commerce-Services"}>Ecommerce Services</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/Mobile-App-Development-Services"}>Mobile Apps</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/Branding-Agency-in-Hyderabad"}>Corporate Branding</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/Digital-Marketing-Agency-in-Hyderabad"}>Digital Marketing</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/IT-Staffing-services"}>IT Staffing</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="footer-widget">
                                    <h6 className="widget-title">Resourses</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/Refundandcancellation"}>Refund and Cancellation</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/Career"}>Career</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/Portfolio"}>Portfolio</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/GetAQuote"}>Get a Quote</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                    <h6 className="widget-title">Support</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/TermsOfUse"}>Terms &amp; Conditions</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/PrivacyPolicy"}>Privacy Policy</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/Contact"}>Contact Us</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="https://originsoftwares.com/">Origin Softwares</a>.</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/PrivacyPolicy"}>Privacy Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/TermsOfUse"}>Terms of Use</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;