import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import TestimonialTwo from "../component/testimonial/TestimonialTwo";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const appData = [
  {
    title: "Mobile Apps Development",
    link: "/Mobile-App-Development-Services",
  },
  {
    title: "Digital Marketing Services",
    link: "/Digital-Marketing-Agency-in-Hyderabad",
  },
  { title: "Graphics Designing", link: "/Logo-Design-Company-in-India" },
  { title: "Social Media", link: "/Social-Media-Marketing-agency-in-India" },
];

const jsonData = {
  services: [
    { title: "UI Design", link: "/UI-Design-Services" },
    { title: "UX Design", link: "/UX-Design-Services" },
    { title: "Responsive Web Design", link: "/Responsive-Web-Design-Services" },
    { title: "Responsive Web App Design", link: "/Responsive-Web-Application" },
    { title: "Website Redesign", link: "/Website-Redesign-Services" },
  ],
};

const allData = [
  {
    id: 1,
    text: "A professional website is a key to putting your best foot forward online. That's why our Team stays on top of the latest trends and technologies from around the globe.",
  },
  {
    id: 2,
    text: "We can help you design a website that is both user- and search engine-friendly and reflects your unique business idea.",
  },
  {
    id: 3,
    text: "As a leading web design company in Hyderabad, we always develop new ways to push the envelope with web designing web development.",
  },
  {
    id: 4,
    text: (
      <>
        <h5> The discovery - Coming up with a concept</h5>
        <p>
          stage of website development is critical for success. In this stage,
          we'll clarify the goals and purpose of your project to ensure that our
          vision is clear and focused. It will help us avoid potential problems
          down the road and ensure that our website design and development is
          what our users need.
        </p>
      </>
    ),
  },
  {
    id: 5,
    text: (
      <>
        <h5> Drafting a design</h5>
        <br />
        <p>
          For planning & drafting the design, the Team focuses on creating a
          roadmap that everyone can agree on. It will help ensure that everyone
          agrees and knows what needs to be done to succeed.
        </p>
      </>
    ),
  },
  {
    id: 6,
    text: (
      <>
        <h5>Coding the website</h5>
        <br />
        <p>
          Development is a lot like sculpting. Our Team has a clear vision of
          what you want the result to look like and works tirelessly to bring
          that vision to life. There can be no margin for error. Everything must
          be accounted for and accounted for well.
        </p>
      </>
    ),
  },
  {
    id: 7,
    text: (
      <>
        <h5>Testing the site</h5>
        <p>
          It is a crucial step before going live. Test all aspects of the site
          to ensure that everything works properly.
        </p>
      </>
    ),
  },
  {
    id: 8,
    text: (
      <>
        <h5>Going live</h5>
        <p>
          Once you've tested the site and everything is functioning, you can go
          live with your project!
        </p>
      </>
    ),
  },
  {
    id: 9,
    text: (
      <>
        <h5>Long-Term Support</h5>
        <p>
          In Support, we are always looking for ways to help you succeed. We
          know your success is our success, and we take that relationship
          seriously.
        </p>
      </>
    ),
  },
  {
    id: 10,
    text: (
      <>
        <h5>Responsive Website Design</h5>
        <p>
          No matter what device your customers use, our responsive website
          design services will ensure that your website looks great and works
          perfectly. From mobile phones and tablets to laptops and desktop
          computers, we'll ensure that your website looks amazing and functions
          flawlessly.
        </p>
      </>
    ),
  },
  {
    id: 11,
    text: (
      <>
        <h5>Dynamic Website Design</h5>
        <p>
          A dynamic website design means changing your website's content from an
          easy-to-use admin panel. It is great for adding new information like
          upcoming events, blog posts, and more.
        </p>
      </>
    ),
  },
  {
    id: 12,
    text: (
      <>
        <h5>CMS</h5>
        <p>
          A content management system (CMS) is software that enables you to
          create, edit, and publish content on your website. You can use a CMS
          to add or remove pages and edit the text and images on your website.
        </p>
      </>
    ),
  },
  {
    id: 13,
    text: (
      <>
        <h5>Static Website Design</h5>
        <p>
          At our company, the Team is specialized in static website design. This
          type of website is perfect for businesses that don't have much
          information to share or small businesses that want a simple website.
          If you're looking for a static website design that is reliable and
          easy to use, look no further than us!
        </p>
      </>
    ),
  },
  {
    id: 14,
    text: (
      <>
        <h5>E-Commerce</h5>
        <p>
          Our E-Commerce solution is perfect for businesses that need to sell
          and collect payments online. Managing inventory, deliveries, and
          customer is easy and efficient with our software. Our eCommerce
          solutions can be tailored to your needs, whether you're a small
          business or a large corporation with mobile application development.
        </p>
      </>
    ),
  },
  {
    id: 15,
    text: (
      <>
        <h5>API Integration</h5>
        <p>
          We can help increase your website visibility by integrating it with
          third-party APIs such as Google, FB, LinkedIn, Payment & SMS Gateway.
        </p>
      </>
    ),
  },
];

const WebsiteDevelopment = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>Web designing company in Hyderabad | Origin software</title>
          <link rel="canonical" href="./Web-Designing-Company-in-Hyderabad" />
          <meta
            name="description"
            content="Origin software is a web designing company in Hyderabad helping Organizations and businesses to grow their business via Internet."
          />
          <meta name="keywords" content="web designing company in hyderabad" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Website Design"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/UI-Design-Services"
                                  }
                                >
                                  UI Design
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/UX-Design-Services"
                                  }
                                >
                                  {" "}
                                  UX Design
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Responsive-Web-Design-Services"
                                  }
                                >
                                  {" "}
                                  Responsive Web Design
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Responsive-Web-Application"
                                  }
                                >
                                  {" "}
                                  Responsive Web App Design
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Website-Redesign-Services"
                                  }
                                >
                                  {" "}
                                  Website Redesign
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Web Designing Company</h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/web designing.png"
                    alt="Web-Designing-Company-in-Hyderabad"
                  />

                  <p>
                    Origin Softwares is the{" "}
                    <span style={{ fontWeight: "bold" }}>
                      leading web designing company in Hyderabad, India.{" "}
                    </span>
                    Our website designers understand that creating a website
                    isn't just about having the latest design trends or the
                    fanciest tech. It's about finding the right blend of all
                    those things to create something uniquely yours.
                  </p>
                  <p>
                    Our web designing company in Hyderabad prides itself on its
                    creative designs, and we are always thinking about how to
                    improve our websites. Whether making sure, they are
                    responsive to all devices or loading quickly, we are
                    constantly working to improve the user experience.
                    <br />
                    <Link
                      to={process.env.PUBLIC_URL + "/contact"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      Get a Quote
                    </Link>
                  </p>

                  <div className="row mb--20">
                    <h4>Get Creative Web Design Services.</h4>
                    {allData.slice(0, 3).map((item) => (
                      <div key={item.id} className="Box col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <h4>Responsive Website Designing</h4>
                  <p>
                    Our Web designers in Hyderabad design responsive websites
                    that are customized to meet your requirements. Our designs
                    are responsive to different devices (mobile, tablets,
                    desktops) and use current user interface techniques. With
                    full customization, my responsive designs will meet your
                    needs.
                  </p>

                  <div className="row">
                    <h4>Our Web Design Process</h4>
                    <p>
                      Outlandish websites require a lot of time and effort to
                      build. For our Team, this process includes many steps,
                      including:
                    </p>
                    {allData.slice(3, 9).map((item) => (
                      <div key={item.id} className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                    <p>
                      We actively invest in your long-term success because we
                      want to be there for you every step.
                      <br />
                      <Link
                        to={process.env.PUBLIC_URL + "/Portfolio"}
                        className="axil-btn btn-fill-primary btn-small"
                      >
                        Portfolio
                      </Link>
                    </p>
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Our Software Technologies</h3>
                    <div>
                      <Technology />
                    </div>
                  </div>
                  {/* <h4 className="mb--20">Our Pricing Model</h4>
                                    <p>Add Packages here</p> */}
                  <div className="row">
                    <h4 className="mb--20">Related web design services</h4>
                    <p className="mb--20">
                      Origin Softwares Provides the Best Web Designing Services
                      in Hyderabad
                    </p>
                    {allData.slice(9, 15).map((item) => (
                      <div key={item.id} className="Box col-xl-7 col-lg-6">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <h4 className="mb--20">Other services </h4>
                  <p className="mb--20">
                    Click on the icons below to see some of our most notable
                    projects in the industries that interest you.
                  </p>
                  <div className="row">
                    {appData.map((item, index) => (
                      <div key={index} className="col-md-3">
                        <div className="Mcard margin">
                          <a href={process.env.PUBLIC_URL + item.link}>
                            {item.title}
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className="b">
                          <li>
                            <a href={process.env.PUBLIC_URL + services.link}>
                              {services.title}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h4 className="mb--20">Our Testimonials</h4>
                    <TestimonialTwo />
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Our Industries</h3>
                    <div>
                      <Industries />
                    </div>
                  </div>

                  <Accordion>
                    <h4 className="color">Web Designing FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="What's the cost of website design services?"
                      body={
                        <>
                          <ul>
                            ► The cost of a website can be difficult to estimate
                            because it depends on how complex the project is. We
                            would be happy to discuss your needs to provide an
                            accurate quote.
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="2"
                      header="Why are we Best among the website designing companies in Hyderabad?"
                      body={
                        <>
                          <ul>
                            <p>
                              ► Our web designing company in Hyderabad offers a
                              comprehensive range of services that helps
                              businesses achieve their online goals. <br />
                              ► We have a team of experienced web designers who
                              can create a custom website ready that fits your
                              brand and business goals. <br />
                              ► We also as a digital marketing company, offer
                              SEO services (search engine optimization) to help
                              you get found by potential customers online.
                              <br />
                              <Link
                                to={process.env.PUBLIC_URL + "/contact"}
                                className="axil-btn btn-fill-primary btn-small"
                              >
                                Get A Quote
                              </Link>
                            </p>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="3"
                      header="What will be the duration of the website design process?"
                      body={
                        <>
                          <ul>
                            ► The time required to construct a site is
                            contingent on its level of complexity. We will work
                            diligently to adhere to a time limit if there's a
                            time limit.
                            <br />
                            ► That being said, the most frequent source of
                            setback is when the client provides the content
                            (text/images).
                            <br />
                            <Link
                              to={process.env.PUBLIC_URL + "/contact"}
                              className="axil-btn btn-fill-primary btn-small"
                            >
                              Get A Quote
                            </Link>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="4"
                      header="Is any meeting required to discuss the website design requirements?"
                      body={
                        <>
                          <ul>
                            ► Often, a meeting isn't necessary, and can do the
                            work with telephone discussions and email. <br />
                            ►So we will work with you to discuss the site and
                            review the changes. The site isn't made available to
                            the public until it looks and works the way you want
                            it to.
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="5"
                      header="Does your website design services include any hidden cost?"
                      body={
                        <>
                          <ul>
                            ► We discuss your website requirements and pricing
                            before you place your order.
                            <br />► This way, you know exactly how much our
                            services will cost, and there are no hidden fees. We
                            believe in being upfront and honest with our
                            clients.
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="6"
                      header="Will you create an SEO-friendly website?"
                      body={
                        <>
                          <ul>
                            ► Creating websites that are Search engine optimized
                            is important to us. We include best practices and
                            avoid bloated code. SEO audits are always included
                            in our website designs.
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="7"
                      header="Can you update the existing website?"
                      body={
                        <>
                          <ul>
                            ► As among the reputed website designing companies
                            in Hyderabad, We would be more than happy to look at
                            your current website and give you an estimate of how
                            much it would cost to update it.
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};
export default WebsiteDevelopment;
