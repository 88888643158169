import React from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const SideStickMenu = () => {
  return (
    <Accordion defaultActiveKey="1">
      <AccordionItem
        eventKey="1"
        header="Technologies"
        body={
          <>
            <ul className="a">
              <li>
                <Link to={process.env.PUBLIC_URL + "/Java-Development-Company"}>
                  Java
                </Link>
              </li>
              <br />
              <li>
                <Link
                  to={process.env.PUBLIC_URL + "/Node-Js-Development-Services"}
                >
                  Node.Js
                </Link>
              </li>
              <br />
              <li>
                <Link to={process.env.PUBLIC_URL + "/Hire-Python-Developers"}>
                  Python
                </Link>
              </li>
              <br />
              <li>
                <Link
                  to={process.env.PUBLIC_URL + "/IOS-App-Development-Services"}
                >
                  iOS
                </Link>
              </li>
              <br />
              <li>
                <Link
                  to={
                    process.env.PUBLIC_URL + "/Andriod-App-Development-Services"
                  }
                >
                  Android
                </Link>
              </li>
              <br />
              <li>
                <Link
                  to={process.env.PUBLIC_URL + "/Angular-Development-Services"}
                >
                  Angular
                </Link>
              </li>
              <br />
              <li>
                <Link
                  to={
                    process.env.PUBLIC_URL + "/Flutter-App-Development-Company"
                  }
                >
                  Flutter
                </Link>
              </li>
              <br />
              <li>
                <Link
                  to={
                    process.env.PUBLIC_URL + "/Dot-Net-Application-development"
                  }
                >
                  .Net
                </Link>
              </li>
              <br />
              <li>
                <Link to={process.env.PUBLIC_URL + "/Php-Development-Services"}>
                  PHP
                </Link>
              </li>
              <br />
              <li>
                <Link
                  to={process.env.PUBLIC_URL + "/React-js-development-services"}
                >
                  React.Js
                </Link>
              </li>
              <br />
              <li>
                <Link
                  to={process.env.PUBLIC_URL + "/Magento-Development-Services"}
                >
                  Magento
                </Link>
              </li>
              <br />
              <li>
                <Link
                  to={
                    process.env.PUBLIC_URL +
                    "/WordPress-Development-Company-in-India"
                  }
                >
                  WordPress
                </Link>
              </li>
            </ul>
          </>
        }
      />
    </Accordion>
  );
};

export default SideStickMenu;
