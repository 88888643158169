import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import SEO from "../common/SEO";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const PrivacyPolicy = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <SEO title="Privacy Policy" />
      <main className="main-wrapper">
        <HeaderOne />
        <SideStickButton />
        <BreadCrumbOne title="Privacy Policy" page="Privacy Policy" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <div className="section-title">
                    <h5 className="title ">
                      WHAT PERSONAL DATA WE COLLECT AND WHY WE COLLECT IT
                    </h5>
                  </div>
                  <h5 className="color">1. COMMENTS</h5>
                  <p className="mb--20">
                    When visitors leave comments on the site we collect the data
                    shown in the comments form, and also the visitor’s IP
                    address and browser user agent string to help spam
                    detection. An anonymized string created from your email
                    address (also called a hash) may be provided to the Gravatar
                    service to see if you are using it. The Gravatar service
                    privacy policy is available here:
                    https://automattic.com/privacy/. After approval of your
                    comment, your profile picture is visible to the public in
                    the context of your comment.
                  </p>
                  <h5 className="color">2. MEDIA</h5>
                  <p className="mb--20">
                    If you upload images to the website, you should avoid
                    uploading images with embedded location data (EXIF GPS)
                    included. Visitors to the website can download and extract
                    any location data from images on the website.{" "}
                    <a href="mailto:info@originsoftwares.com">
                      info@originsoftwares.com
                    </a>{" "}
                  </p>
                  <h5 className="color">3. CONTACT FORMS</h5>
                  <p className="mb--20">
                    If you leave a comment on our site you may opt-in to saving
                    your name, email address and website in cookies. These are
                    for your convenience so that you do not have to fill in your
                    details again when you leave another comment. These cookies
                    will last for one year. If you have an account and you log
                    in to this site, we will set a temporary cookie to determine
                    if your browser accepts cookies. This cookie contains no
                    personal data and is discarded when you close your browser.
                    When you log in, we will also set up several cookies to save
                    your login information and your screen display choices.
                    Login cookies last for two days, and screen options cookies
                    last for a year. If you select “Remember Me”, your login
                    will persist for two weeks. If you log out of your account,
                    the login cookies will be removed. If you edit or publish an
                    article, an additional cookie will be saved in your browser.
                    This cookie includes no personal data and simply indicates
                    the post ID of the article you just edited. It expires after
                    1 day. Contact Us on mobile no.:{" "}
                    <a href="tel:+91 - 9614085555">+91 - 9614085555</a> or email
                    us:{" "}
                    <a href="mailto:info@originsoftwares.com">
                      info@originsoftwares.com
                    </a>
                  </p>
                  <h5 className="color">
                    4. EMBEDDED CONTENT FROM OTHER WEBSITES
                  </h5>
                  <p className="mb--20">
                    Articles on this site may include embedded content (e.g.
                    videos, images, articles, etc.). Embedded content from other
                    websites behaves in the exact same way as if the visitor has
                    visited the other website. These websites may collect data
                    about you, use cookies, embed additional third-party
                    tracking, and monitor your interaction with that embedded
                    content, including tracing your interaction with the
                    embedded content if you have an account and are logged in to
                    that website.
                  </p>
                  <h5 className="color">
                    5. WHAT RIGHTS YOU HAVE OVER YOUR DATA
                  </h5>
                  <p className="mb--20">
                    If you have an account on this site, or have left comments,
                    you can request to receive an exported file of the personal
                    data we hold about you, including any data you have provided
                    to us. You can also request that we erase any personal data
                    we hold about you. This does not include any data we are
                    obliged to keep for administrative, legal, or security
                    purposes.
                  </p>
                  <h5 className="color">6. WHERE WE SEND YOUR DATA</h5>
                  <p className="mb--20">
                    Visitor comments may be checked through an automated spam
                    detection service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default PrivacyPolicy;
