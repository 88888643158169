import React from "react";
import "../assets/scss/SideStickButton.css"; // Import your CSS

const SideStickButton = () => {
  return (
    <a href="/GetAQuote">
      <div className="side-stick-button">Get A Quote</div>
    </a>
  );
};

export default SideStickButton;
