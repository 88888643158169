import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import FooterOne from '../common/footer/FooterOne';
import SEO from '../common/SEO';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import GoogleTagManager from './GTM';

const InformativeVideo = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <SEO title="InformativeVideo" />
      <main className='main-wrapper'>
        <HeaderOne />
        <div className="section-padding-equal">
          <div className="container">
            <div className="row row-40">
              <div className="col-lg-3">
                <Accordion defaultActiveKey="1">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header> Corporate Branding</Accordion.Header>
                    <Accordion.Body>
                      <Link to={process.env.PUBLIC_URL + "/Logo-Design-Company-in-India"}>Logo Design</Link><br></br><br></br>
                      <Link to={process.env.PUBLIC_URL + "/Brochure-Design-India"}>Brochure</Link><br></br><br></br>
                      <Link to={process.env.PUBLIC_URL + "/Presentation-Design-India"}>Presentation</Link><br></br><br></br>
                      <Link to={process.env.PUBLIC_URL + "/InformativeVideo"}>Informative Video</Link><br></br><br></br>
                      <Link to={process.env.PUBLIC_URL + "/Promotional-Products"}>Promotional Material</Link>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="title-BC col-lg-9">
                <h1>Informative Video</h1>
              <img className="img" src="../images/Slider/Informative Video.png" alt="InformativeVideo"/>                
              <p>InformativeVideo</p>
              </div>
            </div>
          </div>
        </div>
        <FooterOne />
      </main>
    </>
  )
}

export default InformativeVideo