import React from 'react'

const Data=[
    {
        name:"Automobile",
        image: "/images/brand/automobile.png",
        link: "/Automobile"
    },
    {
        name: "Banking",
        image: "/images/brand/banking.png",
        link: "/Banking"
    },
    {
        name: "Construction",
        image: "/images/brand/construction.png",
        link: "/Construction"
    },
    {
        name: "Corporate",
        image: "/images/brand/corporate.png",
        link: "/Corporate"
    },
    {
        name: "E Commerce",
        image: "/images/brand/e-commerce.png",
        link: "/ECommerce"
    },
    {
        name: "Education",
        image: "/images/brand/education.png",
        link: "/Education"
    },
    {
        name: "Fashion",
        image: "/images/brand/fashion.png",
        link: "/Fashion"
    },
    {
        name: "FMCG",
        image: "/images/brand/fmcg.png",
        link: "/FMCG"
    },
    {
        name: "Footwear",
        image: "/images/brand/footwear.png",
        link: "/Footwear"
    },
    {
        name: "Health Care",
        image: "/images/brand/health care.png",
        link: "/Health Care"
    },
    {
        name: "Manufacturing",
        image: "/images/brand/manufacturing.png",
        link: "/Manufacturing"
    },
    {
        name:"NGO",
        image: "/images/brand/ngo.png",
        link: "/NGO"
    },
    {
        name: "Restaurant",
        image: "/images/brand/restraunt.png",
        link: "/Restaurent"
    },
    {
        name: "Travel & Hospitality",
        image: "/images/brand/travels and hospitality.png",
        link: "/TravelAndHospitality"
    }
]

const Industries = () => {
    return (
        <>
            {Data.map((data, index) => (
                <div className="col-xl-2 col-lg-6 cool-link2" key={index}>
                    <div className="btm-img">
                        <a href={process.env.PUBLIC_URL + data.link}>
                            <img src={process.env.PUBLIC_URL + data.image} alt={data.name} />
                        </a>
                    </div>
                </div>
            ))}
        </>
    )
}

export default Industries