import React, { useState } from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import SEO from "../common/SEO";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { FaArrowCircleDown } from "react-icons/fa";
import GoogleTagManager from "./GTM";
import SideStickButton from "./SideStickButton";

const AccordionItem = ({ eventKey, header, body }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header onClick={toggleAccordion}>
        <FaArrowCircleDown className={isOpen ? "open" : ""} />
        {header}
      </Accordion.Header>
      {isOpen && <Accordion.Body>{body}</Accordion.Body>}
    </Accordion.Item>
  );
};

const Career = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <SEO title="Career" />
      <main className="main-wrapper">
        <HeaderOne />
        <SideStickButton />
        <div className="section bg-color-light">
          <section className="single-portfolio-area">
            <BreadCrumbOne title="Career" />
            <div className="container pad">
              <div className="row">
                <h4 className="color">Life @Origin Softwares</h4>
                <p>
                  At Origin Softwares, we encourage new ideas and broadcast new
                  trends that make work more vibrant and challenging. We foster
                  an open culture where new ideas are shared and feedback is
                  valued. We believe that excellence is not a goal, but a
                  continuous improvement.{" "}
                </p>
                <div className="col-lg-5">
                  <div className="why-choose-us">
                    <Accordion>
                      <AccordionItem
                        eventKey="1"
                        header="WordPress Developer"
                        body={
                          <>
                            <p className="mb--20">
                              ORIGIN SOFTWARES <br></br>
                              Location: [Hyderabad, Telangana]
                            </p>
                            We are seeking an experienced WordPress Developer to
                            join our dynamic team. As a WordPress Developer, you
                            will be responsible for developing and maintaining
                            high-quality WordPress websites, themes, and
                            plugins. The ideal candidate should have a strong
                            background in front-end and back-end development,
                            possess a creative mindset, and be familiar with the
                            latest web technologies.<br></br>
                            <Link
                              to={process.env.PUBLIC_URL + "/ApplyNow"}
                              className="axil-btn btn-fill-primary btn-small"
                            >
                              Apply Now
                            </Link>
                            <br></br>
                          </>
                        }
                      />
                      <AccordionItem
                        eventKey="2"
                        header=".Net Developer"
                        body={
                          <>
                            <p className="mb--20">
                              ORIGIN SOFTWARES <br></br>
                              Location: [Hyderabad, Telangana]
                            </p>
                            We are seeking a skilled .NET Developer with a
                            minimum of 2 years of experience to join our
                            development team. As a .NET Developer, you will play
                            a key role in designing, developing, and maintaining
                            innovative software solutions that align with our
                            business goals. The ideal candidate should have a
                            strong understanding of C#, .NET Framework, and
                            related technologies, along with a passion for
                            building high-quality and scalable applications.
                            <br></br>
                            <Link
                              to={process.env.PUBLIC_URL + "/ApplyNow"}
                              className="axil-btn btn-fill-primary btn-small"
                            >
                              Apply Now
                            </Link>
                            <br></br>
                          </>
                        }
                      />
                      <AccordionItem
                        eventKey="3"
                        header="Php Laravell Developer"
                        body={
                          <>
                            <p className="mb--20">
                              ORIGIN SOFTWARES <br></br>
                              Location: [Hyderabad, Telangana]
                            </p>
                            We are currently seeking a talented with a minimum
                            of 2 years of experience as PHP Laravel Developer to
                            join our expanding team. As a PHP Laravel Developer,
                            you will be responsible for developing and
                            maintaining web applications using the Laravel
                            framework. Your primary focus will be on crafting
                            efficient, robust, and scalable code to create
                            seamless user experiences.<br></br>
                            <Link
                              to={process.env.PUBLIC_URL + "/ApplyNow"}
                              className="axil-btn btn-fill-primary btn-small"
                            >
                              Apply Now
                            </Link>
                            <br></br>
                          </>
                        }
                      />
                      <AccordionItem
                        eventKey="4"
                        header="Graphic Designer"
                        body={
                          <>
                            <p className="mb--20">
                              ORIGIN SOFTWARES <br></br>
                              Location: [Hyderabad, Telangana]
                            </p>
                            We are thrilled to invite an experienced Graphic
                            Designer with a minimum of 2 years of expertise to
                            join our creative team. As a Graphic Designer, you
                            will play a vital role in conceptualizing and
                            creating visual assets that bring our brand and
                            projects to life. Your artistic flair and attention
                            to detail will be instrumental in delivering
                            captivating designs that resonate with our target
                            audience.<br></br>
                            <Link
                              to={process.env.PUBLIC_URL + "/ApplyNow"}
                              className="axil-btn btn-fill-primary btn-small"
                            >
                              Apply Now
                            </Link>
                            <br></br>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="col-lg-5 offset-xl-2">
                  <div className="why-choose-us">
                    <Accordion>
                      <AccordionItem
                        eventKey="5"
                        header="Angular.Js"
                        body={
                          <>
                            <p className="mb--20">
                              ORIGIN SOFTWARES <br></br>
                              Location: [Hyderabad, Telangana]
                            </p>
                            We are currently seeking a highly skilled AngularJS
                            Developer with at least 2 years of experience to
                            become a valued member of our talented team. In this
                            role, you will be instrumental in the design,
                            development, and maintenance of dynamic web
                            applications utilizing the AngularJS framework. Your
                            proficiency will be paramount in delivering smooth
                            user experiences and guaranteeing the scalability
                            and performance of our applications. Join us and
                            make a significant impact as an AngularJS Developer,
                            contributing to the success and innovation of our
                            projects.<br></br>
                            <Link
                              to={process.env.PUBLIC_URL + "/ApplyNow"}
                              className="axil-btn btn-fill-primary btn-small"
                            >
                              Apply Now
                            </Link>
                            <br></br>
                          </>
                        }
                      />
                      <AccordionItem
                        eventKey="6"
                        header=" React.Js"
                        body={
                          <>
                            <p className="mb--20">
                              ORIGIN SOFTWARES <br></br>
                              Location: [Hyderabad, Telangana]
                            </p>
                            We are currently looking for a highly skilled
                            React.js Developer with a minimum of 2 years of
                            experience to join our talented team. As a React.js
                            Developer, you will play a crucial role in
                            designing, developing, and maintaining dynamic web
                            applications using the React.js library. Your
                            expertise will be essential in creating seamless
                            user experiences and ensuring the scalability and
                            performance of our applications. Join us now and
                            make a significant impact as a React.js Developer,
                            contributing to the success and innovation of our
                            projects. Become a valued member of our team and
                            embark on exciting opportunities in the world of
                            React.js development.<br></br>
                            <Link
                              to={process.env.PUBLIC_URL + "/ApplyNow"}
                              className="axil-btn btn-fill-primary btn-small"
                            >
                              Apply Now
                            </Link>
                            <br></br>
                          </>
                        }
                      />
                      <AccordionItem
                        eventKey="7"
                        header="Node.Js"
                        body={
                          <>
                            <p className="mb--20">
                              ORIGIN SOFTWARES <br></br>
                              Location: [Hyderabad, Telangana]
                            </p>
                            We are excited to welcome a highly skilled Node.js
                            Developer with a minimum of 2 years of experience to
                            join our team. As a Node.js Developer, you will play
                            a pivotal role in designing, developing, and
                            maintaining server-side applications and APIs. Your
                            expertise in Node.js will be critical in building
                            scalable and efficient solutions that power our web
                            and mobile applications.<br></br>
                            <Link
                              to={process.env.PUBLIC_URL + "/ApplyNow"}
                              className="axil-btn btn-fill-primary btn-small"
                            >
                              Apply Now
                            </Link>
                            <br></br>
                          </>
                        }
                      />
                      <AccordionItem
                        eventKey="8"
                        header="UI/UX Designer"
                        body={
                          <>
                            <p className="mb--20">
                              ORIGIN SOFTWARES <br></br>
                              Location: [Hyderabad, Telangana]
                            </p>
                            Anticipation runs high as we eagerly await the
                            arrival of a seasoned UI/UX Designer, armed with a
                            minimum of 2 years of experience, to join our circle
                            of innovators. Carrying the torch of a UI/UX
                            Designer, your role will be nothing short of pivotal
                            – crafting intuitive, visually enthralling user
                            interfaces that pave the way for exceptional user
                            experiences. Your artistry in user-centered design,
                            seamlessly interwoven with the marriage of
                            aesthetics and function, will be the cornerstone
                            upon which we build products that deeply resonate
                            with our users.<br></br>
                            <Link
                              to={process.env.PUBLIC_URL + "/ApplyNow"}
                              className="axil-btn btn-fill-primary btn-small"
                            >
                              Apply Now
                            </Link>
                            <br></br>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <FooterOne />
      </main>
    </>
  );
};

export default Career;
