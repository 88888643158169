import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import GoogleTagManager from "./GTM";
import SideStickButton from "./SideStickButton.js";
import SideStickMenu from "./SideStickMenu.js";

const allData = [
  {
    id: 1,
    text: "Origin Softwares provides Android development services that result in custom mobile apps, mobile SDKs, and utilities. We excel in in-app integration with the back-end web service and corporate systems. Whichever your project requires.",
  },
  {
    id: 2,
    text: "If you're looking for someone to develop a custom Android app for you, look no further than Origin Softwares. Our developers have a wealth of experience creating all sorts of Android apps, from utility apps to those integrated with complex back-end systems.",
  },
  {
    id: 3,
    text: "End-to-end Android app development is our specialty and we're always keen to deliver a custom product that meets your specific needs. We have the skills and know-how to get it done right",
  },
  {
    id: 4,
    text: (
      <>
        <h5>Innovative approach</h5>
        <br />
        <p>
          At Origin Softwares, we pride ourselves on our innovative approach to
          Android app development. Our R&D department keeps up with the latest
          trends in video data processing, object tracking, geographic
          information systems, and more so that we can offer apps that stand out
          from the crowd.
        </p>
      </>
    ),
  },
  {
    id: 5,
    text: (
      <>
        <h5>Focus on your business goals and user experience</h5>
        <p>
          {" "}
          When it comes to developing Android apps, we focus on creating a
          balance between your business goals and the needs of your users. This
          way, we can develop apps that help you achieve your goals and provide
          a great user experience.
        </p>
      </>
    ),
  },
];

const AndriodApps = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>Android App Development Services | Origin softwares</title>
          <link rel="canonical" href="./Andriod-App-Development-Services" />
          <meta
            name="description"
            content="We have developed a wide range of applications for many business sectors and We offer the best Android App Development Services at affordable prices."
          />
          <meta name="keywords" content="Android app development services" />
        </Helmet>
        <main className="main wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <SideStickMenu />
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Android App Development </h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/Android App Development.png"
                    alt="Andriod-App-Development-Services"
                  />
                  <p>
                    Android app development services cover a wide range of needs
                    for businesses and organizations who want to develop
                    software for the Android platform. Android app development
                    can be conducted using native, hybrid, or cross-platform
                    development techniques.
                  </p>

                  <div className="row mb--20">
                    <h4>Android Development Services</h4>
                    {allData.slice(0, 3).map((item) => (
                      <div className="Box3 col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Technologies we Offer</h3>
                    <div>
                      <Technology />
                    </div>
                  </div>

                  <div className="row">
                    <h4>Android Development Services</h4>
                    {allData.slice(3, 5).map((item) => (
                      <div className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <div className="paper col-xl-12 col-lg-1">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <h4>
                            Project Management and Mobile Projects Workflow
                          </h4>
                          <p>
                            <ul className="b">
                              <li>
                                At Origin Softwares, we understand that mobile
                                projects require a mature and experienced
                                project management team that can handle the
                                challenges and complexities that these types of
                                projects bring.
                              </li>
                              <li>
                                We have a deep understanding of the mobile
                                project workflow.
                              </li>
                              <li>
                                We use our custom, advanced Quality Management
                                System to track the progress of Android app
                                development.
                              </li>
                              <li>
                                This system is backed by our ISO 9001
                                certificate and provides you with KPI reporting
                                tools to ensure transparency.
                              </li>
                              <Link
                                to={process.env.PUBLIC_URL + "/contact"}
                                className="axil-btn btn-fill-primary btn-small"
                              >
                                Contact Us
                              </Link>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Our Industries</h3>
                    <div>
                      <Industries />
                    </div>
                  </div>

                  <h4 className="mb--20">
                    Expertise in Security and Integration Process
                  </h4>
                  <p>
                    Our team of experts also guarantees the security of your
                    Android app data, as we are ISO 27001 certified. We provide
                    smooth integration of your app with the back-end web service
                    and internal corporate systems (ERP, CRM, ECM, CPM, HRM, and
                    others).
                  </p>

                  <h4 className="mb--20">Get A Quote</h4>
                  <p>
                    Having an Android app can help you broaden your outreach.
                    Our team can deliver a high-quality app of any complexity,
                    on time, and budget. Contact us today to discuss the
                    development of your app.
                    <Link
                      to={process.env.PUBLIC_URL + "/contact"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      Get A Quote
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default AndriodApps;
// 265
