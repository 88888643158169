import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const OffshoreStaffing = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.originsoftwares.com/Offshore-Staffing"
          />
          <meta
            name="description"
            content="Origin Software is one of the leading offshore staffing services companies in India. We have a team of highly qualified candidates for you."
          />
          <meta name="keywords" content="OFFSHORE STAFFING" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="IT Staffing"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Recruitment-Services"
                                  }
                                >
                                  {" "}
                                  Recruitment
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Staffing-Resources"
                                  }
                                >
                                  Resourses
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Contract-Staffing-Services"
                                  }
                                >
                                  {" "}
                                  Contract Staffing
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={process.env.PUBLIC_URL + "/Direct-Hire"}
                                >
                                  {" "}
                                  Direct Hire
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL + "/Executive-Search"
                                  }
                                >
                                  {" "}
                                  Executive Search
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Offshore-Staffing"
                                  }
                                >
                                  {" "}
                                  Offshore Staffing
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">OffShore Staffing Services</h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/Offshore Staffing.png"
                    alt="Offshore-Staffing"
                  />
                  <p>
                    Working with an offshore staffing service can help you get
                    your digital projects off the ground quickly and
                    efficiently. With a wealth of talent to choose from, you can
                    find the perfect team to support your project every step of
                    the way.
                    <br />
                    <br />
                    From experienced IT professionals to those with strong work
                    ethics and attention to detail, our offshore staff are
                    hand-picked and rigorously trained to deliver outstanding
                    results.
                    <br />
                    <br />
                    Not to mention, sourcing talent overseas can help you save
                    on personnel and infrastructure costs. So if you're looking
                    for greater organizational agility, working with an offshore
                    staffing agency is the perfect solution.
                    <br />
                    <Link
                      to={process.env.PUBLIC_URL + "/contact"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      Contact Us
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default OffshoreStaffing;
// 112
