import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  services: [
    { title: "Logo Design", link: "/Logo-Design-Company-in-India" },
    { title: "Presentation", link: "/Presentation-Design-India" },
    { title: "Brochure", link: "/Brochure-Design-India" },
    { title: "Promotional Material", link: "/Promotional-Products" },
  ],
};

const jsonData1 = {
  services: [
    {
      title: "Digital Marketing",
      link: "/Digital-Marketing-Agency-in-Hyderabad",
    },
    { title: "Mobile Apps", link: "/Mobile-App-Development-Services" },
    {
      title: "Social Media Marketing",
      link: "/Social-Media-Marketing-agency-in-India",
    },
  ],
};

const allData = [
  {
    id: 1,
    text: (
      <>
        <h6>Creative Brief</h6>
        <p>
          An effective corporate logo is the foundation of any successful
          corporate identity. At origin software, we specialize in creating
          logos that perfectly represent our client's needs and objectives. We
          believe that a well-designed logo can positively impact potential
          customers and give your company a competitive edge.
          <br />
          Our team takes the time to understand your business and design a
          business logo that communicates your mission and vision clearly and
          memorably.
        </p>
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        <h6>Doing the Research</h6>
        <p>
          As the best logo designing services provider in India, we firmly
          believe that research and action go hand-in-hand; neither can exist
          without the other. In this initial stage, we investigate your business
          and its industry inside and out. It allows us to understand what your
          competitors are up to and what strategies they use to engage potential
          customers.
          <br />
          By understanding the latest trends in your industry, we can position
          your logo design ahead of the curve while maintaining a timeless look
          and feel.
        </p>
      </>
    ),
  },
  {
    id: 3,
    text: (
      <>
        <h6>Conceptual phase</h6>
        <p>
          At our company, we pride ourselves on our creative designers. We
          believe they are some of the most talented in the industry and have a
          keen eye for detail in finding the right graphic style and image to
          represent your business.
          <br />
          As the best logo design company in India, we offer a unique identity
          to your business based on aspects like aesthetic looks and the best
          user experience.
        </p>
      </>
    ),
  },
  {
    id: 4,
    text: (
      <>
        <h6>Fill it with brand Colors</h6>
        <p>
          We understand that coming up with a logo for your business can be a
          daunting task. But our team of professional and inventive logo
          designers is here to help! We'll work with you to develop a logo that
          not only represents your business well but also catches the eye of
          your target audience.
          <br />
          We're proud to offer customized designing services that consider your
          ideas and inspiration to create a professional and memorable logo.
          Once our LOGO designer is satisfied with the sketch, they will move
          forward to fill in the colors and make it look more natural by trying
          various color combinations.
        </p>
      </>
    ),
  },
  {
    id: 5,
    text: (
      <>
        <h6>Client Approval</h6>
        <p>
          Logo design is all about making your clients happy. We consider your
          feedback and ideas to create a logo that represents your business in
          the best way possible.
          <br />
          You can expect significant results that exceed your expectations.
          <br />
        </p>
      </>
    ),
  },
  {
    id: 6,
    text: (
      <>
        <h5>Business Card</h5>
        <br />
        <p>
          A company's corporate identity is very important for sales and
          marketing. A signature business card design is essential for a company
          to build its brand image.
          <br />
          Letterhead, envelopes, compliment slips, and fax sheets are all
          examples of office stationery vital to building a brand image.
        </p>
      </>
    ),
  },
  {
    id: 7,
    text: (
      <>
        <h5>
          <Link to={process.env.PUBLIC_URL + "/Brochure-Design-India"}>
            Brochure Design
          </Link>
        </h5>
        <br />
        <p>
          Brochures are a great way to get information about a company out to
          the public. They can include statistics, product information, and
          details about special discounts.
          <br />
          It makes them an effective tool for communication and marketing for
          any business.{" "}
        </p>
      </>
    ),
  },
  {
    id: 8,
    text: (
      <>
        <h5>
          <Link
            to={
              process.env.PUBLIC_URL + "/Ecommerce-Website-Development-Services"
            }
          >
            Web Design and ECommerce Website
          </Link>
        </h5>
        <p>
          There is a wide range of websites, from simple static websites to
          eCommerce websites with shopping carts and payment gateways. <br />
          At Origin Softwares, we have the expertise to design and develop a
          professional website to build your company's online presence.
        </p>
      </>
    ),
  },
  {
    id: 9,
    text: (
      <>
        <h5>Packaging Design</h5>
        <p>
          The design team is award-winning with years of expertise in designing
          commercial packaging. Our team is proficient in many areas of
          development of branding, across FMCG, Cosmetics, Food and Beverages,
          Automobiles to Pharmaceuticals.
        </p>
      </>
    ),
  },
  {
    id: 10,
    text: (
      <>
        <h5>
          <Link to={process.env.PUBLIC_URL + "/Promotional-Products"}>
            Promotional Products
          </Link>
        </h5>
        <p>
          Branding is important for any company, but knowing where to start, is
          difficult. Logo Designers in Hyderabad, India, can help you get your
          brand out with promotional products like mugs, T-shirts, pens, or
          mouse pads with your company logo. <br />
          By giving away these items, you'll be able to get your brand in front
          of potential customers and clients and build a positive association
          with your company.
        </p>
      </>
    ),
  },
];

const LogoDesign = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>Logo design company in India | Origin Software</title>
          <link rel="canonical" href="./Logo-Design-Company-in-India" />
          <meta
            name="description"
            content="Origin Software is a leading logo design company in India. We deliver high quality logos that would suit any business or personal requirements."
          />
          <meta name="keywords" content="Logo design company in India" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Coporate Branding"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Logo-Design-Company-in-India"
                                  }
                                >
                                  Logo Design
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Presentation-Design-India"
                                  }
                                >
                                  Presentation
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Brochure-Design-India"
                                  }
                                >
                                  Brochure
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Promotional-Products"
                                  }
                                >
                                  Promotional Material
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Logo Design</h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/Logo Design.png"
                    alt="Logo-Design-Company-in-India"
                  />
                  <p className="mb--20">
                    Our Logo design company in India has a design team that can
                    help you create a brand that will take your company to the
                    next level. We specialize in logo design, graphic design,
                    and responsive website design. In today's competitive
                    market, it's essential to have a proactive approach to
                    marketing and publicity.
                    <br />
                    <br />
                    Origin Softwares is among the best branding agencies in
                    Hyderabad, India. Our team can help you highlight the
                    character of your business and emphasize your product and
                    services.
                    <br />
                  </p>

                  <div className="row mb--20">
                    <h4>Logo Designing Process</h4>
                    {allData.slice(0, 5).map((item) => (
                      <div className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <img
                      className="thumbnail"
                      src="../images/InfoGraphics/logodesign.png"
                      alt="Thumbnail"
                    />
                  </div>

                  <div className="row mb--20">
                    <div className="paper col-xl-6 col-lg-2">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <p>
                            At our company, we pride ourselves on creating
                            perfect logos for our client's brands. Our logo
                            design process is both professional and transparent,
                            and it includes the following steps:
                            <ul className="b">
                              <li>
                                We take the time to learn about your brand and
                                what makes it unique.
                              </li>
                              <li>
                                We brainstorm logo brand concepts that capture
                                the essence of your brand.
                              </li>
                              <li>
                                We refine the concepts and present them to you
                                for feedback.
                              </li>
                              <li>
                                Once you're happy with a concept, we complete
                                the design and deliver the files.
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Technologies We Use</h3>
                    <div>
                      <Technology />
                    </div>
                  </div>

                  <h4>Logo Design ( Brand Identity )</h4>
                  <p>
                    <ul className="b">
                      <li>
                        A company's logo is much more than just an attractive
                        piece of artwork. It reflects your company's vision and
                        should be unique and trendy enough to help you stand out
                        from the competition.
                      </li>
                      <li>
                        Our logo designers will work closely with you to create
                        a custom logo design that perfectly captures the essence
                        of your business. Visit our website anytime to learn
                        more about our logo design services.
                      </li>
                    </ul>
                  </p>

                  <div className="row mb--20">
                    {allData.slice(5, 10).map((item) => (
                      <div className="Box3 col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <h4>Other Services</h4>
                  <div className="container mt-3">
                    <div className="row">
                      {jsonData1.services.map((services, index) => (
                        <div className="col-md-3">
                          <div className="Scard margin">
                            <p>
                              {" "}
                              <a href={process.env.PUBLIC_URL + services.link}>
                                {services.title}
                              </a>
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Industries</h3>
                    <div>
                      <Industries />
                    </div>
                  </div>

                  <div className="row">
                    <h4 className="mb--20">Our Services</h4>
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className="b">
                          <li>
                            <a href={process.env.PUBLIC_URL + services.link}>
                              {services.title}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>

                  <p>
                    {" "}
                    Customers frequently have questions when choosing a Logo
                    Design company. To help give customers a clear initial
                    ideaabout our work, we've compiled a list of FAQ's below.
                    <br></br>
                    These will address questions related to our process,pricing,
                    and more.
                  </p>
                  <Accordion>
                    <h4 className="color">Logo Design FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="How much time does it take to create a logo design?"
                      body={
                        <>
                          <ul>
                            ► As soon as we receive your complete request, our
                            logo designers get to work brainstorming ideas for a
                            unique and innovative logo design that meets your
                            requirements. <br />
                            ► Once you've suggested all the weaks and changes
                            you'd like, the final logo is usually ready within
                            2-3 weeks.
                            <br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="2"
                      header="How does the logo designing process work?"
                      body={
                        <>
                          <ul>
                            ► In our first meeting, we want to understand your
                            ideas and vision for the logo. It includes
                            understanding your likes, dislikes, and target
                            audience. This way, we can develop a design concept
                            that matches your needs. <br />
                            ► We will develop and show you samples of the logo.
                            After making all the changes and revisions, we will
                            give you the final logo.
                            <br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="3"
                      header="What will be the cost of logo designing from Origin Softwares?"
                      body={
                        <>
                          <ul>
                            ► We offer three unique plans to businesses- Basic,
                            Plus, and Premium. Each plan has benefits that you
                            can choose based on your requirements and budget.
                            <br />
                            ► You can mix and match services from each plan to
                            create a unique package that works for your
                            business.
                            <br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="4"
                      header="Will I get support after the project gets done?"
                      body={
                        <>
                          <ul>
                            ► At our logo design company, we see our clients as
                            our most valuable assets. We're not satisfied just
                            completing the logo design process - we want to
                            establish a long-term relationship with you.
                            <br />
                            ► We're always happy to provide support and advice
                            long after your logo is complete.
                            <br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="5"
                      header="Is copyright registration done?"
                      body={
                        <>
                          <ul>
                            ► As among the top logo design agencies India-based,
                            We will begin working on the copyright as soon as
                            you complete the logo and make all the payments.{" "}
                            <br />
                            ► Copyright protection gives you exclusive rights to
                            print, display, and distribute the logo so that you
                            can do anything with it.
                            <br />
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default LogoDesign;
// 467
