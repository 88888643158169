import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import BlogSidebar from "../component/blog/BlogSidebar";
import BlogGridOne from "../component/blog/BlogGridOne";
import SEO from "../common/SEO";
import GoogleTagManager from "./GTM";
import SideStickButton from "./SideStickButton";

const BlogGridView = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <SEO title="Blog Grid" />
      <SideStickButton />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Blogs" page="Blog" />
        <div className="section-padding-equal">
          <div className="container">
            <div className="row row-40">
              <div className="col-lg-3">
                <BlogSidebar />
              </div>
              <div className="col-lg-9">
                <BlogGridOne />
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default BlogGridView;
