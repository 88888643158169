import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import { Container, Row, Tabs, Tab } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import "bootstrap/dist/css/bootstrap.min.css";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import Websites from "./Websites";
import ECommerce from "../component/Portfolio/ECommerce";
import LMS from "../component/Portfolio/LMS";
import MobileApplication from "../component/Portfolio/MobileApplication";
import Construction from "../component/Portfolio/Construction";
import NGO from "../component/Portfolio/NGO";
import Restaurant from "../component/Portfolio/Restaurant";
import Travel from "../component/Portfolio/Travel";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const Portfolio = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>Portfolio | Origin software</title>
          <link rel="canonical" href="./Portfolio" />
          <meta
            name="description"
            content="Explore the diverse portfolio of our software development company and see the range of successful projects we have completed for clients in various industries."
          />
          <meta name="keywords" content="Portfolio" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section bg-color-light">
            <BreadCrumbOne title="Portfolio" />
            <Container className="py-4">
              <Row className="justify-content-center">
                <Tabs
                  justify-variant="pills"
                  defaultActiveKey="tab-1"
                  className="mb-1 pad font"
                >
                  <Tab eventKey="tab-1" title="Show All">
                    <Websites />
                    <ECommerce />
                    <LMS />
                  </Tab>
                  <Tab eventKey="tab-2" title="Websites">
                    <Websites />
                  </Tab>
                  <Tab eventKey="tab-3" title="E-Commerce">
                    <ECommerce />
                  </Tab>
                  <Tab eventKey="tab-4" title="LMS">
                    <LMS />
                  </Tab>
                  <Tab eventKey="tab-5" title="Mobile Application">
                    <MobileApplication />
                  </Tab>
                  <Tab eventKey="tab-6" title="Construction">
                    <Construction />
                  </Tab>
                  <Tab eventKey="tab-7" title="NGO">
                    <NGO />
                  </Tab>
                  <Tab eventKey="tab-8" title="Restaurant">
                    <Restaurant />
                  </Tab>
                  <Tab eventKey="tab-9" title="Travel & Hospitality">
                    <Travel />
                  </Tab>
                </Tabs>
              </Row>
            </Container>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default Portfolio;

// import React, { useState, useEffect } from 'react';
// import ProjectData from "../data/project/ProjectData.json";
// import ProjectPropOne from '../itemProp/ProjectPropOne';

// <nav className='mainmenu-nav'>
//                             <ul className='mainmenu'>
//                                 <div className='texto'>
//                                     <li className='menu-item-has-children'>
//                                         <li><Link to={process.env.PUBLIC_URL + "#"}>Show All</Link></li>
//                                         <li><Link to={process.env.PUBLIC_URL + "#"}>Websites</Link></li>
//                                         <li><Link to={process.env.PUBLIC_URL + "#"}>E-commerce</Link></li>
//                                         <li><Link to={process.env.PUBLIC_URL + "#"}>Lms </Link></li>
//                                         <li><Link to={process.env.PUBLIC_URL + "#"}>Mobile Application</Link></li>
//                                     </li>
//                                 </div>
//                             </ul>
//                         </nav>

// const filters = [
//     {
//         id: 1,
//         label: "Show All",
//     },
//     {
//         id: 2,
//         label: "Website",
//     },
//     {
//         id: 3,
//         label: "E-commerce",
//     },
//     {
//         id: 4,
//         label: "LMS",
//     },
//     {
//         id: 5,
//         label: "Mobile Application",
//     }
// ];

// const AllData = ProjectData;

// const Portfolio = ({ parentClass, colSize, itemShow, columnGap }) => {

//     const [getAllItems] = useState(AllData);
//     const [visiableProject] = useState(itemShow ? itemShow : 6);
//     const [activeFilter, setActiveFilter] = useState("");
//     const [visibleItems, setVisibleItems] = useState([]);

//     useEffect(() => {
//         setActiveFilter(filters[0].label);
//         setVisibleItems(getAllItems.filter((item) => item.id <= visiableProject));
//     }, []);

//     const handleChange = (e) => {
//         e.preventDefault();
//         let target = e.target.textContent;

//         setActiveFilter(target);

//         let tempData = [];
//         if (target === filters[0].label) {
//             tempData = getAllItems.filter((data) => data.id <= visiableProject);
//         } else {
//             for (let i = 0; i < getAllItems.length; i++) {
//                 const element = getAllItems[i];
//                 let categories = element['category'];

//                 if (categories.includes(target)) {
//                     tempData.push(element)
//                 }
//             }
//         }
//         setVisibleItems(tempData);
//     };
//     return (
//         <div className='section section-padding-2 isotope-button isotope-project-btn'>
//             <div className='container'>
//                 <h1>Portfolio</h1>
//                 {filters.map((filter) => (
//                     <button onClick={handleChange} className={
//                         filter.label === activeFilter
//                             ? "is-checked"
//                             : " "
//                     }
//                         key={filter.id}>{filter.label}</button>
//                 ))}

//                 <div className={`row ${columnGap ? columnGap : "row-35"}`}>
//                     {visibleItems.map((data) => (
//                         <div className={colSize ? colSize : "col-md-6"} key={data.id}>
/* <ProjectPropOne projectStyle="" portfolio={data} /> */
//                         </div>
//                     ))}
//                 </div>

//             </div>
//         </div>
//     )
// }

// export default Portfolio
