import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import SideStickButton from "./SideStickButton";
import SEO from "../common/SEO";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectOne from "../component/project/ProjectOne";
import GoogleTagManager from "./GTM";

const ProjectGridOne = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <SEO title="Project Two Column" />
      <SideStickButton />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title="Our Projects"
          paragraph="A quick view of industry specific problems solved with design by the awesome team at Abstrak.
                "
          styleClass=""
          mainThumb="/images/banner/banner-thumb-1.png"
        />
        <ProjectOne />
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ProjectGridOne;
