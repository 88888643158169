import React from 'react';
import EducationD from '../../data/Portfolio/EducationD.json';

const allData = EducationD

const LMS = () => {
  return (
    <>
      <div className='container mt-3'>
        <div className='row'>
          {allData.map((data) => (
            <div className='col-md-3'>
              <div className='card margin'>
                <img src={process.env.PUBLIC_URL + data.image} alt={data.name} />
                <div className='card-body'>
                  <h5>{data.name}</h5>
                  <a href={process.env.PUBLIC_URL + data.url} target='_blank' rel='noopener norefferrer'><button className='axil-btn btn-fill-primary btn-fluid-btn-primary'>Live Preview</button></a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default LMS





