import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Industries from "../component/Industries/Industries";
import Technology from "../component/Technologies/Technology.js";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  services: [
    { title: "Software Consulting", link: "/Software-Consulting" },
    {
      title: "Custom Software Development",
      link: "/Custom-Software-Development-Company-in-India",
    },
    {
      title: "Software Development Outsourcing",
      link: "/Software-Development-Outsourcing",
    },
    {
      title: "Software Product Development",
      link: "/Software-Product-Development",
    },
    {
      title: "Software Development Automation",
      link: "/Software-Development-Automation",
    },
    {
      title: "Software Maintenance and support",
      link: "/Software-Maintenance-and-Support",
    },
  ],
};

const allData = [
  {
    id: 1,
    text: (
      <>
        <h5>Software development</h5>
        <p>
          Origin Softwares is a leading software development company in
          Hyderabad, India. we create incredible software for your business.
        </p>
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        <h5>Web development</h5>
        <p>
          Our services also include web development; you can check our website
          development portfolio as a reference. It has various web-based apps
          developed by our experts.
        </p>
      </>
    ),
  },
  {
    id: 3,
    text: (
      <>
        <h5>Mobile App Development</h5>
        <p>
          We have been Building on our experience with over 20+ completed mobile
          projects. we can deliver mobile apps for any platform and industry.
        </p>
      </>
    ),
  },
  {
    id: 4,
    text: (
      <>
        <h5>Desktop Development</h5>
        <p>
          Our desktop app development services include Mac, Windows, and Linux
          in C++/Qt, .NET, and Python programming languages.
        </p>
      </>
    ),
  },
  {
    id: 5,
    text: (
      <>
        <h5>Database Development</h5>
        <p>
          We can help you organize your data for efficient analysis and
          decision-making.
        </p>
      </>
    ),
  },
  {
    id: 6,
    text: (
      <>
        <h5>Cloud App Development</h5>
        <p>
          Our cloud app development services ensure rational use of cloud
          resources to avoid over- or under-provisioning.
        </p>
      </>
    ),
  },
  {
    id: 7,
    text: (
      <>
        <h5>API Development </h5>
        <p>
          {" "}
          Our development teams employ API-driven development to seamlessly
          integrate custom software with corporate and third-party systems and
          services.
        </p>
      </>
    ),
  },
  {
    id: 8,
    text: (
      <>
        <h5>SaaS development</h5>
        <p>
          {" "}
          Our company offers assistance for developing software for the saas
          apps.
        </p>
      </>
    ),
  },
  {
    id: 9,
    text: (
      <>
        <h5>Modernisation</h5>
        <p>
          In addition, we can improve the usability, refactor code and optimize
          the maintenance cost of legacy software.
        </p>
      </>
    ),
  },
  {
    id: 10,
    text: (
      <>
        <h6> Enterprise resource &amp; management software</h6>
        <p>
          Our software helps businesses manage their day-to-day activities more
          efficiently by consolidating all of their operations into a single
          system.<br></br>
          It includes project and task management features, resource and
          workload management, HR and talent management, sales and marketing
          automation, risk management, compliance management, and document
          management.<br></br>
          Having these capabilities in one place makes it simpler and faster for
          businesses to get work done.
        </p>
      </>
    ),
  },
  {
    id: 11,
    text: (
      <>
        <h6> Knowledge &amp; productivity</h6>
        <p>
          In addition to streamlining communication and product movement, our
          software also helps increase employee knowledge and productivity.
          <br></br>
          At our company, we provide various content management systems that can
          help with everything from activity tracking to knowledge hubs and
          intranets.<br></br>
          We understand that corporate learning and training can be complex, so
          we offer our clients a wide variety of options. With our help, you can
          find the perfect system for your needs and ensure your employees have
          all the tools they need to succeed.
        </p>
      </>
    ),
  },
  {
    id: 12,
    text: (
      <>
        <h6>Supply chain management software</h6>
        <p>
          The software we use for supply chain management is designed to
          automate and keep track of all the steps involved in getting a
          product, from sourcing and manufacturing to be available for purchase
          by the consumer.<br></br>
          At our company, we specialize in software solutions that help manage
          all aspects, from demand planning and procurement to warehouse and
          vendor management.
        </p>
      </>
    ),
  },
  {
    id: 13,
    text: (
      <>
        <h6> Business Analytics</h6>
        <p>
          There are many different types of business analytics, each with its
          benefits depending on what kind of data you need to analyze. For
          example, predictive analytics can help you decide about future sales
          or customer trends.<br></br>
          Enterprise-wide data management solutions can give you a big-picture
          view of your business, while big data solutions can help you make
          sense of large, complex data sets.
        </p>
      </>
    ),
  },
  {
    id: 14,
    text: (
      <>
        <h6> Internet-connected processes</h6>
        <p>
          We create software ecosystems that connect physical objects to the
          internet so that they can transfer information about their state or
          environment into the data center.<br></br>
          <ul>
            <li>
              {" "}
              It allows for various objects to communicate with one another and
              creates a more cohesive internet of things.
            </li>
            <li>
              {" "}
              It has applications in many industries, like smart cities,
              factories, and homes.
            </li>
            <li>
              {" "}
              It is used for remote service and monitoring of connected
              vehicles.
            </li>
          </ul>
        </p>
      </>
    ),
  },
  {
    id: 15,
    text: (
      <>
        <h6>Emergency &amp; security</h6>
        <p>
          We also offer security solutions for emergency response and incident
          management, information security, and secure video conferencing and
          messaging.<br></br>
          When it comes to your finances and security, we've got you covered.
        </p>
      </>
    ),
  },
  {
    id: 16,
    text: (
      <>
        <h6> Customer-centric software</h6>
        <p>
          Our software is designed with the customer in mind. We consider
          audience specifics in design to create a user-friendly product for
          business users or individual consumers that give the best user
          experience.
        </p>
      </>
    ),
  },
  {
    id: 17,
    text: (
      <>
        <h6>Financial management &amp; accounting</h6>
        <p>
          At our company, we pride ourselves on providing top-tier financial
          management and accounting tools that help streamline data collection
          and analysis for improved decision-making processes at all levels.
        </p>
      </>
    ),
  },
  {
    id: 18,
    text: (
      <>
        <h6 className="title">Enterprise-wide software</h6>
        <p>
          Enterprise-wide software is designed to access multiple users and
          usually has a user roles and permissions hierarchy{" "}
        </p>
      </>
    ),
  },
  {
    id: 19,
    text: (
      <>
        <h6 className="title">Departmental software </h6>
        <p>
          Departmental software is designed to meet department employees'
          specific needs. Custom departmental software can uncover functional
          capabilities not available in mass-market or free tools.
        </p>
      </>
    ),
  },
  {
    id: 20,
    text: (
      <>
        <h6 className="title">Business process management software</h6>
        <p>
          Business process management software addresses a particular business
          workflow and reveals possibilities for automation.
        </p>
      </>
    ),
  },
];

const CustomSoftwareDevelopment = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>
            Custom Software Development Company in India | Origin software
          </title>
          <link
            rel="canonical"
            href="./Custom-Software-Development-Company-in-India"
          />
          <meta
            name="description"
            content="Origin software is a custom software development company in India. We provide web and mobile app development services to startups and SMEs in India"
          />
          <meta
            name="keywords"
            content="Custom software development company in india"
          />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className=" section-padding">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Software Development"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Software-Consulting"
                                  }
                                >
                                  {" "}
                                  Software Consulting
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Custom-Software-Development-Company-in-India"
                                  }
                                >
                                  Custom Software Development
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Software-Development-Outsourcing"
                                  }
                                >
                                  {" "}
                                  Software Development Outsourcing
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Software-Product-Development"
                                  }
                                >
                                  {" "}
                                  Software Product Development
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Software-Development-Automation"
                                  }
                                >
                                  {" "}
                                  Software Development Automation
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Software-Maintenance-and-Support"
                                  }
                                >
                                  {" "}
                                  Software Maintenance and support
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Custom Software Development</h1>
                  <div>
                    <img
                      className="img mb--20"
                      src="../images/Custom Software Development.png"
                      alt="Custom-Software-Development-Company-in-India"
                    />
                  </div>
                  <p>
                    {" "}
                    Origin Softwares is a custom software development company in
                    India. We specialize in helping clients get the most out of
                    our custom software development services.
                  </p>
                  <p>
                    We have a team of dedicated consultants who will help you
                    every step of the way, from gathering and analyzing your
                    business requirements to conceptualizing the software to
                    best fit those needs.
                  </p>
                  <p>
                    And because{" "}
                    <span style={{ fontWeight: "bold" }}>
                      we've successfully completed over 3,300 projects in 30+
                      industries
                    </span>
                    , you can be confident that we have the experience and
                    expertise to deliver a solution that powers your business
                    processes.<br></br>
                    <Link
                      to={process.env.PUBLIC_URL + "/contact"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      Get a Quote
                    </Link>
                  </p>
                  <h3>Origin Softwares Service Scope</h3>
                  <div className="row">
                    {jsonData.services.map((service, index) => (
                      <div key={index} className="col-xl-6 col-lg-2">
                        <ul className="b">
                          <li>
                            <a href={process.env.PUBLIC_URL + service.link}>
                              {service.title}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                  <div className="row mb--20">
                    {allData.slice(0, 9).map((item) => (
                      <div key={item.id} className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <h3 className="mb--20">
                    Custom software development we create and offer
                  </h3>
                  We create custom software tailored to your business's specific
                  needs at Origin Softwares. We piece together the core
                  functionality for the business process in focus and enhance
                  the software with unique software features and capabilities in
                  real-time.
                  <div className="container mt-3">
                    <div className="row">
                      <h4>Software for Different Industries</h4>
                      <div>
                        <Industries />
                      </div>
                    </div>
                  </div>
                  <h4>Software for Various business needs</h4>
                  <div className="row mb--20">
                    {allData.slice(9, 17).map((item) => (
                      <div key={item.id} className="Box col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="container mt-3">
                    <div className="row">
                      <h3 className="mb--20">Our Software Technologies</h3>
                      <div>
                        <Technology />
                      </div>
                    </div>
                  </div>
                  <div className="row mb--20">
                    <h4 className="mb--20">
                      Software for various management levels{" "}
                    </h4>
                    <p className="mb--20">
                      {" "}
                      We create 3 main types of software at our company:
                      enterprise-wide, departmental, and business process
                      management software.
                    </p>
                    {allData.slice(17, 20).map((item) => (
                      <div className="Box1 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Accordion>
                    <h4 className="color">Custom Software FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="Is custom software worth the investment?"
                      body={
                        <>
                          <ul>
                            <p>
                              ► When it comes to custom software, it's essential
                              to weigh the pros and cons carefully before
                              deciding. Some critical pros of custom development
                              include a tailored feature set and unlimited
                              scalability.
                            </p>
                            <p>
                              ► On the downside, custom software can take longer
                              to develop and may require more input from key
                              stakeholders. There is also usually no free trial
                              or demo available before investment.
                            </p>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="2"
                      header="What will be the price of custom software development?"
                      body={
                        <>
                          <ul>
                            <p>
                              ► The cost of custom software development work can
                              vary depending on your project's specifications.
                            </p>
                            <p>
                              ► Some factors that affect price are how many
                              features the software has, how unique the design
                              is, and how many other systems it needs to be
                              compatible with; infrastructure requirements
                              (availability, performance, security, etc.) can
                              also affect the price.
                            </p>
                            <p>
                              ► If you need a more specific estimate for your
                              project, you can always request a free project
                              cost calculation.
                            </p>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="3"
                      header="What cooperation model is better for custom development?"
                      body={
                        <>
                          <ul>
                            <p>
                              ► If you don't have any development resources
                              in-house, you'll need to assemble a dedicated team
                              and outsource your project to a vendor.
                            </p>
                            <p>
                              ► You can use a team augmentation model if you
                              have a team but lack specific competencies.
                            </p>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="4"
                      header="Do we have to know development techniques to choose right?"
                      body={
                        <>
                          <ul>
                            <p>
                              ► You don't need to understand development
                              methodologies to choose the correct option, but if
                              you're curious about the difference, here's a
                              brief overview:
                            </p>
                            <p>
                              ► At Origin Softwares, we use cutting-edge modern
                              technologies like Agile (Scrum, Kanban, XP) to
                              enable fast releases and easy changes.
                            </p>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="5"
                      header=" How can we guarantee the success of a custom software development project?"
                      body={
                        <>
                          <ul>
                            <p>
                              ► There are a few things that can help ensure the
                              success of a custom software development project:
                              <br />● A dedicated project manager organizes the
                              work, communicates with stakeholders, continuously
                              re-evaluates requirements, and manages risks.
                              <br></br>● Using lean UX design principles.
                              <br></br>● Creating clean and stable code with a
                              future-proof architecture.<br></br>● Developing
                              the software products with an eye towards easy
                              support and smooth evolution.
                            </p>
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                  <br />
                  <p>
                    Contact us today to learn more about how we can help you
                    relieve some of the pressure of product management. And Get
                    the custom software development services in India within
                    your budget.<br></br>
                    <Link
                      to={process.env.PUBLIC_URL + "/contact"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      Contact Us
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default CustomSoftwareDevelopment;
