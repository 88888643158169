import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  services: [
    { title: "Android Apps", link: "/Andriod" },
    { title: "iOS Apps", link: "/IOS" },
    {
      title: "React Native Mobile App",
      link: "/React-Native-App-Development-Company",
    },
    { title: "Xamarin Apps", link: "/Xamarin-App-Development" },
    { title: "Hybrid Apps", link: "/Hybrid-App-Development" },
    { title: "Mobile App Design", link: "/Mobile-App-Design-Services" },
    {
      title: "Mobile Ecommerce Apps",
      link: "/Mobile-Ecommerce-App-Development",
    },
  ],
};

const allData = [
  {
    id: 1,
    text: (
      <>
        <h5>Research and Analysis</h5>
        <p>
          At Origin Softwares, we not only design mobile apps, but we also
          develop software. Our team has a wealth of knowledge and experience
          that allows us to cater to each project individually, while also
          understanding general business needs and user expectations.
          <br />
          The requirements and specifications that we create serve as the
          foundation for our mobile app designs.
        </p>
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        <h5>UX Design</h5>
        <p>
          After our team of analysts creates a comprehensive project roadmap,
          it's time for our design team to work their magic. We prioritize
          making apps easy to follow and navigate, so our UX specialists sketch
          layouts and wireframes with the users in mind.
          <br />
          This allows us to create apps that are not only functional but also
          delightful to use.
        </p>
      </>
    ),
  },
  {
    id: 3,
    text: (
      <>
        <h5>UI Design</h5>
        <p>
          Our UI designers work hard to make sure that your app's design is
          consistent with your brand image. We understand that you may want to
          create a visually stand-alone mobile app, and we are eager to present
          our own ideas!
        </p>
      </>
    ),
  },
  {
    id: 4,
    text: (
      <>
        <h5>Customer Satisfaction</h5>
        <p>
          Although we are confident in and proud of the knowledge and talents of
          our BAs and designers, our customer’s opinions mean a lot to us.
          <br />
          We welcome users' feedback and do our best to grasp the vision of an
          ideal mobile app design by iteratively rolling out UI/UX updates based
          on user experience data.
        </p>
      </>
    ),
  },
];

const MobileAppDesign = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>Mobile App Design Services | Origin softwares</title>
          <link rel="canonical" href="./Mobile-App-Design-Services" />
          <meta
            name="description"
            content="We are a leading mobile app design services company in India, offering creative and cost-effective Android and iOS app development services."
          />
          <meta name="keywords" content="Mobile app design services" />
        </Helmet>
        <main className="main wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Mobile Apps"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link to={process.env.PUBLIC_URL + "/Andriod"}>
                                  Android Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link to={process.env.PUBLIC_URL + "/IOS"}>
                                  iOS Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/React-Native-App-Development-Company"
                                  }
                                >
                                  React Native Mobile App
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Xamarin-App-Development"
                                  }
                                >
                                  Xamarin Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Hybrid-App-Development"
                                  }
                                >
                                  Hybrid Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Mobile-App-Design-Services"
                                  }
                                >
                                  Mobile App Design
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Mobile-Ecommerce-App-Development"
                                  }
                                >
                                  Mobile Ecommerce Apps
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Mobile App Design</h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/Mobile App Design.png"
                    alt="Mobile-App-Design-Services"
                  />
                  <p>
                    Origin Softwares mobile app design services are key in the
                    creation of successful and user-friendly apps. Our team of
                    experts has been delivering sleek, responsive designs with
                    transparent navigation since the early 2013. We know what it
                    takes to create an app with a high conversion rate and easy
                    adoption.
                  </p>

                  <div className="row">
                    <h4>Our Mobile App Design Service Scope</h4>
                    {allData.slice(0, 4).map((item) => (
                      <div className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Our Core Technologies</h3>
                    <div>
                      <Technology />
                    </div>
                  </div>

                  <div className="row">
                    <div className="paper col-xl-12 col-lg-2">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <h4>Platforms We Target</h4>
                          <p>
                            Origin Softwares team of experts creates UI/UX
                            designs that are both user-friendly and trendy, be
                            it a native or cross-platform app. <br></br>
                            We have a wealth of experience in implementing sleek
                            designs for iOS and Android apps, as well as for
                            applications on Cordova, Ionic, Flutter, and
                            Xamarin.
                          </p>
                          <h4> Our Featured Mobile App Designs</h4>
                          <Link
                            to={process.env.PUBLIC_URL + "/Portfolio"}
                            className="axil-btn btn-fill-primary btn-small"
                          >
                            Portfolio
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <h3 className="mb--20">We Deal with this Industries</h3>
                    <div>
                      <Industries />
                    </div>
                  </div>

                  <h4>App Development</h4>
                  <p>
                    UI/UX design is a core part of our mobile app development
                    services. This important part of our work relies on
                    efficient cross-team communication and time-proved
                    methodologies that ease the entire process from developing a
                    prototype to releasing the app.
                  </p>

                  <div className="row">
                    <h4 className="mb--20">Mobile App Services</h4>
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className="b">
                          <li>
                            <a href={process.env.PUBLIC_URL + services.link}>
                              {services.title}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>

                  <Accordion>
                    <h4 className="color">Mobile App Design FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="Need a Sleek Design for Your Mobile App?"
                      body={
                        <>
                          <ul>
                            If you want to go with low-code development,
                            technologies require little to no coding to build
                            applications.
                            <br />
                            <br />► At Origin Softwares serving mobile app
                            design services, we understand that time is of the
                            essence when it comes to developing a new app.
                            That's why we offer to provide sample UX wireframes
                            within just one week of starting discussions about
                            your concept. <br />
                            ► And once those wireframes have passed our rigorous
                            UX testing process, we'll begin working on
                            high-quality UI visuals that meet all of your
                            requirements.
                            <br />
                            <Link
                              to={process.env.PUBLIC_URL + "/contact"}
                              className="axil-btn btn-fill-primary btn-small"
                            >
                              Contact Us
                            </Link>
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default MobileAppDesign;
// 264
