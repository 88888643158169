import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {FaWhatsapp, FaFacebookF, FaTwitter, FaLinkedinIn,  FaPhone, FaFax, FaEnvelope, FaInstagram } from "react-icons/fa";
// FaBehance , FaMapMarkedAlt



const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton>
            <div className='center'>
                <img className="center1" src={process.env.PUBLIC_URL + "/images/logo-1.png"} alt="Site Logo" />
            </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <form action="#" className="side-nav-search-form">
                    <div className="form-group">
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15229.07579997471!2d78.4472157!3d17.398876!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb977d15dafc6b%3A0x94ba7b774d9ffdb9!2sOrigin%20Tours%20and%20Travels!5e0!3m2!1sen!2sin!4v1722524118729!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    <iframe title="This is a unique title" style={{ width:"100%", height:"300px"}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15229.07579997471!2d78.4472157!3d17.398876!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb977d15dafc6b%3A0x94ba7b774d9ffdb9!2sOrigin%20Tours%20and%20Travels!5e0!3m2!1sen!2sin!4v1722524118729!5m2!1sen!2sin"></iframe>
                    
                        {/* <input type="text" className="search-field" name="search-field" placeholder="Search..." />
                        <button className="side-nav-search-btn"><i className="fas fa-search"></i></button> */}
                    </div>
                </form>
                <div className="row">
                    <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                    <li><Link to={process.env.PUBLIC_URL + "/"}>Home </Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/AboutUs"}>About Us</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/Portfolio"}>Portfolio</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/CaseStudy"}>Case Studies</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/Contact"}>Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Contact Information</span>
                                    <p>Third Floor, Serene Heights Above Ratnadeep Masab Tank,<br/>
                                        Humayun Nagar Road,<br/>
                                        Hyderabad, Telangana - 500028<br/></p>
                                </address>
                                <address className="address">
                                    <span className="title">We're Available 24/7. Call Now.</span>
                                    <a href="tel:+91 - 96140 85555" className="tel"><FaPhone /> +91 - 96140 85555</a>
                                    <a href="tel:+91 4035720216" className="tel"><FaFax /> +91 4035720216</a>
                                    <a href="info@originsoftwares.com"><FaEnvelope />info@originsoftwares.com</a> 
                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title">Find us here</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li>
                                            <a href="https://wa.me/919614085555/" title="Whatsapp"><FaWhatsapp /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/originsoftwares1/"><FaFacebookF /></a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/originsoftware1"><FaTwitter /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/originsoftwares/"><FaLinkedinIn /></a>
                                        </li>
                                        <li>
                                            <a href='https://www.instagram.com/originsoftwares/'><FaInstagram/></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
