import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import { Helmet } from "react-helmet-async";
import GoogleTagManager from "./GTM";

const VisionAndMission = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>Vision and mission | Origin software</title>
          <link rel="canonical" href="./VisionAndMission" />
          <meta
            name="description"
            content="Learn about our mission and vision as a software development company. We strive to provide cutting-edge solutions and exceptional service to drive success"
          />
          <meta name="keywords" content="Vision and mission" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <div className="section bg-color-light section-padding">
            <div className="container">
              <SectionTitle title="Vision And Mission" />
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default VisionAndMission;
