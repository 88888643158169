import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology";
import Industries from "../component/Industries/Industries.js";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  services: [
    {
      title: "Automate Digital Marketing",
      link: "/Digital-Marketing-Agency-in-Hyderabad",
    },
    {
      title: "ECommerce Website Design",
      link: "/Ecommerce-Website-Development-Services",
    },
  ],
};

const allData = [
  {
    id: 1,
    text: (
      <>
        <h5>Technology and Market Entry Consulting</h5>
        <ul className="b">
          <li>
            {" "}
            eCommerce platforms are a dime a dozen, but we will find one that
            suits your needs which can be like finding a needle in a haystack.
          </li>
          <li>
            Finding the right platform but also making sure that it has the
            potential to grow along with your business{" "}
          </li>
          <li>Research the target audience and the competition</li>
          <li>
            It is aware of government regulations that might prohibit or
            restrict your business' growth
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        <h5>Business Optimization and Operational Consulting</h5>
        <ul className="b">
          <li>Business workflows can often get stalled</li>
          <li>
            Get involved in planning a cross-system integration landscape and
            implementing a business-wide data management strategy
          </li>
          <li>
            To improve business KPIs is to review your digital experience
            strategy
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 3,
    text: (
      <>
        <h5>Custom eCommerce Website Development</h5>
        <ul className="b">
          <li>Headless PWAs - Advancement in eCommerce</li>
          <li>AI-based personalization</li>
          <li>Voice-based commerce</li>
          <li>Augmented Reality experience</li>
        </ul>
      </>
    ),
  },
  {
    id: 4,
    text: (
      <>
        <h5>UX and UI Design</h5>
        <ul className="b">
          <li>
            Research-based Personas &amp; user stories: To know the user needs
            and how they will use the site
          </li>
          <li>
            Wireframes and prototypes - creating the basic layout, structure,
            and content
          </li>
          <li>Testing the Online shopping store usability and appearance</li>
          <li>
            A UI kit - to have a consistent style across all the eCommerce
            businesses' websites
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 5,
    text: (
      <>
        <h5>eCommerce Integration</h5>
        <ul className="b">
          <li>ERP - Enterprise resource planning</li>
          <li>Automate marketing</li>
          <li>Support and customer service</li>
          <li>CM - Content management </li>
          <li>PIM - Product information management</li>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    text: (
      <>
        <h5>Ecommerce Audit</h5>
        <ul className="b">
          <li>Accessibility of the eCommerce website</li>
          <li>
            Fixing the code patches that are impacting the eCommerce website
          </li>
          <li>Updating the security issues</li>
          <li>Whole infrastructure checkup</li>
        </ul>
      </>
    ),
  },
  {
    id: 7,
    text: (
      <>
        <h5>What we Test:</h5>
        <ul className="b">
          <li>Usability</li>
          <li>System integrations</li>
          <li>Content analysis and SEO aspects</li>
          <li>Performance</li>
          <li>Login and Security</li>
        </ul>
      </>
    ),
  },
  {
    id: 8,
    text: (
      <>
        <h5>Webstore Setup</h5>
        <ul className="b">
          <li>Bringing all the features to life.</li>
          <li>Work on Front-end &amp; back-end development.</li>
          <li>Mobile store development.</li>
        </ul>
      </>
    ),
  },
  {
    id: 9,
    text: (
      <>
        <h5>Business performance optimization</h5>
        <ul>
          <li> Including the Advanced Analytics</li>
          <li> Widen the customer acquisition channels</li>
          <li> Working on Omni channel approach</li>
          <li>
            {" "}
            Fixing the issues via audit and improving the layout and design if
            required
          </li>
          <li> Optimizingfor Seamless checkout process</li>
        </ul>
      </>
    ),
  },
  {
    id: 10,
    text: (
      <>
        <h5> Adding the Omni channel approach in the marketing</h5>
        <ul>
          <li> PPC - Pay per click </li>
          <li> SMM - social media marketing</li>
          <li> SEO - Search engine optimization</li>
          <li> Content marketing</li>
        </ul>
      </>
    ),
  },
  {
    id: 11,
    text: "An eCommerce store (business to consumer b2c) is all about making sure your customers have the best possible experience. Online retailers must constantly adapt to new industry trends and find ways to personalize shopper interactions. Many have already succeeded in retailer sales!",
  },
  {
    id: 12,
    text: "You can learn more about how to create a great customer experience by watching our demo. Explore our eCommerce development services if you feel ready to trust our team with your eCommerce project.",
  },
];

const EcommerceDevelopment = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>
            Ecommerce Services For Modern Businesses | Origin softwares
          </title>
          <link rel="canonical" href="./E-Commerce-Services" />
          <meta
            name="description"
            content="Ecommerce Services for Modern Businesses is a one-stop solution for all your e-commerce needs Visit our site now."
          />
          <meta name="keywords" content="Ecommerce services" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="ECommerce Services"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Ecommerce-Website-Development-Services"
                                  }
                                >
                                  ECommerce Website Design
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Ecommerce Services</h1>
                  <img
                    className="img mb--20"
                    src="../images/ECOMMERCE DEVELOPMENT.png"
                    alt="E-Commerce-Services"
                  ></img>
                  <p>
                    Origin Softwares eCommerce services are provided by a team
                    of Back-end &amp; Front-end professionals with more than 9
                    years of experience in the eCommerce industry. <br />
                    We have a wide range of e-commerce solutions to make your
                    business model successful. We are passionate about helping
                    our customers achieve their goals and deliver exceptional
                    service.
                  </p>
                  <h4>Ecommerce Website Development Services</h4>
                  <p>
                    Origin Softwares specializes in creating eCommerce Solutions
                    for eCommerce sites. Our team of experts will design and
                    develop your online stores from scratch or help you to
                    update/ redesign your existing website.
                  </p>

                  <div className="row">
                    <h4>eCommerce Services (Consulting)</h4>
                    {allData.slice(0, 8).map((item) => (
                      <div className="Box3 col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h4>Ecommerce services for Growth and Optimization</h4>
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className="b">
                          <li>
                            <a href={process.env.PUBLIC_URL + services.link}>
                              {services.title}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h4>Our Technology Stack</h4>
                    <Technology />
                  </div>

                  <div className="row">
                    <h4>eCommerce Services (Consulting)</h4>
                    {allData.slice(8, 10).map((item) => (
                      <div className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <h4 className="mb--20">
                    We Can Get Solutions for Your E-commerce Challenges
                  </h4>
                  <p>
                    We are a team of eCommerce services experts and have worked
                    in the most demanding situations. Now we have come together
                    to create one of the finest e-commerce platforms for
                    start-ups &amp; small businesses.
                  </p>

                  <div className="row">
                    <h4>B2C eCommerce Demo</h4>
                    {allData.slice(10, 12).map((item) => (
                      <div className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Our Industries</h3>
                    <div>
                      <Industries />
                    </div>
                  </div>

                  <Accordion>
                    <h4 className="color">ECommerce Services FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="What is it like to have a customer-centered experience?"
                      body={
                        <>
                          At Origin Softwares, we have seen how customer
                          experience standards and expectations have grown and
                          changed over the years. To stay ahead of the game,
                          companies must be aware of the fundamental rules of
                          customer experience.
                          <br />
                          <h4>Support &amp; Maintenance eCommerce Services</h4>
                          <h6>eCommerce testing</h6>
                          <ul className="b">
                            <li>
                              {" "}
                              Some key rules include having a consistent
                              omnichannel brand presence and a company-wide
                              commitment to customer-centricity.
                            </li>
                            <li>
                              {" "}
                              Businesses must be willing to invest in dedicated
                              customer experience consultants to ensure that
                              their efforts are targeted and effective.
                            </li>
                            <li>
                              {" "}
                              Businesses need to think about more than just
                              one-time conversions regarding marketing but to
                              focus on building customer loyalty from the
                              beginning.
                            </li>
                            <li>
                              {" "}
                              It involves personalizing the customer experience
                              in a helpful and relevant way. Some areas to focus
                              on include website content, product
                              recommendations, email communication, and customer
                              support service.
                            </li>
                            <li className="mb--20">
                              {" "}
                              Let our team of experienced e-commerce developers
                              help you get ahead of the competition with a
                              custom-tailored, exceptional customer experience.
                            </li>

                            <h6>B2B eCommerce Portal Demo</h6>

                            <li>
                              {" "}
                              At Origin Softwares, we develop e-commerce portals
                              that help businesses B2B establish and nurture
                              long-term, productive relationships with
                              customers.
                            </li>
                            <li>
                              {" "}
                              We believe that online presence is essential for
                              any modern business, so we prioritize creating
                              functional and easy-to-use portals.
                            </li>
                            <li>
                              {" "}
                              Our team has a wealth of experience developing
                              B2-B eCommerce platforms, and we use this
                              knowledge to create portals designed with the
                              customer journey in mind.{" "}
                            </li>
                            <li className="mb--20">
                              {" "}
                              With our B2B demo format, we show how we realize
                              these key features and share our vision of a
                              customer-oriented approach to business.
                            </li>

                            <h6>B2B Demo - Our Strengths</h6>

                            <li>
                              {" "}
                              We understand how crucial it is to earn your
                              customers' trust in the B2B eCommerce world. We
                              know that your buyers are people who want to get
                              things done, so we don't overload our websites
                              with a bunch of elements that get in the way of
                              navigation.
                            </li>
                            <li>
                              {" "}
                              We think carefully about each customer's journey
                              on your portal and design an intuitive purchasing
                              process that lays the foundation for a long and
                              fruitful relationship with buyers. Our B2B
                              solutions feature:
                            </li>
                            <li>
                              {" "}
                              Your customers should have no trouble finding what
                              they're looking for, whether it's through a
                              catalog or search box.
                            </li>
                            <li>
                              {" "}
                              They should be able to compare products and make
                              informed decisions. And when it comes to customer
                              service, they should be able to purchase in bulk
                              or pre-order with ease.
                            </li>
                            <li>
                              {" "}
                              Product pages should be informative and answer all
                              customer questions, from available data to
                              technical details. Doing so allows for thought-out
                              purchasing decisions from the customer and selling
                              the products.
                            </li>
                            <li>
                              {" "}
                              Flexible shipping terms help your customers save
                              time by eliminating the need to place several
                              orders with delivery to different addresses.
                            </li>
                            <li>
                              {" "}
                              Additionally, loyalty builders like saved lists
                              engage customers and allow them to reserve goods
                              for later, encouraging repeat purchases and
                              customer loyalty.
                            </li>
                            <li>
                              {" "}
                              Buyers feel they will receive immediate support
                              when they have direct access to a personal
                              manager.
                            </li>
                            <li className="mb--20">
                              {" "}
                              The security of customer information and your
                              business is a priority, so role-based access
                              control ensures that each employee has the
                              necessary permission.
                            </li>

                            <h6>FACTS OF B2B eCOMMERCE THAT YOU SHOULD KNOW</h6>

                            <li>
                              {" "}
                              It's no wonder that B2B buyers are doing more
                              research online before making a purchase decision
                              - e-commerce is expected to generate $7.4 trillion
                              in revenue by 2025.{" "}
                            </li>
                            <li>
                              {" "}
                              What's more, e-commerce is seen as an important
                              revenue opportunity by B2-B companies. By
                              understanding these buying trends, businesses can
                              better adapt their online sales and marketing
                              strategies to address the needs of today's buyers.
                            </li>
                            <li>
                              {" "}
                              Introducing your company to customers via a B2B
                              e-commerce portal is a great way to ensure you're
                              making the most of this lucrative opportunity.{" "}
                            </li>
                            <li>
                              {" "}
                              Our B2B demo shows you just how much care and
                              attention to detail we put into every aspect of
                              creating an effective portal.
                            </li>
                            <li className="mb--20">
                              {" "}
                              We know that when done right, your portal can
                              serve as a powerful sales tool that works around
                              the clock, without breaks, sick days, or overtime
                              complaints!
                            </li>

                            <h6>B2B PORTALS DESIGNED BY PROFESSIONALS</h6>

                            <li>
                              {" "}
                              In portal design, our focus is always on giving
                              you the tools you need to build strong
                              relationships with your customers and keep them
                              coming back for more.
                            </li>
                            <li>
                              {" "}
                              We know that customer loyalty is key to any
                              successful business, so we ensure that our portals
                              are packed with features that will help you foster
                              those long-lasting relationships.
                            </li>
                            <Link
                              to={process.env.PUBLIC_URL + "/contact"}
                              className="axil-btn btn-fill-primary btn-small"
                            >
                              Get A Quote
                            </Link>
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default EcommerceDevelopment;
