import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import TestimonialTwo from "../component/testimonial/TestimonialTwo";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";
import SideStickMenu from "./SideStickMenu.js";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const allData = [
  {
    id: 1,
    text: "Adherence to code rules, precise and descriptive variable names, comments explaining what particular code functions and methods do, and a README that documents what the whole code does and its dependencies.",
  },
  {
    id: 2,
    text: "In addition, code should be split into short units and portable so that it can be used on different systems.",
  },
  {
    id: 3,
    text: "Unit tests should be conducted regularly to ensure the code works as intended.",
  },
  {
    id: 4,
    text: "Finally, using linting tools like SonarQube and ESLint can help identify potential errors in the code.",
  },
  {
    id: 5,
    text: "We can provide Node.js resources to augment your project team(s) or cover full-cycle Node.js application development.",
  },
  {
    id: 6,
    text: "It includes project management, business analysis, UX and UI design, testing, and QA.",
  },
  {
    id: 7,
    text: " Our experienced and highly skilled professionals can help ensure that your software is delivered on time and within budget while meeting all business and user needs.",
  },
  {
    id: 8,
    text: "Team augmentation with Node.js developers can help you fill the skill gaps and get the job done quickly and efficiently.",
  },
  {
    id: 9,
    text: "T&amp;M is the most suitable pricing model for this type of work. Alternatively, you could outsource the entire node.js development services project or part of it to reduce management efforts and streamline project delivery.",
  },
];

const NodeJs = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>Node.js Development services | Origin software</title>
          <link rel="canonical" href="./Node-Js-Development-Services" />
          <meta
            name="description"
            content="Origin software is a cloud-based Node.js development platform (as-a-service) that allows enterprises to build and deploy Node.js applications in the cloud."
          />
          <meta name="keywords" content="node.js development services" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <SideStickMenu />
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Node.js Development </h1>
                  <img
                    className="img mb--20"
                    src="../images/Node JS.png"
                    alt=""
                  ></img>
                  <p>
                    If you're looking for a company that provides Node.js
                    development services, you've come to the right place.
                    <br />
                    Origin Softwares developers use Node.js to efficiently
                    deliver fast, highly scalable software solutions for
                    large-scale industries, including healthcare, retail, and
                    financial services.
                  </p>

                  <h4>Our Node. Js-Related Development Skills</h4>
                  <div className="row">
                    <div>
                      <Technology />
                    </div>
                  </div>

                  <h4>Featured Node.js Development Projects</h4>
                  <Link
                    to={process.env.PUBLIC_URL + "/Portfolio"}
                    className="axil-btn btn-fill-primary btn-small"
                  >
                    {" "}
                    Portfolio
                  </Link>

                  <h4>Our Industry Expertise</h4>
                  <div className="row">
                    <div>
                      <Industries />
                    </div>
                  </div>

                  <div className="row">
                    <h4 className="mb--20">
                      Our Code Quality Practices in Node.js Development
                    </h4>
                    <p>There are a few things that good code should have:</p>
                    {allData.slice(0, 4).map((item) => (
                      <div className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h4>Choose Your Cooperation hiring Model.</h4>
                    {allData.slice(4, 9).map((item) => (
                      <div className="Box3 col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <br />

                  <div className="row">
                    <h4>Our Experts Say</h4>
                    <TestimonialTwo />
                  </div>
                  <p className="mb--20">
                    <Link
                      to={process.env.PUBLIC_URL + "/contact"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      {" "}
                      Contact Us
                    </Link>
                  </p>

                  <Accordion>
                    <h4 className="color">Node.Js FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="Looking for a Node.js Development Company?"
                      body={
                        <>
                          <ul>
                            ► Our Node.js outsourcing services are of the
                            highest quality, due in part to our pre-vetted
                            Node.js developers and dedicated node.js teams.
                            <br />
                            ► Additionally, we provide high-performance
                            development solutions with Node.js at the core.
                            Contact us today to learn more about how we can help
                            you achieve your software development goals and
                            business requirements.
                            <br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="2"
                      header="Why Origin Softwares?"
                      body={
                        <>
                          <ul>
                            ► At Origin Softwares, our developers have an
                            average of 9 years of experience working with
                            Node.js.
                            <br />
                            ► We can set up cooperation within 5 days and
                            specialize in cutting-edge technologies like
                            computer vision, machine learning, IoT, big data,
                            AR, VR, and blockchain.
                            <br />
                            ► Our development processes are mature and include
                            Agile methodology, DevOps practices, and
                            cloud-native development.
                            <br />
                            ► Our headquarters are located in Hyderabad, but we
                            also have offshore development centers in Saudi
                            Arabia and offices in USA, UK, and Sweden.
                            <br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="3"
                      header="What do We develop with Node.js?"
                      body={
                        <>
                          <ul>
                            ► With Node.js, we develop a wide range of web and
                            mobile apps (data-intensive real-time apps,
                            cloud-native, single-page), web app server side, and
                            custom APIs.
                            <br />
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default NodeJs;
// 302
