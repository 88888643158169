import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import CounterPhp from "../component/counterup/CounterPhp";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology";
import TestimonialTwo from "../component/testimonial/TestimonialTwo";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";
import SideStickMenu from "./SideStickMenu";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const allData = [
  {
    id: 1,
    text: "Our PHP website consultants can help you select the right technology mix for your project, or provide expert recommendations on how to solve tech challenges in an ongoing project.",
  },
  {
    id: 2,
    text: "We can help you identify system bottlenecks, check security vulnerabilities and prepare an optimal scalability map.",
  },
  {
    id: 3,
    text: "Depending on the level of interaction you need, you can choose either an offshore dedicated team or team augmentation.",
  },
];

const Php = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>PHP development services company | Origin software</title>
          <link rel="canonical" href="./Php-Development-Services" />
          <meta
            name="description"
            content="Origin software is a PHP development services company offering latest and advance technology.We provide web application that serves your business needs."
          />
          <meta name="keywords" content="php development services" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <SideStickMenu />
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">PHP Development</h1>
                  <img
                    className="img mb--20"
                    src="../images/PHP.png"
                    alt=""
                  ></img>
                  <p>
                    Origin Softwares PHP development services are perfect for
                    those who need secure and powerful server-sides for their
                    web applications. With our extensive experience in PHP web
                    app development, we are able to tame the language and
                    deliver robust web portals, B2C &amp; B2B web apps, CMSs,
                    and other web-based PHP solutions.
                    <br />
                    <br />
                    Trust us for your next custom PHP development project and
                    enjoy the benefits that come while working with Origin
                    Softwares.
                  </p>
                  <div className="row">
                    <CounterPhp
                      colSize="col-lg-3 col-6"
                      layoutStyle=""
                      evenTopMargin=""
                    />
                  </div>
                  <br />

                  <h4 className="mb--20">Partnerships</h4>
                  {/* <p>Partnerships with Magento, Pimcore, and Akeneo.<br/> Add Partnerships here</p> */}

                  <div className="row">
                    <h4 className="mb--20">Our Happy Customers</h4>
                    <TestimonialTwo />
                  </div>
                  <br />

                  <div>
                    <h4 className="mb--20">Technology Competencies</h4>
                    <Technology />
                  </div>

                  <div className="row">
                    <h4 className="mb--20">Choose Your Cooperation Model</h4>
                    {allData.slice(0, 3).map((item) => (
                      <div className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <br />

                  <h4 className="mb--20">Selected Projects</h4>
                  <Link
                    to={process.env.PUBLIC_URL + "/Potfolio"}
                    className="axil-btn btn-fill-primary btn-small"
                  >
                    Portfolio
                  </Link>

                  <h4 className="mb--20">Request CV</h4>
                  <p>
                    <ul className="b">
                      <li>
                        If you're looking for PHP developers or PHP development
                        services experts who can help contribute to the success
                        of your projects, look no further than our team of 55+
                        developers. We're confident that we have the right
                        people for the job, so request a CV today.
                      </li>
                    </ul>
                  </p>

                  <Accordion>
                    <AccordionItem
                      eventKey="1"
                      header="What Makes Our PHP Web Development Company Stand Out?"
                      body={
                        <>
                          <ul>
                            ► At Origin Softwares, our team of experts has
                            worked across a wide range of industries, including
                            eCommerce, healthcare, banking, manufacturing,
                            logistics, and professional services. <br />
                            ► This allows us to really understand your business
                            requirements in the best possible way.
                            <br />
                            ► When it comes to software design or web designing,
                            we always make sure that it is stable (with load
                            balancing, system health checks, and failure
                            isolation), scalable (to be prepared for multiple
                            nodes working in parallel), and responsive front-end
                            (thanks to the back end being optimized with
                            asynchronous calls, microservices, etc.).
                            <br />
                            ► Technologies like AI, predictive analytics, and
                            IoT can give you a competitive edge in software
                            development. But knowing which technology to use for
                            your project can be a challenge.
                            <br />
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default Php;
// 204
