import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your Message has been successfully sent.
    </Alert>
  );
};

function CareerForm() {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    const numericInput = event.target.value.replace(/[^0-9+()-]/g, ""); // Remove all non-numeric characters
    setInputValue(numericInput);
  };

  const form = useRef();

  const [result, showresult] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 10 * 1024 * 1024) {
      setSelectedFile(file);
      setErrorMessage("");
    } else {
      setSelectedFile(null);
      setErrorMessage("File must be less than 10MB.");
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      console.log("Uploading file:", selectedFile);
    } else {
      console.log("No file selected.");
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("contact-name", form.current["contact-name"].value);
    formData.append("contact-email", form.current["contact-email"].value);
    formData.append("contact-phone", form.current["contact-phone"].value);
    formData.append(
      "Tell About YourSelf",
      form.current["contact-message"].value
    );
    formData.append("cv", selectedFile);

    emailjs
      .sendForm(
        "service_dwxrdc6",
        "template_a1u31xv",
        form.current,
        "_1XDcSPjowbppxkUr"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset();
    setSelectedFile();
    showresult(!result);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
      <div className="middle col-xl-8 col-lg-6">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            name="contact-name"
            placeholder="Full Name"
            required
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            name="contact-email"
            placeholder="Email ID"
            required
          />
        </div>
        <div className="form-group">
          <label>Phone</label>
          <input
            type="tel"
            value={inputValue}
            onChange={handleInputChange}
            className="form-control"
            name="contact-phone"
            placeholder="Mobile No."
            required
          />
        </div>
        <div className="form-group">
          <label>Upload CV</label>
          <input type="file" onChange={handleFileChange} />
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </div>
        <div className="form-group">
          <label>Tell About YourSelf</label>
          <textarea
            type="message"
            className="form-control"
            name="contact-message"
            placeholder="Tell About Yourself"
            style={{ height: "150px" }}
            required
          />
        </div>
        <div className="form-group">
          <button
            type="submit"
            className="axil-btn btn-fill-primary btn-small"
            name="submit-btn"
            onClick={handleUpload}
          >
            Submit
          </button>
        </div>
        <div className="form-group">{result && <Result />}</div>
      </div>
    </form>
  );
}

export default CareerForm;
