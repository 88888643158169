import { Link } from "react-router-dom";
export const menuItems = [
  {
    title: <Link to={process.env.PUBLIC_URL + "/"}>Home </Link>,
  },
  {
    title: <Link to={process.env.PUBLIC_URL + "/AboutUs"}>About Us</Link>,
  },
  {
    title: <Link to={process.env.PUBLIC_URL + "#"}>Services</Link>,
    submenu: [
      {
        title: <Link to={process.env.PUBLIC_URL + "/Software-Development-Company-in-India"}>Software Development</Link>,
        submenu: [
          {
            title: <Link to={process.env.PUBLIC_URL + "/Software-Consulting"}>Software Consulting</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Custom-Software-Development-Company-in-India"}>Custom Software Development</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Software-Development-Outsourcing"}>Software Development Outsourcing</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Software-Product-Development"}>Software Product Development</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Software-Development-Automation"}>Software Development Automation</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Software-Maintenance-and-Support"}>Software Maintenance and Support</Link>,
          }
        ]
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Web-Designing-Company-in-Hyderabad"}>Website Design</Link>,
        submenu: [
          {
            title: <Link to={process.env.PUBLIC_URL + "/UI-Design-Services"}>UI Design</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/UX-Design-Services"}>UX Design</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Responsive-Web-Design-Services"}>Responsive Web Design</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Responsive-Web-Application"}>Responsive Web App Design</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Website-Redesign-Services"}>Website Redesign</Link>,
          }
        ]
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Web-Development-Company-in-Hyderabad"}>Web Development</Link>,
        submenu: [
          {
            title: <Link to={process.env.PUBLIC_URL + "/Responsive-Web-Design-Services"}>Responsive Web Design</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Responsive-Web-Application"}>Responsive Web App Design</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Website-Redesign-Services"}>Website Redesign</Link>,
          }
        ]
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/E-Commerce-Services"}>Ecommerce Services</Link>,
        submenu: [
          {
            title: <Link to={process.env.PUBLIC_URL + "/Ecommerce-Website-Development-Services"}>ECommerce Website Design</Link>,
          }
        ]
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Mobile-App-Development-Services"}>Mobile Apps</Link>,
        submenu: [
          {
            title: <Link to={process.env.PUBLIC_URL + "/Andriod"}>Andriod Apps</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/IOS"}>iOS Apps</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/React-Native-App-Development-Company"}>React Native Mobile App</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Xamarin-App-Development"}>Xamarin Apps</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Hybrid-App-Development"}>Hybrid Apps</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Mobile-App-Design-Services"}>Mobile App Design</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Mobile-Ecommerce-App-Development"}>Mobile Ecommerce Apps</Link>,
          }
        ]
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Branding-Agency-in-Hyderabad"}>Corporate Branding</Link>,
        submenu: [
          {
            title: <Link to={process.env.PUBLIC_URL + "/Logo-Design-Company-in-India"}>Logo Design</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Brochure-Design-India"}>Brochure</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Presentation-Design-India"}>Presentation</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Promotional-Products"}>Promotional Material</Link>,
          }
        ]
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Digital-Marketing-Agency-in-Hyderabad"}>Digital Marketing</Link>,
        submenu: [
          {
            title: <Link to={process.env.PUBLIC_URL + "/SEO-Services-in-Hyderabad"}>SEO</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Social-Media-Marketing-agency-in-India"}>SMM</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/PPC-Services-India"}>PPC</Link>,
          }
        ]
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/IT-Staffing-services"}>IT Staffing</Link>,
        submenu: [
          {
            title: <Link to={process.env.PUBLIC_URL + "/Recruitment-Services"}>Recruitment</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Staffing-Resources"}>Resources</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Contract-Staffing-Services"}>Contract Staffing</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Direct-Hire"}>Direct Hire</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Executive-Search"}>Executive Search</Link>,
          },
          {
            title: <Link to={process.env.PUBLIC_URL + "/Offshore-Staffing"}>OffShore Staffing</Link>,
          }
        ]
      }
    ],
  },
  {
    title: <Link to={process.env.PUBLIC_URL + "#"}>Technologies</Link>,
    submenu: [
      {
        title: <Link to={process.env.PUBLIC_URL + "/Java-Development-Company"}>Java</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Node-Js-Development-Services"}>Node.Js</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Hire-Python-Developers"}>Python</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/IOS-App-Development-Services"}>iOS</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Andriod-App-Development-Services"}>Andriod</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Angular-Development-Services"}>Angular</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Flutter-App-Development-Company"}>Flutter</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Dot-Net-Application-development"}>.Net</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Php-Development-Services"}>PHP</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/React-js-development-services"}>React</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Magento-Development-Services"}>Magento</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/WordPress-Development-Company-in-India"}>Word Press</Link>,
      }
    ],
  },
  {
    title: <Link to={process.env.PUBLIC_URL + "#"}>Industries</Link>,
    submenu: [
      {
        title: <Link to={process.env.PUBLIC_URL + "/Automobile"}>Automobile</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Banking"}>Banking</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Construction"}>Construction</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Corporate"}>Corporate</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/ECommerce"}>Ecommerce</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Education"}>Education</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Fashion"}>Fashion</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/FMCG"}>FMCG</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Footwear"}>Footwear</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Healthcare"}>Health Care</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Manufacturing"}>Manufacturing</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/NGO"}>NGO</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/Restaurent"}>Restaurant</Link>,
      },
      {
        title: <Link to={process.env.PUBLIC_URL + "/TravelAndHospitality"}>Travel &amp; Hospitality</Link>,
      }
    ],
  },
  {
    title: <Link to={process.env.PUBLIC_URL + "/Portfolio"}>Portfolio </Link>,
  },
  {
    title: <Link to={process.env.PUBLIC_URL + "/"}>Case Studies</Link>,
    // CaseStudy
  },
  {
    title: <Link to={process.env.PUBLIC_URL + "/Contact"}>Contact Us</Link>,
  }
];
