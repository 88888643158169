import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";
import SideStickMenu from "./SideStickMenu";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const allData = [
  {
    id: 1,
    text: "The answer to both of those questions is a resounding yes! We have certified Adobe Commerce Magento developers and solution specialists on our team who always put the needs of the client first.",
  },
  {
    id: 2,
    text: "We firmly believe that cooperation between our company and other eCommerce businesses can be extremely productive and result-oriented.",
  },
  {
    id: 3,
    text: "If you're thinking about Adobe Commerce for your business but are worried about the cost, don't be. Every business has different needs, so the cost of development will vary depending on what you need.",
  },
  {
    id: 4,
    text: "You can always get a free quote from us to get an idea of what your specific magento project would cost. And if you're worried about ROI, don't be.",
  },
  {
    id: 5,
    text: "We can help you calculate the investments needed and estimate the ROI so you can make an informed decision.",
  },
];

const Magento = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>
            Magento Development Services in India | Origin softwares
          </title>
          <link rel="canonical" href="./Magento-Development-Services" />
          <meta
            name="description"
            content="Magento Development Services in India - We are the pioneers in providing Magento Real-time solutions and customization services."
          />
          <meta name="keywords" content="Magento development services" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <SideStickMenu />
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Magento Development</h1>
                  <img
                    className="img mb--20"
                    src="../images/Magento.png"
                    alt=""
                  ></img>
                  <p className="mb--20">
                    Origin Softwares provides Adobe Commerce - Magento
                    development services that cover all technology-related needs
                    for online businesses.
                    <br />
                    We have a team of certified experts who rely on their PHP
                    coding skills and Adobe Commerce knowledge to ensure service
                    quality. Our team has the development and solution design
                    expertise to deliver smooth communication and results that
                    meet your expectations.
                  </p>

                  <h4 className="mb--20">Our Magento Projects</h4>
                  <Link
                    to={process.env.PUBLIC_URL + "/Portfolio"}
                    className="axil-btn btn-fill-primary btn-small"
                  >
                    Portfolio
                  </Link>
                  <p>
                    <ul className="b">
                      Here at Origin Softwares, we frequently get asked a lot of
                      questions related to Adobe Commerce.
                      <li>Do we undergo Adobe Commerce certifications?</li>
                      <li>
                        Are we interested in partnering up with eCommerce
                        businesses and digital agencies as an implementation
                        partner for Adobe Commerce?
                      </li>
                    </ul>
                  </p>

                  <div className="row mb--20">
                    {allData.slice(0, 5).map((item) => (
                      <div className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h4>Our Core Tecnologies</h4>
                    <div>
                      <Technology />
                    </div>
                  </div>

                  <Accordion>
                    <AccordionItem
                      eventKey="1"
                      header="Why Hire a Magento development company?"
                      body={
                        <>
                          <ul>
                            ► Getting an Adobe Commerce Magento developer who is
                            competent has a few benefits that you may not have
                            considered before. <br />
                            ► For one, you'll save money in the long run because
                            you won't have to keep troubleshooting code or
                            issues that could have been prevented in the first
                            place. <br />
                            ► Additionally, your site's performance and security
                            will be much better off because of the clean code
                            that will be used.
                            <br />
                            ► We want to help you and your business succeed by
                            developing features that prevent issues from
                            happening, rather than being something you have to
                            pay for later on down the road. <br />
                            ► Our team is quick to develop minor features within
                            1-3 days, while larger-scale projects will be
                            assessed on an individual basis. We're never
                            satisfied with the status quo - we're always
                            investigating eCommerce trends and transformations
                            that can bring real value to your business.
                            <br />
                            ► You can prevent issues from happening in the first
                            place by working with Origin software Adobe Commerce
                            developers. We have a plan of scheduled audits and
                            health checkups that will save you from the
                            consequences of security breaches, performance
                            problems, SEO issues, and poor UX.
                            <br />
                            ► With Origin Softwares and true expertise in Adobe
                            Commerce, we can handle any need you have within the
                            agreed timeline. You can expect tangible business
                            value from our work.
                            <br />
                            ► Let's discuss your development needs today.
                            <br />► Here are two aspects of Adobe Commerce that
                            we always have under control: performance and
                            security. <br />
                            ► Performance-wise, our goal is to keep load times
                            under 1.5 seconds under normal conditions.
                            <br />
                            ► Additionally, we aim for stability during load
                            peaks. As for security, we maintain a safe
                            environment and protect the file system. We also
                            make sure that the Adobe Commerce instance is
                            patched promptly. Furthermore, we regularly conduct
                            security audits.
                            <br />
                            ► If you have any Adobe Commerce Magento development
                            needs, we can take care of them for you.
                            <br />
                            ► This includes competencies such as Adobe Commerce
                            consulting, customization, UX and UI design, PWA
                            development, migration and upgrade, and integration
                            <br />
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default Magento;
// 225
