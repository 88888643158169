import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology";
import Industries from "../component/Industries/Industries";
import TestimonialTwo from "../component/testimonial/TestimonialTwo";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  services: [
    { title: "Software Consulting", link: "/Software-Consulting" },
    {
      title: "Custom Software Development",
      link: "/Custom-Software-Development-Company-in-India",
    },
    {
      title: "Software Development Outsourcing",
      link: "/Software-Development-Outsourcing",
    },
    {
      title: "Software Product Development",
      link: "/Software-Product-Development",
    },
    {
      title: "Software Development Automation",
      link: "/Software-Development-Automation",
    },
    {
      title: "Software Maintenance and support",
      link: "/Software-Maintenance-and-Support",
    },
  ],
};

const cardData = [
  { title: "User personalization", link: "#" },
  { title: "Minimalistic design", link: "#" },
  { title: "Data visualization", link: "#" },
  { title: "Push notifications", link: "#" },
  { title: "In-app purchases", link: "#" },
];

const allData = [
  {
    id: 1,
    text: (
      <>
        <h5>Quick time to market</h5>
        <br />
        <p>
          Our development process is designed to get your product to market
          quickly while still maintaining a steady release schedule. We utilize
          an Agile development approach, which, combined with a CI/CD pipeline
          and DevOps methodology, allows us to introduce new product changes
          rapidly.
        </p>
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        <h5>Transparent self-management</h5>
        <p>
          At our company, we pride ourselves on providing our clients with teams
          of developers who can work independently and deliver high-quality
          products. Our Quality Management System is ISO 9001 certified, meaning
          that we have established standards that our developers must adhere to
          for better results.
        </p>
      </>
    ),
  },
  {
    id: 3,
    text: (
      <>
        <h5>Secure cooperation</h5>
        <br />
        <p>
          At the beginning of our cooperation, we sign an NDA to ensure that
          your information stays confidential. As the project goes on, your
          product is protected by our secure IT infrastructure, data security
          practices, and adherence to industry-specific standards.
        </p>
      </>
    ),
  },
  {
    id: 4,
    text: (
      <>
        <h5>Reduce the cost of development</h5>
        <p>
          Additionally, using scalable cloud-native architectures and ready-made
          components helps reduce development costs.
        </p>
      </>
    ),
  },
  {
    id: 5,
    text: (
      <>
        <h5>Context-aware product design</h5>
        <p>
          We know your target audience and can use user behavior data to plan
          the product's UI and functionality.
        </p>
      </>
    ),
  },
  {
    id: 6,
    text: (
      <>
        <h5>Flexible outsourcing options</h5>
        <p>
          {" "}
          We can take over the entire software product development process or
          supplement your in-house team with additional staff if desired.
        </p>
      </>
    ),
  },
  {
    id: 7,
    text: (
      <>
        <h5>Dedicated teams</h5>
        <p>
          {" "}
          And finally, we are dedicated to ensuring that the team assigned to
          your project is not only committed to seeing it succeed but also has a
          vested interest in doing so.
        </p>
      </>
    ),
  },
  {
    id: 8,
    text: (
      <>
        <h5>Risk management</h5>
        <p>
          We estimate different risks (time, budget, performance) so you know
          what to expect.iOSApp.json
        </p>
      </>
    ),
  },
];

const SoftwareProductDevelopment = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>Software Product Development Services | Origin software</title>
          <link rel="canonical" href="./Software-Product-Development" />
          <meta
            name="description"
            content="We offer software product development services,Whether you are in need of custom software or ready to take your product to the next level, Origin's to help you"
          />
          <meta name="keywords" content="software product development" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Software Development"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Software-Consulting"
                                  }
                                >
                                  {" "}
                                  Software Consulting
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Custom-Software-Development-Company-in-India"
                                  }
                                >
                                  Custom Software Development
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Software-Development-Outsourcing"
                                  }
                                >
                                  {" "}
                                  Software Development Outsourcing
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Software-Product-Development"
                                  }
                                >
                                  {" "}
                                  Software Product Development
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Software-Development-Automation"
                                  }
                                >
                                  {" "}
                                  Software Development Automation
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Software-Maintenance-and-Support"
                                  }
                                >
                                  {" "}
                                  Software Maintenance and support
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Software Product Development</h1>
                  <img
                    className="img mb--20"
                    src="../images/Software Product Development Services.png"
                    alt="Software-Product-Development"
                  />
                  <p className="mb--20">
                    Software product development helps create commercial
                    software for business users. Origin Softwares provides
                    outsourced software product development services to design,
                    architect, and implement user-friendly and engaging software
                    products.
                  </p>
                  <h4 className="mb--20">
                    {" "}
                    Essential Features We Create for Market-Leading Software
                    Products
                  </h4>
                  <p className="mb--20">
                    {" "}
                    Some examples of these features could be things like:
                  </p>
                  <div className="container mt-3">
                    <div className="row">
                      {cardData.map((card, index) => (
                        <div key={index} className="col-md-3 ">
                          <div className="Scard margin">
                            <a
                              href={process.env.PUBLIC_URL + card.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {card.title}
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="row">
                    <h4>Origin Softwares Offers</h4>
                    {jsonData.services.map((service, index) => (
                      <div key={index} className="col-xl-6 col-lg-2">
                        <ul className="b">
                          <li>
                            <a href={process.env.PUBLIC_URL + service.link}>
                              {service.title}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                  <div className="row mb--20">
                    {allData.slice(0, 8).map((item) => (
                      <div key={item.id} className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <h4 className="mb--20">Our Projects</h4>
                  <Link
                    to={process.env.PUBLIC_URL + "/Portfolio"}
                    className="axil-btn btn-fill-primary btn-small"
                  >
                    Portfolio
                  </Link>

                  <div className="row">
                    <TestimonialTwo />
                  </div>

                  <Accordion>
                    <h4>Our Software Product Development Services Include</h4>
                    <AccordionItem
                      eventKey="1"
                      header="New Product Development"
                      body={
                        <>
                          <ul>
                            <p>
                              New product development can be a tough process,
                              but a few key things can make it easier:
                              <br /> <br />
                              ► It would help if you took the time to understand
                              the needs and expectations of your target users.
                              What are they looking for in a product? Wha will
                              make them choose your product over others on the
                              market?
                              <br />
                              ► You must research any compliance requirements
                              that may apply to your product. For example, if
                              you're developing a health-related product, you'll
                              need to ensure it meets HIPAA standards.
                              <br />
                              ► You need to plan your product releases and
                              prioritize features.
                              <br />
                              <br />
                              It will help you stay on track and ensure that
                              your product is marketable and compliant.
                            </p>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="2"
                      header="Continuous Product Development"
                      body={
                        <>
                          <ul>
                            <p>
                              ► Product evolution is a continuous process. By
                              implementing behavior analytics, you can spot
                              friction in user journeys and plan UX and UI
                              improvements accordingly.
                              <br />► You can also deliver new functional
                              modules, and features envisaged in the product
                              roadmap, manage technical backlog, develop APIs to
                              expand your product's integration capabilities, or
                              migrate the product to the cloud.
                            </p>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="3"
                      header="SaaS Product Customization"
                      body={
                        <>
                          <ul>
                            <p>
                              {" "}
                              ► SaaS product customization can be a lot of work.
                              We will analyze the individual requirements of our
                              clients, consult the roadmap to see if clients'
                              needs are covered in future releases and create
                              custom features and UI components if they're not.
                            </p>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="4"
                      header="Market-constraint Product Growth"
                      body={
                        <>
                          <ul>
                            <p>
                              ► We also create custom APIs for integration with
                              our client's systems. If you're looking to expand
                              your SaaS product, you have to think about how you
                              will market it to new users and what sort of
                              functionality you will need to add to make it
                              appealing to them.
                            </p>
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                  <br />

                  <h5>
                    Origin Softwares has a Software Product Development Company
                  </h5>

                  <h4>Our Core Technologies</h4>
                  <div className="mb--20">
                    <Technology />
                  </div>

                  <h4>Our Industries</h4>
                  <div className="mb--20">
                    <Industries />
                  </div>

                  <h4>Consult us for Software Product Development </h4>
                  <p>
                    {" "}
                    Origin Softwares can help take your product from the idea
                    stage to fruition. With our software product development
                    services and consulting assistance, you can create a
                    software product that will be marketable and that your
                    target users will love.
                    <br />
                    <Link
                      to={process.env.PUBLIC_URL + "/contact"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      {" "}
                      Contact Us
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default SoftwareProductDevelopment;
