import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";
// import Accordion from 'react-bootstrap/Accordion';


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li className="menu-item-has-children">
                    <Link to={process.env.PUBLIC_URL + "/"}>Home </Link>
                    </li>
                <li className="menu-item-has-children">
                    <Link to={process.env.PUBLIC_URL + "/AboutUs"}>About Us<FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/AboutUs"}>How We Work</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Team"}>Management Team</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/VisionAndMission"}>Vision &amp; Mission</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Testimonials"}>Testimonials</Link></li>
                    </ul>

                </li>
                <li className="menu-item-has-children">
                    <Link to={process.env.PUBLIC_URL + "#"}>Services <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/Software-Development-Company-in-India"}>Software Development</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Web-Development-Company-in-Hyderabad"}>Web Development</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/E-Commerce-Services"}>Ecommerce Services</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Mobile-App-Development-Services"}>Mobile Apps</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Branding-Agency-in-Hyderabad"}>Corporate Branding</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Digital-Marketing-Agency-in-Hyderabad"}>Digital Marketing</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/IT-Staffing-services"}>IT Staffing</Link></li>
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">Technologies<FaAngleDown /> </Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/Java-Development-Company"}>Java</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Node-Js-Development-Services"}>Node.Js</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Hire-Python-Developers"}>Python</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/IOS-App-Development-Services"}>iOS</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Andriod-App-Development-Services"}>Andriod</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Angular-Development-Services"}>Angular</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Flutter-App-Development-Company"}>Flutter</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Dot-Net-Application-development"}>.Net</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Php-Development-Services"}>PHP</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/React-js-development-services"}>React</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Magento-Development-Services"}>Magento</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/WordPress-Development-Company-in-India"}>Word Press</Link></li>
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">Industries<FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/Fashion"}>Fashion</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Footwear"}>Footwear</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/TravelAndHospitality"}>Travel &amp; Hospitality</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Education"}>Education</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Healthcare"}>Health Care</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/OilAndGas"}>Oil &amp; Gas</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Banking"}>Banking</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Manufacturing"}>Manufacturing</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/FinancialServices"}>Financial Services</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Automobile"}>Automobile</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/FMCG"}>FMCG</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Restaurent"}>Restaurent</Link></li>
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to={process.env.PUBLIC_URL + "/Portfolio"}>Portfolio</Link>
                </li>
                <li className="menu-item-has-children">
                    <Link to={process.env.PUBLIC_URL + "/CaseStudy"}>Case Studies</Link>
                </li>
                <li><Link to={process.env.PUBLIC_URL + "/Contact"}>Contact Us</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;