import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";
import SideStickMenu from "./SideStickMenu.js";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const allData = [
  {
    id: 1,
    text: "A flutter is a powerful tool that allows developers to build responsive and attractive apps for Android and iOS platforms.",
  },
  {
    id: 2,
    text: "This cross-platform technology provides endless possibilities for app development.",
  },
  {
    id: 3,
    text: "The primary language used with Flutter is DART, making it easy for flutter developers. It is to create feature-rich, high-quality apps quickly and efficiently.",
  },
  {
    id: 4,
    text: "Origin Softwares offers advanced Flutter app development services, providing clients with reliable and scalable interfaces that are easy to use.",
  },
  {
    id: 5,
    text: "Our Flutter app development company can help you create amazing multi-platform native apps.",
  },
  {
    id: 6,
    text: "And build apps with beautiful animations, excellent native performance, and a graphic user interface.",
  },
  {
    id: 7,
    text: "We offer comprehensive services, from initial consultation to post-launch maintenance and support.",
  },
  {
    id: 8,
    text: "With more than a decade of experience, we can breathe new life into your stagnant projects.",
  },
  {
    id: 9,
    text: "Contact us today to start with the best Flutter app development company in India.",
  },
];

const Flutter = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>Flutter App Development Company | Origin software</title>
          <link rel="canonical" href="./Flutter-App-Development-Company" />
          <meta
            name="description"
            content="Flutter App Development Company Origin software architect unites talented app developers who are eager to make your digital idea a reality."
          />
          <meta name="keywords" content="flutter app development company" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <SideStickMenu />
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Flutter App Development</h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/flutter.png"
                    alt="Flutter-App-Development-Company"
                  />

                  <div className="row mb--20">
                    {allData.slice(0, 9).map((item) => (
                      <div className="Box3 col-xl-4 col-lg-3">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h4 className="mb--20">Services we offer include:</h4>
                    <img
                      className="thumbnail"
                      src="../images/InfoGraphics/flutter.png"
                      alt="Thumbnail"
                    />
                  </div>
                  <br />

                  <div className="row">
                    <div>
                      <Technology />
                    </div>
                  </div>

                  <Accordion>
                    <h4 className="color">Flutter FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="Why Choose Us?"
                      body={
                        <>
                          <ul>
                            ► At our company, we pride ourselves on creating
                            perfect flutter applications. We have a great
                            product development team of flutter that will keep
                            your project running smoothly from start to finish.
                            Our developers are certified experts with years of
                            experience in the field.<br></br>
                            <br></br>► You'll have complete transparency and
                            command of your project. We'll protect your
                            intellectual property and keep your data secure.
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default Flutter;
// 177
