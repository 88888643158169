import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';


const AboutFour = () => {
    return (
        <div className="section section-padding case-study-featured-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                            <img src={process.env.PUBLIC_URL + "/images/about/AboutUs.png"} alt="About Us" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                            <div className="section-heading heading-left">
                                <h2 className="title">Who we are</h2>
                                <p>We have a highly competent team of Developers Integrators and Testers with expertise in various Domains and Platforms. WE have mastered the art of delivering software with highly competent team through Safe and Successful delivery process. With knowledge and passion we are dedicated to help you become a leader. You can benefit from global expertise and post delivery support. Our experts have Unparalleled width and depth of knowledge of how technology can match customer and end user needs.</p>
                                <Link to="/Contact" className="axil-btn btn-fill-primary btn-large">Contact Us</Link>
                            </div>
                            <div className="case-study-counterup">
                                <div className="single-counterup">
                                    <h2 className="count-number">
                                        <TrackVisibility once>
                                            {({ isVisible }) => (
                                                <span className="number count">
                                                    {isVisible ? <CountUp end="12" duration={1} /> : null}
                                                </span>
                                            )}
                                        </TrackVisibility>
                                        <span className="symbol">+</span>
                                    </h2>
                                    <span className="counter-title">Years on the market</span>
                                </div>
                                <div className="single-counterup">
                                    <h2 className="count-number">
                                        <TrackVisibility once>
                                            {({ isVisible }) => (
                                                <span className="number count">
                                                    {isVisible ? <CountUp end="284" duration={1} /> : null}
                                                </span>
                                            )}
                                        </TrackVisibility>
                                        <span className="symbol">+</span>
                                    </h2>
                                    <span className="counter-title">Projects delivered so far</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutFour;