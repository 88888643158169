import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology";
import Industries from "../component/Industries/Industries.js";
import TestimonialTwo from "../component/testimonial/TestimonialTwo";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  services: [
    { title: "Recruitment", link: "/Recruitment-Services" },
    { title: "Resources", link: "/Staffing-Resources" },
    { title: "Contract Staffing", link: "/Contract-Staffing-Services" },
    { title: "Direct Hire", link: "/Direct-Hire" },
    { title: "Executive Search", link: "/Executive-Search" },
    { title: "Offshore Staffing", link: "/Offshore-Staffing" },
  ],
};

const allData = [
  {
    id: 1,
    text: "At Origin Softwares, we provide staffing solutions tailored to each client's needs.",
  },
  {
    id: 2,
    text: "Whether our client is looking for temporary staffing, permanent staff, employees on a contractual basis, or candidates for a contract-to-hire arrangement, either long-term or short-term, we are here to help.",
  },
  {
    id: 3,
    text: "We also offer recruitment process outsourcing (RPO) services to help our clients find the top talent for their business objectives.",
  },
  {
    id: 4,
    text: "Origin Softwares is a leading IT staffing agency that offers customized solutions.",
  },
  {
    id: 5,
    text: "We take the time to understand our client's specific skills and match them with the perfect candidate.",
  },
  {
    id: 6,
    text: "With our Recruitment Process, we can achieve superior recruiting results in a shorter amount of time.",
  },
];

const ITStaffing = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>
            IT Staffing Services - Employers & Candidates | Origin Software
          </title>
          <link
            rel="canonical"
            href="https://www.originsoftwares.com/IT-Staffing-services"
          />
          <meta
            name="description"
            content="Our IT Staffing services division helps companies to find the best talent from around the world. contact origin software today"
          />
          <meta name="keywords" content="IT STAFFING SERVICES" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="IT Staffing"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Recruitment-Services"
                                  }
                                >
                                  {" "}
                                  Recruitment
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Staffing-Resources"
                                  }
                                >
                                  Resourses
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Contract-Staffing-Services"
                                  }
                                >
                                  {" "}
                                  Contract Staffing
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={process.env.PUBLIC_URL + "/Direct-Hire"}
                                >
                                  {" "}
                                  Direct Hire
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL + "/Executive-Search"
                                  }
                                >
                                  {" "}
                                  Executive Search
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Offshore-Staffing"
                                  }
                                >
                                  {" "}
                                  Offshore Staffing
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">IT Staffing Services</h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/IT Staffing.png"
                    alt="IT-Staffing-services"
                  />
                  <p className="mb--20">
                    At Origin Softwares, we pride ourselves on being a leading
                    global IT staffing service provider. We use technology to
                    identify competent full-time IT professionals or software
                    developers for various business organizations. Our staffing
                    partners get sophisticated placement and retention records
                    analysis helps us identify the most highly skilled
                    candidates for each job.
                    <br />
                    <br />
                    So if you're looking for an IT professional to join your
                    in-house team, look no further than origin software! We'll
                    have you cost-effective and demand talent coming up and
                    running in no time.
                  </p>

                  <div className="row mb--20">
                    <h4>Our Staffing Solutions</h4>
                    {allData.slice(0, 3).map((item) => (
                      <div className="Box3 col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Our Industries</h3>
                    <div>
                      <Industries />
                    </div>
                  </div>

                  <div className="row mb--20">
                    <h4 className="mb--20">Our Recruitment Process</h4>
                    {allData.slice(3, 6).map((item) => (
                      <div className="Box1 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <h4>Center of Excellence For Recruiting</h4>
                  <h4>Our Esteemed Clients</h4>
                  <div className="row">
                    <TestimonialTwo />
                  </div>

                  <h4>Technology We Cover</h4>
                  <div className="mb--20">
                    <Technology />
                  </div>

                  <div className="row">
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className="b">
                          <li>
                            <a href={process.env.PUBLIC_URL + services.link}>
                              {services.title}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                  <h4 className="mb--20">
                    Hire the Right Candidate at Right Time
                  </h4>
                  <p>
                    At Origin Softwares, being among the best staffing
                    companies, we focus on helping you attain your dream job. We
                    understand that a wide range of experiences and perspectives
                    are necessary for our success as a company.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default ITStaffing;
// 340
