import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology";
import Industries from "../component/Industries/Industries.js";
import GoogleTagManager from "./GTM";
import SideStickButton from "./SideStickButton.js";
import SideStickMenu from "./SideStickMenu.js";

const allData = [
  {
    id: 1,
    text: (
      <>
        <h6>Widespread use and popularity:</h6>
        <p>
          A large community of developers is passionate about the evolution of
          Angular and helping each other out with problems they may have.
        </p>
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        <h6>Clean and understandable code:</h6>
        <p>
          Angular provides developers with tools and design and development
          patterns that result in well-structured and easy-to-understand code
          (that is also easy to test!).
        </p>
      </>
    ),
  },
  {
    id: 3,
    text: "Angular is popular among developers because it provides plenty of tools to help them build apps quickly, and the learning curve is relatively gentle. ",
  },
  {
    id: 4,
    text: "With Angular, the development team doesn't need to learn a new language; the framework is built on top of existing JavaScript code.",
  },
  {
    id: 5,
    text: "It means that angular app development is accelerated, as developers can take advantage of features like static typing, interfaces, classes, and many others.",
  },
  {
    id: 6,
    text: "Building a team of Angular developers is like creating a jigsaw puzzle. If you want to do it on your own, it's not easy.",
  },
  {
    id: 7,
    text: "It's far better to turn to an expert Angular development company that does the work for you. They vet every single Angular developer before making them available for hire.",
  },
  {
    id: 8,
    text: "That way, you're assured of getting access to the best people in the field with the skill and experience to make your angular projects successful.",
  },
];
const Angular = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>Angular Development services | Origin software</title>
          <link rel="canonical" href="./Angular-Development-Services" />
          <meta
            name="description"
            content="Angular Development services--Origin is Custom AngularJS Development Company. We are expert in developing custom web application using AngularJS."
          />
          <meta name="keywords" content="angular development services" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <SideStickMenu />
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Angular Development services</h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/Angular.png"
                    alt="Angular-Development-Services"
                  />
                  <p>
                    Angular provides the tools necessary to build quality web
                    applications quickly and effectively. Angular's open-source
                    framework focuses on developer productivity, speed, and
                    scalability.
                    <br />
                    <br />
                    Google introduced Angular in 2016, and since then, it has
                    become a significant player in web application development.
                    <br />
                    <br />
                    Our team helps customers utilize Angular to its full
                    potential to create stunning web and single-page
                    applications that offer excellent performance, a good user
                    experience, and a user-friendly web platform. Our developers
                    also include data binding in the process to make it simple.
                  </p>

                  <div className="row mb--20">
                    <h4>Angular web app development benefits include:</h4>
                    {allData.slice(0, 2).map((item) => (
                      <div className="Box col-xl-6 col-lg-6">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row mb--20">
                    <h4 className="mb--20">Multi-platform availability:</h4>
                    <p>
                      You can use Angular to build progressive web, mobile and
                      desktop apps.
                    </p>
                    {allData.slice(2, 5).map((item) => (
                      <div className="Box1 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row mb--20">
                    <h4 className="mb--20">
                      Hire the Angular development team{" "}
                    </h4>
                    {allData.slice(5, 8).map((item) => (
                      <div className="Box3 col-xl-4 col-lg-3">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div>
                    <Technology />
                  </div>

                  <h4>Our Development Process</h4>
                  {/* <p>Add Development Architechture Here</p> */}

                  <h4>Our Development Projects and Case Studies</h4>
                  <Link
                    to={process.env.PUBLIC_URL + "/Portfolio"}
                    className="axil-btn btn-fill-primary btn-small"
                  >
                    Portfolio
                  </Link>

                  <div className="row">
                    <h4 className="mb--20">Our Industries</h4>
                    <div>
                      <Industries />
                    </div>
                  </div>

                  <p>
                    Contact us for angular software services and get support
                    &amp; maintenance solutions with it.
                    <br />
                    <Link
                      to={process.env.PUBLIC_URL + "/contact"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      Contact Us
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default Angular;
// 185
