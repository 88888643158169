import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './assets/scss/header/nav1.css';

import './App.css';

// Blog Import
import BlogGridView from './pages/BlogGrid';
import BlogCategory from './pages/Category';
import BlogArchive from './pages/Archive';
import BlogDetails from './pages/BlogDetails';
// import BlogGrid from './pages/BlogGrid'

// Service 
//import Service from './pages/Service';
import ServiceOne from './pages/ServiceOne';
import ServiceTwo from './pages/ServiceTwo';
import ServiceDetails from './pages/ServiceDetails';

// Project 
import ProjectGridOne from './pages/ProjectGridOne';
import ProjectGridTwo from './pages/ProjectGridTwo';
import ProjectGridThree from './pages/ProjectGridThree';
import ProjectGridFive from './pages/ProjectGridFive';
import ProjectGridFour from './pages/ProjectGridFour';
import ProjectDetails from './pages/ProjectDetails';

// Pages 
// import Splash from './pages/Splash';
import AboutUs from './pages/AboutUs';
import OurOffice from './pages/OurOffice';
import OurClients from './pages/OurClients';
import Team from './pages/Team';
import TeamDetails from './pages/TeamDetails';
import CaseStudy from './pages/CaseStudy';
import CaseDetails from './pages/CaseStudyDetails';
import Testimonials from './pages/Testimonials';
import PricingTable from './pages/PricingTable';
import Typography from './pages/Typography';
import Contact from './pages/Contact';
import ErrorPage from './pages/404';
import ComingSoon from './pages/ComingSoon';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import ApplyNow from './pages/ApplyNow';
import Career from './pages/Career';
import Refundandcancellation from './pages/Refundandcancellation';


// origin Pages
import Home from './pages/Home'
import HowWeWork from './pages/HowWeWork';
import ManagementTeam from './pages/ManagementTeam';
import VisionAndMission from './pages/VisionAndMission';
import Testimonials1 from './pages/Testimonials1';
import SoftwareDevelopment from './pages/Software-Development-Company-in-India';
import WebsiteDesign from './pages/Web-Designing-Company-in-Hyderabad';
import WebsiteDevelopment from './pages/Web-Development-Company-in-Hyderabad';
import EcommerceDevelopment from './pages/E-Commerce-Services';
import MobileApps from './pages/Mobile-App-Development-Services';
import CorporateBranding from './pages/Branding-Agency-in-Hyderabad';
import DigitalMarketing from './pages/Digital-Marketing-Agency-in-Hyderabad';
import ITStaffing from './pages/IT-Staffing-services';
import Fashion from './pages/Fashion';
import Footwear from './pages/Footwear';
import TravelAndHospitality from './pages/TravelAndHospitality';
import Education from './pages/Education';
import Healthcare from './pages/Healthcare';
import ECommerce from './pages/ECommerce';
import Banking from './pages/Banking';
import Manufacturing from './pages/Manufacturing';
import Corporate from './pages/Corporate';
import Automobile from './pages/Automobile';
import FMCG from './pages/FMCG';
import NodeJs from './pages/Node-Js-Development-Services';
import Java from './pages/Java-Development-Company';
import Python from './pages/Hire-Python-Developers';
import Angular from './pages/Angular-Development-Services';
import Andriod from './pages/Andriod';
import IOS from './pages/IOS';
import Flutter from './pages/Flutter-App-Development-Company';
import DotNet from './pages/Dot-Net-Application-development';
import Php from './pages/Php-Development-Services';
import React1 from './pages/React-js-development-services';
import WordPress from './pages/WordPress-Development-Company-in-India';
import Restaurent from './pages/Restaurent';
import NGO from './pages/NGO';
import Construction from './pages/Construction';

// Sub-Menu Pages
import SoftwareConsulting from './pages/Software-Consulting';
import CustomSoftwareDevelopment from './pages/Custom-Software-Development-Company-in-India';
import SoftwareDevelopmentOutsourcing from './pages/Software-Development-Outsourcing';
import SoftwareProductDevelopment from './pages/Software-Product-Development';
import SoftwareDevelopmentAutomation from './pages/Software-Development-Automation';
import SoftwareMaintenanceAndSupport from './pages/Software-Maintenance-and-Support';
import UIDesign from './pages/UI-Design-Services';
import UXDesign from './pages/UX-Design-Services';
import ResponsiveWebDesign from './pages/Responsive-Web-Design-Services';
import ResponsiveWebAppDesign from './pages/Responsive-Web-Application';
import EcommerceWebsiteDesign from './pages/Ecommerce-Website-Development-Services';
import WebsiteRedesign from './pages/Website-Redesign-Services';
import AndriodApps from './pages/Andriod-App-Development-Services';
import IOSApps from './pages/IOS-App-Development-Services';
import ReactNativeMobileApp from './pages/React-Native-App-Development-Company';
import XamarinApps from './pages/Xamarin-App-Development';
import HybridApps from './pages/Hybrid-App-Development';
import MobileAppDesign from './pages/Mobile-App-Design-Services';
import MobileEcommerceApps from './pages/Mobile-Ecommerce-App-Development';
import LogoDesign from './pages/Logo-Design-Company-in-India';
import Brochure from './pages/Brochure-Design-India';
import Presentation from './pages/Presentation-Design-India';
import InformativeVideo from './pages/InformativeVideo';
import PromotionalMaterial from './pages/Promotional-Products';
import SEO1 from './pages/SEO-Services-in-Hyderabad';
import SMM from './pages/Social-Media-Marketing-agency-in-India';
import PPC from './pages/PPC-Services-India';
import Recruitment from './pages/Recruitment-Services';
import Resources from './pages/Staffing-Resources';
import ContractStaffing from './pages/Contract-Staffing-Services';
import DirectHire from './pages/Direct-Hire';
import ExecutiveSearch from './pages/Executive-Search ';
import OffshoreStaffing from './pages/Offshore-Staffing ';
import Magento from './pages/Magento-Development-Services';
import Portfolio from './pages/Portfolio';
import GetAQuote from './pages/GetAQuote';




// Css Import
import './assets/scss/app.scss';
import Service from './pages/Service';


//import BlogGridOne from './component/blog/BlogGridOne';

const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<Home />}/>

				{/* Blogs */}
				<Route path={process.env.PUBLIC_URL + "/blog-grid/"} element={<BlogGridView />}/>
				<Route path={process.env.PUBLIC_URL + "/category/:slug"} element={<BlogCategory />}/>
				<Route path={process.env.PUBLIC_URL + "/archive/:slug"} element={<BlogArchive />}/>
				<Route path={process.env.PUBLIC_URL + "/blog-details/:id"} element={<BlogDetails />}/>

				{/* Service */}
				<Route path={process.env.PUBLIC_URL + "/service-one/"} element={<ServiceOne />}/>
				<Route path={process.env.PUBLIC_URL + "/service-two/"} element={<ServiceTwo />}/>
				<Route path={process.env.PUBLIC_URL + "/service-details/:slug"} element={<ServiceDetails />}/>

				{/* Project  */}
				<Route path={process.env.PUBLIC_URL + "/project-grid-one"} element={<ProjectGridOne />}/>
				<Route path={process.env.PUBLIC_URL + "/project-grid-two"} element={<ProjectGridTwo />}/>
				<Route path={process.env.PUBLIC_URL + "/project-grid-three"} element={<ProjectGridThree />}/>
				<Route path={process.env.PUBLIC_URL + "/project-width-one"} element={<ProjectGridFour />}/>
				<Route path={process.env.PUBLIC_URL + "/project-width-two"} element={<ProjectGridFive />}/>
				<Route path={process.env.PUBLIC_URL + "/project-details/:slug"} element={<ProjectDetails />}/>

				{/* Origin Pages */}
				<Route path={process.env.PUBLIC_URL + "/HowWeWork"} element={<HowWeWork />}/>
				<Route path={process.env.PUBLIC_URL + "/ManagementTeam"} element={<ManagementTeam/>}/>
				<Route path={process.env.PUBLIC_URL + "/VisionAndMission"} element={<VisionAndMission/>}/>
				<Route path={process.env.PUBLIC_URL + "/Testimonials1"} element ={<Testimonials1/>}/>
				<Route path={process.env.PUBLIC_URL + "/Software-Development-Company-in-India"} element ={<SoftwareDevelopment/>}/>
				<Route path={process.env.PUBLIC_URL + "/Web-Development-Company-in-Hyderabad"} element ={<WebsiteDevelopment/>}/>
				<Route path={process.env.PUBLIC_URL + "/Web-Designing-Company-in-Hyderabad"} element = {<WebsiteDesign/>}/>
				<Route path={process.env.PUBLIC_URL + "/E-Commerce-Services"} element = {<EcommerceDevelopment/>}/>
				<Route path={process.env.PUBLIC_URL + "/Mobile-App-Development-Services"} element ={<MobileApps/>}/>
				<Route path={process.env.PUBLIC_URL + "/Branding-Agency-in-Hyderabad"} element ={<CorporateBranding/>}/>
				<Route path={process.env.PUBLIC_URL + "/Digital-Marketing-Agency-in-Hyderabad"} element ={<DigitalMarketing/>}/>
				<Route path={process.env.PUBLIC_URL + "/IT-Staffing-services"} element ={<ITStaffing/>}/>
				<Route path={process.env.PUBLIC_URL + "/Fashion"} element ={<Fashion/>}/>
				<Route path={process.env.PUBLIC_URL + "/Footwear"} element ={<Footwear/>}/>
				<Route path={process.env.PUBLIC_URL + "/TravelAndHospitality"} element ={<TravelAndHospitality/>}/>
				<Route path={process.env.PUBLIC_URL + "/Education"} element ={<Education/>}/>
				<Route path={process.env.PUBLIC_URL + "/Healthcare"} element ={<Healthcare/>}/>
				<Route path={process.env.PUBLIC_URL + "/ECommerce"} element ={<ECommerce/>}/>
				<Route path={process.env.PUBLIC_URL + "/Banking"} element ={<Banking/>}/>
				<Route path={process.env.PUBLIC_URL + "/Manufacturing"} element ={<Manufacturing/>}/>
				<Route path={process.env.PUBLIC_URL + "/Corporate"} element ={<Corporate/>}/>
				<Route path={process.env.PUBLIC_URL + "/Automobile"} element ={<Automobile/>}/>
				<Route path={process.env.PUBLIC_URL + "/FMCG"} element ={<FMCG/>}/>
				<Route path={process.env.PUBLIC_URL + "/Java-Development-Company"} element ={<Java/>}/>
				<Route path={process.env.PUBLIC_URL + "/Node-Js-Development-Services"} element ={<NodeJs/>}/>
				<Route path={process.env.PUBLIC_URL + "/Hire-Python-Developers"} element ={<Python/>}/>
				<Route path={process.env.PUBLIC_URL + "/Andriod"} element ={<Andriod/>}/>
				<Route path={process.env.PUBLIC_URL + "/Angular-Development-Services"} element ={<Angular/>}/>
				<Route path={process.env.PUBLIC_URL + "/Flutter-App-Development-Company"} element ={<Flutter/>}/>
				<Route path={process.env.PUBLIC_URL + "/Dot-Net-Application-development"} element ={<DotNet/>}/>
				<Route path={process.env.PUBLIC_URL + "/Php-Development-Services"} element ={<Php/>}/>
				<Route path={process.env.PUBLIC_URL + "/React-js-development-services"} element ={<React1/>}/>
				<Route path={process.env.PUBLIC_URL + "/WordPress-Development-Company-in-India"} element ={<WordPress/>}/>
				<Route path={process.env.PUBLIC_URL + "/Portfolio"} element ={<Portfolio/>}/>
				<Route path={process.env.PUBLIC_URL + "/Restaurent"} element ={<Restaurent/>}/>
				<Route path={process.env.PUBLIC_URL + "/NGO"} element ={<NGO/>}/>
				<Route path={process.env.PUBLIC_URL + "/Construction"} element ={<Construction/>}/>

				{/* Sub-menu Pages */}
				<Route path={process.env.PUBLIC_URL + "/Software-Consulting"} element={<SoftwareConsulting />}/>
				<Route path={process.env.PUBLIC_URL + "/Custom-Software-Development-Company-in-India"} element={<CustomSoftwareDevelopment />}/>
				<Route path={process.env.PUBLIC_URL + "/Software-Development-Outsourcing"} element={<SoftwareDevelopmentOutsourcing />}/>
				<Route path={process.env.PUBLIC_URL + "/Software-Product-Development"} element ={<SoftwareProductDevelopment/>}/>
				<Route path={process.env.PUBLIC_URL + "/Software-Development-Automation"} element ={<SoftwareDevelopmentAutomation/>}/>
				<Route path={process.env.PUBLIC_URL + "/Software-Maintenance-and-Support"} element ={<SoftwareMaintenanceAndSupport/>}/>
				<Route path={process.env.PUBLIC_URL + "/UI-Design-Services"} element ={<UIDesign/>}/>
				<Route path={process.env.PUBLIC_URL + "/UX-Design-Services"} element ={<UXDesign/>}/>
				<Route path={process.env.PUBLIC_URL + "/Responsive-Web-Design-Services"} element ={<ResponsiveWebDesign/>}/>
				<Route path={process.env.PUBLIC_URL + "/Responsive-Web-Application"} element ={<ResponsiveWebAppDesign/>}/>
				<Route path={process.env.PUBLIC_URL + "/Ecommerce-Website-Development-Services"} element ={<EcommerceWebsiteDesign/>}/>
				<Route path={process.env.PUBLIC_URL + "/Website-Redesign-Services"} element ={<WebsiteRedesign/>}/>
				<Route path={process.env.PUBLIC_URL + "/Andriod-App-Development-Services"} element ={<AndriodApps/>}/>
				<Route path={process.env.PUBLIC_URL + "/IOS-App-Development-Services"} element ={<IOSApps/>}/>
				<Route path={process.env.PUBLIC_URL + "/IOS"} element ={<IOS/>}/>
				<Route path={process.env.PUBLIC_URL + "/React-Native-App-Development-Company"} element ={<ReactNativeMobileApp/>}/>
				<Route path={process.env.PUBLIC_URL + "/Xamarin-App-Development"} element ={<XamarinApps/>}/>
				<Route path={process.env.PUBLIC_URL + "/Hybrid-App-Development"} element={<HybridApps/>}/>
				<Route path={process.env.PUBLIC_URL + "/Mobile-App-Design-Services"} element ={<MobileAppDesign/>}/>
				<Route path={process.env.PUBLIC_URL + "/Mobile-Ecommerce-App-Development"} element ={<MobileEcommerceApps/>}/>
				<Route path={process.env.PUBLIC_URL + "/Logo-Design-Company-in-India"} element ={<LogoDesign/>}/>
				<Route path={process.env.PUBLIC_URL + "/Brochure-Design-India"} element ={<Brochure/>}/>
				<Route path={process.env.PUBLIC_URL + "/Presentation-Design-India"} element ={<Presentation/>}/>
				<Route path={process.env.PUBLIC_URL + "/InformativeVideo"} element ={<InformativeVideo/>}/>
				<Route path={process.env.PUBLIC_URL + "/Promotional-Products"} element ={<PromotionalMaterial/>}/>
				<Route path={process.env.PUBLIC_URL + "/SEO-Services-in-Hyderabad"} element ={<SEO1/>}/>
				<Route path={process.env.PUBLIC_URL + "/Social-Media-Marketing-agency-in-India"} element ={<SMM/>}/>
				<Route path={process.env.PUBLIC_URL + "/PPC-Services-India"} element ={<PPC/>}/>
				<Route path={process.env.PUBLIC_URL + "/Recruitment-Services"} element ={<Recruitment/>}/>
				<Route path={process.env.PUBLIC_URL + "/Staffing-Resources"} element ={<Resources/>}/>
				<Route path={process.env.PUBLIC_URL + "/Contract-Staffing-Services"} element ={<ContractStaffing/>}/>
				<Route path={process.env.PUBLIC_URL + "/Direct-Hire"} element ={<DirectHire/>}/>
				<Route path={process.env.PUBLIC_URL + "/Executive-Search"} element ={<ExecutiveSearch/>}/>
				<Route path={process.env.PUBLIC_URL + "/Offshore-Staffing"} element ={<OffshoreStaffing/>}/>
				<Route path={process.env.PUBLIC_URL + "/Magento-Development-Services"} element ={<Magento/>}/>
				

				{/* Pages */}
				<Route path={process.env.PUBLIC_URL + "/AboutUs"} element={<AboutUs />}/>
				<Route path={process.env.PUBLIC_URL + "/our-office"} element={<OurOffice />}/>
				<Route path={process.env.PUBLIC_URL + "/our-clients"} element={<OurClients />}/>
				<Route path={process.env.PUBLIC_URL + "/team"} element={<Team />}/>
				<Route path={process.env.PUBLIC_URL + "/team-details/:slug"} element={<TeamDetails />}/>
				<Route path={process.env.PUBLIC_URL + "/case-study"} element={<CaseStudy />}/>
				<Route path={process.env.PUBLIC_URL + "/case-details/:slug"} element={<CaseDetails />}/>
				<Route path={process.env.PUBLIC_URL + "/testimonials"} element={<Testimonials />}/>
				<Route path={process.env.PUBLIC_URL + "/pricing-table"} element={<PricingTable />}/>
				<Route path={process.env.PUBLIC_URL + "/typography"} element={<Typography />}/>
				<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />}/>
				<Route path={process.env.PUBLIC_URL + "/404"} element={<ErrorPage />}/>
				<Route path={process.env.PUBLIC_URL + "/coming-soon"} element={<ComingSoon />}/>
				<Route path={process.env.PUBLIC_URL + "/PrivacyPolicy"} element={<PrivacyPolicy />}/>
				<Route path={process.env.PUBLIC_URL + "/TermsOfUse"} element={<TermsOfUse />}/>
				<Route path={process.env.PUBLIC_URL + "/CaseStudy"} element={<CaseStudy />}/>
				<Route path={process.env.PUBLIC_URL + "/CaseStudyDetails"} element={<CaseStudy />}/>
				<Route path={process.env.PUBLIC_URL + "/Service"} element ={<Service/>}/>
				<Route path={process.env.PUBLIC_URL + "/ApplyNow"} element ={<ApplyNow/>}/>
				<Route path={process.env.PUBLIC_URL + "/Career"} element ={<Career/>}/>
				<Route path={process.env.PUBLIC_URL + "/GetAQuote"} element ={<GetAQuote/>}/>
				<Route path={process.env.PUBLIC_URL + "/Refundandcancellation"} element ={<Refundandcancellation/>}/>

			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
