import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";
import SideStickMenu from "./SideStickMenu.js";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const allData = [
  {
    id: 1,
    text: "It's packed with features and plugins that make managing websites quick and easy. Our WordPress developers create hybrid solid websites perfect for small businesses and start-ups.",
  },
  {
    id: 2,
    text: "We excel in helping you increase your brand's value, generate income, and attract clients. Our WordPress development team will create individualized, highly optimized websites that will be great for your company.",
  },
  {
    id: 3,
    text: "Origin Softwares specializes in WordPress development services that are tailored to your company's specific needs. Our team of designers and developers is passionate about creating WordPress websites and themes.",
  },
  {
    id: 4,
    text: "Our experts have the skills and experience necessary to provide high-quality services that meet your expectations.",
  },
  {
    id: 5,
    text: "Contact us today if you're looking for a WordPress website development company that can create a custom website for your business.",
  },
];

const WordPress = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>
            WordPress Development company in India | Origin software
          </title>
          <link
            rel="canonical"
            href="./WordPress-Development-Company-in-India"
          />
          <meta
            name="description"
            content="If you are looking for a professional & reliable WordPress development company in India, Origin software can help with your custom WordPress development."
          />
          <meta
            name="keywords"
            content="wordpress development company in india"
          />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <SideStickMenu />
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">WordPress Development</h1>
                  <img
                    className="img mb--20"
                    src="../images/Wordpress.png"
                    alt=""
                  ></img>
                  <p className="mb--20">
                    Origin Softwares is a leading WordPress development company
                    in India that provides affordable WordPress web development
                    services that are reliable.
                    <br />
                    WordPress is the way to go if you're looking for a great
                    content management system that will improve your development
                    experience.
                    <br />
                    <Link
                      to={process.env.PUBLIC_URL + "/contact"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      Contact Us
                    </Link>
                  </p>

                  <div className="row ">
                    {allData.slice(0, 2).map((item) => (
                      <div className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <h4>Our WordPress development services are as follows</h4>
                  <p>
                    <ul className="b">
                      <li>WordPress plugin creation</li>
                      <li>WordPress services withno header (Headless CMS)</li>
                      <li>Creation of WordPressthemes</li>
                      <li>Theme customization for WordPress</li>
                    </ul>
                  </p>

                  <div className="row">
                    {allData.slice(2, 5).map((item) => (
                      <div className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <br />

                  <h4 className="mb--20">
                    {" "}
                    Our WordPress website development services include
                  </h4>
                  <img
                    className="thumbnail"
                    src="../images/InfoGraphics/wordpress1.png"
                    alt="Thumbnail"
                  />

                  <div className="row">
                    <h3 className="mb--20">Technologies we Offer</h3>
                    <div>
                      <Technology />
                    </div>
                  </div>

                  <h4 className="mb--20">Our WordPress development projects</h4>
                  <Link
                    to={process.env.PUBLIC_URL + "/Portfolio"}
                    className="axil-btn btn-fill-primary btn-small"
                  >
                    Portfolio
                  </Link>

                  <h4>Our WordPress website development process</h4>
                  {/* <h6>Add Process Infographic Here</h6> */}

                  <Accordion>
                    <h4 className="color">WordPress FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="So why should you choose us for all your WordPress needs?"
                      body={
                        <>
                          <ul>
                            ► We're focused on providing cutting-edge technology
                            and customization to all our clients. if you're
                            looking for a team to help you create a truly unique
                            WordPress site, look no further than Origin
                            Softwares!
                            <br />
                            ► Origin Softwares is the best choice for a
                            WordPress development partner. We offer experienced
                            and expert WordPress developers who can provide
                            smooth website functionality and a user-friendly
                            interface.
                            <br />► Our team is dedicated to empowering and
                            strengthening your company. In addition to building
                            websites, our qualified WordPress developers also
                            optimize websites for search engines.
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="2"
                      header="Why choose Origin Softwares?"
                      body={
                        <>
                          <ul>
                            ► We provide smooth website functionality
                            <br />
                            ► Hire WordPress developers with experience and
                            expertise
                            <br />
                            ► Custom WordPress Theme Development
                            <br />
                            ► SEO Friendly Structure
                            <br />
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default WordPress;
// 250
