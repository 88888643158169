import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology";
import Industries from "../component/Industries/Industries";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  services: [
    { title: "Software Consulting", link: "/Software-Consulting" },
    {
      title: "Custom Software Development",
      link: "/Custom-Software-Development-Company-in-India",
    },
    {
      title: "Software Development Outsourcing",
      link: "/Software-Development-Outsourcing",
    },
    {
      title: "Software Product Development",
      link: "/Software-Product-Development",
    },
    {
      title: "Software Development Automation",
      link: "/Software-Development-Automation",
    },
    {
      title: "Software Maintenance and support",
      link: "/Software-Maintenance-and-Support",
    },
  ],
};

const allData = [
  {
    id: 1,
    text: "Corrective maintenance (maintenance process) is fixing problems discovered by users or identified during monitoring.",
  },
  {
    id: 2,
    text: "Adaptive maintenance is keeping software up-to-date by tuning it in line with the changing business needs. Software development process and perfective maintenance continuously improve software's usability, performance, and reliability.",
  },
  {
    id: 3,
    text: "Preventive or perfective maintenance is about detecting and correcting latent issues before they cause problems.",
  },
  {
    id: 4,
    text: (
      <>
        <h5>Reactive support</h5>
        <p>It is the support that is triggered by a request from a customer.</p>
      </>
    ),
  },
  {
    id: 5,
    text: (
      <>
        <h5>End user support</h5>
        <p>
          It is the support that is provided to the end users. The first-level
          support team provides this support.
        </p>
      </>
    ),
  },
  {
    id: 6,
    text: (
      <>
        <h5>Application support</h5>
        <p>
          It is the support that is provided to the application. The
          second-level support team provides this support.
        </p>
      </>
    ),
  },
  {
    id: 7,
    text: (
      <>
        <h5>Development support</h5>
        <p>
          It is the support that is provided to the application. The third-level
          support team provides this support.This support is also known as
          custom development.
        </p>
      </>
    ),
  },
  {
    id: 8,
    text: (
      <>
        <h5>Proactive support </h5>
        <p>
          Support is the process of helping customers to solve problems and to
          get the most out of their products. It includes both reactive and
          proactive activities.
        </p>
      </>
    ),
  },
  {
    id: 9,
    text: (
      <>
        <h5>Reactive support</h5>
        <p>
          This activities are the ones that are performed to solve a problem or
          an issue reported by the customer.
        </p>
      </>
    ),
  },
  {
    id: 10,
    text: (
      <>
        <h5>Aggressive support</h5>
        <p>
          This activities are the ones that are committed to preventing problems
          or issues from occurring.
        </p>
      </>
    ),
  },
  {
    id: 11,
    text: (
      <>
        <h6>Model 1: Hire people to work on the software.</h6>
        <h6>Duration: 4-8 weeks.</h6>
        <p>
          {" "}
          Origin Softwares chooses the right people to work on the software.
          They need to know how to work on the software. They need to know how
          to fix bugs and make the software work better.{" "}
        </p>
        <h6>Duration: 1-4 weeks.</h6>
        <p>
          Origin Softwares chooses the proper testing tools and the right
          operating system for the software. It takes 1-4 weeks.
        </p>
        <h6>BEST PRACTICES</h6>
        <p>
          Origin Softwares uses tools that are multipurpose and can be used to
          monitor the performance of an application. Origin software customizes
          these tools to adjust them to the application's specific needs.
        </p>
      </>
    ),
  },
  {
    id: 12,
    text: (
      <>
        <h6>Model 2 :Outsourced software maintenance</h6>
        <p>
          To choose the right vendor, you should look at the top listings of
          software development companies.
        </p>
        <h6>Most of them offer maintenance services. </h6>
        <p>
          You should pick 3-5 companies with good experience with ITIL and
          DevOps practices, such as Microsoft certifications, cloud provider
          certifications (like Certified Solution Architect, Certified SysOps
          Administrator by AWS), or Red Hat certifications.
        </p>
        <p> Then you should send them an RFP with your needs.</p>
      </>
    ),
  },
  {
    id: 13,
    text: (
      <>
        <h6>Consider Professional Software Maintenance Services</h6>
        <p>
          Regarding software support and maintenance, our consultants have the
          experience and expertise you need to get the job done right.
        </p>
        <p>
          {" "}
          We offer feasibility studies, process design, launch planning, and
          more to help you get your project off the ground.{" "}
        </p>
        <p>
          We can also provide outsourced support and maintenance services,
          including infrastructure support, proactive monitoring, and issue
          resolution. Whatever you need, we're here to help.
        </p>
      </>
    ),
  },
  {
    id: 14,
    text: (
      <>
        <h6>Benefits of 'Origin Softwares' Software Maintenance and Support</h6>
        <p>
          Before we begin, we sit down with our customers and carefully assess
          the TCO and ROI of the software product they want us to support. It
          helps us identify improvements we can make that will have a real
          financial impact.
        </p>
        <p>
          We also focus on KPIs, so our customers can see how our services
          impact critical areas like application availability, user
          satisfaction, and Mean Time to Recovery.
        </p>
        <p>
          Finally, we're flexible in pricing and SLA, so our customers can get
          the support they need without breaking the bank.
        </p>
        <p>Long-term collaboration</p>
      </>
    ),
  },
];

const SoftwareMaintenanceAndSupport = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>Software Maintenance and Support | Origin software</title>
          <link rel="canonical" href="./Software-Maintenance-and-Support" />
          <meta
            name="description"
            content="Improve the performance and bottom-line profitability of your business with professional software maintenance, support and consulting services from Origin "
          />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Software Development"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Software-Consulting"
                                  }
                                >
                                  {" "}
                                  Software Consulting
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Custom-Software-Development-Company-in-India"
                                  }
                                >
                                  Custom Software Development
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Software-Development-Outsourcing"
                                  }
                                >
                                  {" "}
                                  Software Development Outsourcing
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Software-Product-Development"
                                  }
                                >
                                  {" "}
                                  Software Product Development
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Software-Development-Automation"
                                  }
                                >
                                  {" "}
                                  Software Development Automation
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Software-Maintenance-and-Support"
                                  }
                                >
                                  {" "}
                                  Software Maintenance and support
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Software Maintenance and Support</h1>
                  <div>
                    <img
                      className="img mb--20"
                      src="../images/Software Maintenance and Support.png"
                      alt="Software-Maintenance-and-Support"
                    />
                  </div>
                  <p>
                    Origin Softwares offers software maintenance and support
                    services since 9 Years.
                    <br />
                    There are other software maintenance and support types:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      corrective, adaptive, and perfective.
                    </span>
                  </p>
                  <div className="row mb--20">
                    {allData.slice(0, 3).map((item) => (
                      <div className="Box3 col-xl-4 col-lg-4">
                        <div key={item.id} className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p> {item.text} </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <img
                    className="thumbnail"
                    src="../images/InfoGraphics/keysteps.png"
                    alt="Thumbnail"
                  />

                  <div className="row">
                    {jsonData.services.map((service, index) => (
                      <div key={index} className="col-xl-6 col-lg-2">
                        <ul className="b">
                          <li>
                            <a href={process.env.PUBLIC_URL + service.link}>
                              {service.title}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>

                  <div>
                    <h4 className="mb--20">Our Industries</h4>
                    <Industries />
                  </div>

                  <h4 className="mb--20">
                    Software Maintenance &amp; Support Setting up Plan
                  </h4>
                  <p>
                    At Origin Softwares, we help companies set up and run
                    preventive maintenance, software testing, development
                    testing, performance testing, and on-demand support of
                    applications and their infrastructures. So we're here to
                    assist if you need help getting your software maintenance
                    and support plan up and running.
                  </p>

                  <div className="row mb--20">
                    <h4>
                      {" "}
                      Step 1: Estimation of the maintenance and support scope
                      and required resources
                    </h4>
                    <div className="paper col-xl-12 col-lg-2">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <h5>
                            {" "}
                            Duration: 1 week (in-house), 2-4 weeks (outsourced){" "}
                          </h5>
                          <p>
                            The first step Origin Softwares takes when setting
                            up software support and maintenance is to estimate
                            the scope of the activities and the required
                            resources. It is done by analyzing the software's
                            related infrastructure, new features and changes,
                            and the current and planned user pool. The
                            estimation process takes 1 week when done in-house
                            and 2-4 weeks when outsourced.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb--20">
                    <h4>
                      Step 2: Software maintenance and support process design
                    </h4>
                    {allData.slice(3, 10).map((item) => (
                      <div key={item.id} className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row paper mb--20">
                    <h6 className="title">
                      The following list shows the support activities that can
                      include in the proactive support process:
                    </h6>
                    <div className="col-xl-6 col-lg-5">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <ul className="b">
                            <li>Software availability</li>
                            <li>Performance</li>
                            <li>Security</li>
                            <li>Compliance monitoring and management</li>
                            <li>
                              QA activities (including regular code reviews)
                            </li>
                            <li>
                              Infrastructure optimization (e.g., cloud resource
                              consumption optimization)
                            </li>
                            <li>
                              Depending on the situation, these activities are
                              performed by the origin software and the customer.
                            </li>
                            <li>
                              A KPI is a tool used to measure the performance of
                              a process or a system. Origin software defines
                              KPIs to measure the performance of these
                              activities.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left shadow-box mb--30">
                          <img
                            className="img"
                            src="../images/InfoGraphics/proactivesupport.png"
                            alt=""
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb--20">
                    <h4>
                      {" "}
                      Step 3: Selecting the suitable sourcing model and proper
                      implementation
                    </h4>
                    {allData.slice(10, 12).map((item) => (
                      <div key={item.id} className="Box col-xl-7 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row mb--20">
                    <h4>Step 4: Software maintenance &amp; support launch</h4>
                    <div className="paper col-xl-12 col-lg-2">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <p>
                            At Origin Softwares, we understand that product
                            managers are under a lot of pressure to deliver.{" "}
                            <br />
                            That's why we offer a variety of software
                            maintenance and life cycle support and service level
                            options that can help relieve some of that pressure.{" "}
                            <br />
                            Our team is experienced in application support, IT
                            service management, and automation software code
                            language, so we know how to keep your project on
                            track.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <h4>Consider Professional Software Maintenance Services</h4>
                    {allData.slice(12, 14).map((item) => (
                      <div key={item.id} className="Box col-xl-5 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <h5>
                    Technologies Origin Softwares Uses for Software Maintenance
                    and Support.
                  </h5>
                  <img
                    className="thumbnail"
                    src="../images/InfoGraphics/maintainencetools.png"
                    alt="Thumbnail"
                  />
                  <div>
                    <Technology />
                  </div>

                  <Accordion>
                    <AccordionItem
                      eventKey="1"
                      header="Software Maintenance and Support Costs"
                      body={
                        <>
                          <p>
                            At Origin Softwares, we know that software
                            maintenance plans and support can be costly-
                            sometimes as high as 70% of the total cost of
                            ownership (TCO).
                          </p>
                          <p>
                            To help offset these costs, we always choose the
                            most cost-effective and efficient tools and
                            technologies for monitoring, detecting, and fixing
                            software issues and test environments.
                          </p>
                          <p>
                            Additionally, we are available 24/7 to help with any
                            problems.
                          </p>
                          <p>
                            Cost factors will always play a role in
                            decision-making, especially when it comes to
                            something as crucial as software support and
                            maintenance.
                          </p>
                          <p>
                            Sourcing models can help narrow down the field, but
                            it's essential to consider all key cost factors
                            before making a final decision.{" "}
                          </p>
                          <p>
                            For in-house software maintenance, some of the key
                            cost factors to consider are:
                            <br />
                            ➔ The type and number of software and technologies
                            used, including legacy techs, integrations, and
                            customizations.
                            <br />
                            ➔ Complexity of the organization's digital
                            infrastructure and its degree of automation. <br />➔
                            Licenses for the team's tools: software monitoring,
                            configuration, help desk, and more. <br />
                            ➔Availability of complete and high-quality software
                            documentation.
                          </p>
                          <p>
                            If you're thinking about outsourcing your software
                            maintenance, there are a few essential things to
                            consider.
                            <br />
                            ➔ First, you'll need to consider the size and
                            competency of the team you're outsourcing. <br />
                            ➔ Second, you'll need to consider the pricing model
                            - will it be per ticket or by the hour? <br />
                            ➔ Third, you'll need to decide what maintenance and
                            support services you need - on-demand, continuous,
                            or both. <br />➔ Fourth, you'll need to determine
                            how many help desk tickets you need - agreed upon
                            and extra. <br />
                            ➔ Fifth, you'll need to consider the team member's
                            workload. And finally, you'll need to consider the
                            support and maintenance time coverage.
                            <br />➔ If you want to know your maintenance and
                            support costs, the origin software team is ready to
                            help. <br />➔ We can calculate the costs depending
                            on your software and its underlying infrastructure,
                            as well as the scope of support you need.
                          </p>
                          <p>
                            Some of the services we offer include scheduled
                            maintenance windows for preventive maintenance
                            activities and regular check-ins to ensure
                            everything is on track.
                          </p>
                          <p>
                            {" "}
                            We also provide various support options, so you can
                            choose the level of service that best fits your
                            needs. Contact Origin Softwares today if you're
                            looking for a partner who can help you manage your
                            project from start to finish.
                          </p>
                        </>
                      }
                    />
                  </Accordion>
                  <Link
                    to={process.env.PUBLIC_URL + "/contact"}
                    className="axil-btn btn-fill-primary btn-small"
                  >
                    {" "}
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default SoftwareMaintenanceAndSupport;
