import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import CaseStudyProp from "../component/casestudy/CaseStudyProp";
import { Helmet } from "react-helmet-async";
import GoogleTagManager from "./GTM";
import SideStickButton from "./SideStickButton";

const CaseStudy = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>Case Study | Origin software</title>
          <link rel="canonical" href="./CaseStudy" />
          <meta
            name="description"
            content="Dive deeper into the success stories of our clients with our software development case studies. we've helped businesses in various industries achieve goals."
          />
          <meta name="keywords" content="Case Study" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <BcrumbBannerOne
            title="Case Study"
            paragraph="We design and develop web and mobile applications for our clients worldwide."
            styleClass="thumbnail-3"
            mainThumb="/images/banner/banner-thumb-5.pg"
          />
          <div className="pt--250 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area">
            <div className="container">
              <CaseStudyProp />
            </div>
          </div>
          <CtaLayoutOne />
          <FooterOne parentClass="" />
        </main>
      </div>
    </>
  );
};

export default CaseStudy;
