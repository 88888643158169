import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  services: [
    { title: "SEO", link: "/SEO-Services-in-Hyderabad" },
    { title: "SMM", link: "/Social-Media-Marketing-agency-in-India" },
    { title: "PPC", link: "/PPC-Services-India" },
  ],
};

const allData = [
  {
    id: 1,
    text: "When finding the best PPC services in India, look no further than our team at _________. We specialize in helping companies maximize their ROI with our pay-per-click services, resulting in a lower cost per acquisition and a boost to your business.",
  },
  {
    id: 2,
    text: "We leverage all ad channels to ensure that your customers can reach you easily, resulting in the best return on ad spend (ROAS) you've ever seen.",
  },
  {
    id: 3,
    text: "PPC campaigns can be a great way to boost traffic, but it's important to connect with a reputable company that can provide the best services.",
  },
  {
    id: 4,
    text: "Working with the best PPC company in India allows you to enjoy watching your traffic grow exponentially in just a few weeks.",
  },
  {
    id: 5,
    text: "The beauty of Google Ads is that you can target your ads to reach a specific audience. You can target your website by location, age, gender, interests, and even past behavior. It allows you to laser focus your ad campaigns and get the most bang for your buck.",
  },
  {
    id: 6,
    text: (
      <>
        <h5>Basic demographic </h5>
        <p>
          There are a few different factors that come into play when you are
          trying to determine who your target audience is for your display ads.
          Age, location, and gender are all important things to consider.
          <br />
          Additionally, it would help if you thought about what device they are
          using. Once you understand all of these things, you can start to
          brainstorm and create a buyer's persona. It will help you to get your
          ads in front of the right people.
        </p>
      </>
    ),
  },
  {
    id: 7,
    text: (
      <>
        <h5>Set up a Campaign</h5>
        <p>
          Additionally, it would help if you thought about what device they are
          using. Once you understand all of these things, you can start to
          brainstorm and create a buyer's persona. It will help you to get your
          ads in front of the right people.
          <br />
          We have a long history of running successful campaigns for our
          clients, and we know how to get the most out of your budget.
        </p>
      </>
    ),
  },
  {
    id: 8,
    text: (
      <>
        <h5>Targeted Keyword </h5>
        <p>
          Targeting keywords can help you reach the right audience. For example,
          if someone searches for "men's shoes" and you appear at the top of the
          results, they're more likely to buy from you. It can accomplish with
          keyword targeting in Google Ads.{" "}
        </p>
      </>
    ),
  },
  {
    id: 9,
    text: (
      <>
        <h5>PPC audit services</h5>
        <p>
          At our PPC agency, we offer PPC audit services to help you
          troubleshoot why your rank may have dropped or to give you ideas on
          how to improve your ads' performance. Google penalties can be
          frustrating, but our team can help get your campaign back on track.
        </p>
      </>
    ),
  },
  {
    id: 10,
    text: (
      <>
        <h5>Native Advertising</h5>
        <p>
          Our PPC Management company in India has a lot of experience with
          native advertising. We understand what it takes to make an effective
          native ad campaign.
          <br />
          If you're looking for a way to reach your target audience and deliver
          your message in a way that resonates with them, native advertising
          services are a great option.
        </p>
      </>
    ),
  },
  {
    id: 11,
    text: (
      <>
        <h5>Social Media Advertising</h5>
        <p>
          There's no one-size-fits-all solution when it comes to social media
          advertising. The key is creating a custom strategy tailored to your
          specific business needs.
          <br />
          To do that, you'll need to consider which platforms make the most
          sense for you. LinkedIn, Instagram, Twitter, and Facebook are all
          great options, but it ultimately depends on where your target audience
          spends their time online.
        </p>
      </>
    ),
  },
  {
    id: 12,
    text: (
      <>
        <h5>Local Service Ads</h5>
        <p>
          If you're looking to connect with potential customers searching for
          the services you provide, Local Service Ads can be a great way to
          reach them. You'll only pay for leads, not clicks, making it a
          cost-effective way to market your business.
        </p>
      </>
    ),
  },
  {
    id: 13,
    text: (
      <>
        <h5>Search Ads</h5>
        <p>
          If you are looking for a way to reach a targeted audience on Google
          and Bing ads that may be more inclined to purchase your product or
          services, Search Ads may be the solution you are looking for. By
          placing ads on these search engines, you can specifically target users
          who may be interested in what you have to offer.
        </p>
      </>
    ),
  },
  {
    id: 14,
    text: (
      <>
        <h5>PPC Remarketing Services </h5>
        <br />
        <p>
          Remarketing is a great way to show ads to people who have already
          interacted with your business or website.
        </p>
      </>
    ),
  },
  {
    id: 15,
    text: (
      <>
        <h5>Integrated Digital Marketing Services</h5>
        <p>
          At its core, your company must have a story. As an internet marketing/
          digital marketing agency, it is our responsibility to tell that story
          to your audience and explain why your company is the best solution to
          their problems.
        </p>
      </>
    ),
  },
  {
    id: 16,
    text: (
      <>
        <h5>Amazon PPC advertising services</h5>
        <p>
          Through Amazon PPC, brands, agencies, and third-party sellers can
          target specific keywords to create advertisements for their products,
          which appear in Amazon's search results and competitor product
          listings
        </p>
      </>
    ),
  },
];

const PPC = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>
            PPC Services India | PPC Marketing agency | Origin software
          </title>
          <link rel="canonical" href="./PPC-Services-India" />
          <meta
            name="description"
            content="PPC Services India - We offer the best PPC services in India. Our team is well versed with Google Adwords and can help your business to become more visible PPC services India"
          />
          <meta name="keywords" content="PPC services India" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Digital Marketing"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/SEO-Services-in-Hyderabad"
                                  }
                                >
                                  SEO
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Social-Media-Marketing-agency-in-India"
                                  }
                                >
                                  SMM
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/PPC-Services-India"
                                  }
                                >
                                  PPC
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Pay-Per-Click(PPC) Marketing</h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/PPC.png"
                    alt="PPC-Services-India"
                  />
                  <p>
                    Skyrocket your reach, traffic, and ROIs
                    <br />
                    <br />
                    At Origin Softwares PPC services India, we focus on results.
                    Our team of pay-per-click advertising experts is dedicated
                    to bringing you the best possible return on investment for
                    your company. Among the advertising companies, origin
                    softwares is the
                    <span style={{ fontWeight: "bold" }}>
                      Top Google PPC company in Hyderabad, India.
                    </span>{" "}
                    Contact us today to learn more about how we can help you
                    grow your business.
                    <br />
                    <br />
                    Our pay-per-click(PPC) experts help you get started with
                    your digital marketing efforts. Request a free consultation
                    today and get started on your path to success.
                  </p>

                  <div className="row mb--20">
                    <h4 className="mb--20">Best PPC Services in India</h4>
                    {allData.slice(0, 5).map((item) => (
                      <div className="Box1 col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Technologies we Offer</h3>
                    <div>
                      <Technology />
                    </div>
                  </div>

                  <div className="row mb--20">
                    {allData.slice(5, 13).map((item) => (
                      <div className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Our Industries</h3>
                    <div>
                      <Industries />
                    </div>
                  </div>

                  <div className="row">
                    {allData.slice(13, 16).map((item) => (
                      <div className="Box3 col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <br />

                  <h4 className="mb--20">Professional Branding</h4>
                  <p className="mb--20">
                    Your brand is what makes you unique. And when it comes to
                    branding, there is no better and more cost-effective
                    solution than online marketing. At our company, we
                    specialize in turning a business into a brand.
                  </p>

                  <h6>Web Design Services</h6>
                  <div className="row">
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className="b">
                          <li>
                            <a href={process.env.PUBLIC_URL + services.link}>
                              {services.title}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>

                  <Accordion>
                    <AccordionItem
                      eventKey="1"
                      header="Why use a PPC Management Agency?"
                      body={
                        <>
                          <ul>
                            ► PPC is an ongoing process that is always changing
                            and evolving. Just like you wouldn't set your
                            fitness goals for the year and then never revisit
                            them or change them, you also can't set your PPC ads
                            goals and leave them be. <br />
                            ► You must constantly monitor and adjust your
                            campaigns to ensure they are performing well and
                            achieving your desired results. Additionally, while
                            anyone can technically set up a PPC campaign, it
                            takes a skilled and experienced PPC agency like
                            origin softwares to maximize your ROI.
                            <br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="2"
                      header="What are PPC services?"
                      body={
                        <>
                          <ul>
                            ► PPC stands for pay per click &amp; PPC management
                            services are a type of advertising where you pay
                            platforms like Google or Bing to increase traffic to
                            your website.
                            <br />
                            ► You only pay when someone clicks on an ad copy
                            that leads them to your site. It can be a very
                            effective way to grow your business online, but
                            working with a management service that knows what
                            they're doing to get the most out of your investment
                            is important.
                            <br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="3"
                      header="What are the different types of Google ads?"
                      body={
                        <>
                          <ul>
                            ► Regarding Google ads, there are a few different
                            types from GOOGLE PPC SERVICES. <br />
                            1. Search Network campaigns are usually text-based
                            and appear on Google Search results pages when
                            someone searches for a product or service that's
                            similar to yours.
                            <br />
                            2. Display Network campaigns are image-based ads on
                            websites or apps your customer visits.
                            <br />
                            3. Video campaigns are usually short videos that are
                            typically found right before or during YouTube
                            content.
                            <br />
                            4. Remarketing ads are designed to reconnect with
                            people who have previously been interested in your
                            site. By bringing them back, you increase the
                            chances of conversion.
                            <br />
                            5. Google shopping ads provide a visual display of
                            the products you sell, making it easier for
                            potential customers to browse and find what they are
                            looking for.
                            <br />
                            6. Local service ads help your business stand out
                            when someone is searching for a similar service in
                            their area.
                            <br />
                            7. Gmail-sponsored promotions allow ads to be
                            displayed in a user's inbox, increasing your brand's
                            visibility.
                            <br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="4"
                      header="How can PPC help grow my business?"
                      body={
                        <>
                          <ul>
                            ► PPC advertising can be a great way to grow your
                            business by generating leads and sales. When done
                            correctly, it can be a very effective marketing
                            tool. Here are a few ways that PPC can help grow
                            your business:
                            <br />
                            1. By generating leads – PPC can help you generate
                            leads by targeting potential customers who are
                            searching for your product or service.
                            <br />
                            2. Increasing sales – PPC can also help increase
                            sales by driving traffic to your website or landing
                            page.
                            <br />
                            3. By building brand awareness – PPC can help build
                            brand awareness by increasing the visibility of your
                            ads.
                            <br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="5"
                      header="How do I select the best PPC company in India?"
                      body={
                        <>
                          <ul>
                            ► There are a few things to consider when choosing a
                            PPC company in India:
                            <br />
                            1. It's important to research the various options
                            and compare their services.
                            <br />
                            2. You'll want to read online reviews from past and
                            present clients to understand their quality.
                            <br />
                            3. Asking for referrals from people you trust is
                            always a good idea.
                            <br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="6"
                      header="Why should I choose Origin Softwares for my successful PPC campaign?"
                      body={
                        <>
                          <ul>
                            ► Here are five reasons why Origin Softwares is the
                            right PPC agency for your PPC campaign needs:
                            <br />
                            1. We have a team of certified professionals who are
                            experts in PPC campaigns.
                            <br />
                            2. We create tailored campaigns based on your
                            specific goals and target market.
                            <br />
                            3. We have a proven track record of success with our
                            past clients.
                            <br />
                            4. We are always up-to-date on the latest trends and
                            changes in the PPC landscape.
                            <br />
                            5. We offer competitive pricing for our services.
                            <br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="7"
                      header="What's better? SEO vs. PPC"
                      body={
                        <>
                          <ul>
                            ► The answer to this question depends on your
                            existing circumstances, long-term goals, and market.
                            PPC and SEO each have pros and cons. Both of them
                            are reliable traffic providers. Choose PPC and SEO
                            after carefully examining your company's needs and
                            position.
                            <br />
                            ► Request a quote or call us for PPC services in
                            India
                            <br />
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default PPC;
// 590
