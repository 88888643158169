import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import TestimonialTwo from "../component/testimonial/TestimonialTwo";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  services: [
    { title: "Android Apps", link: "/Andriod" },
    { title: "iOS Apps", link: "/IOS" },
    {
      title: "React Native Mobile App",
      link: "/React-Native-App-Development-Company",
    },
    { title: "Xamarin Apps", link: "/Xamarin-App-Development" },
    { title: "Hybrid Apps", link: "/Hybrid-App-Development" },
    { title: "Mobile App Design", link: "/Mobile-App-Design-Services" },
    {
      title: "Mobile Ecommerce Apps",
      link: "/Mobile-Ecommerce-App-Development",
    },
  ],
};

const allData = [
  {
    id: 1,
    text: (
      <>
        <h5>Cross-Platform</h5>
        <p>
          React Native allows for a single codebase to be used for both Android
          and iOS platforms- saving time and resources.
        </p>
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        <h5>Trusted by Billions of Users</h5>
        <p>
          React Native was used for the Facebook and Instagram mobile apps-
          which are used by billions of users daily.
        </p>
      </>
    ),
  },
  {
    id: 3,
    text: (
      <>
        <h5>3rd Party Integrations</h5>
        <p>
          Since React Native uses JavaScript, we have access to a wide range of
          3rd party APIs and tools.
        </p>
      </>
    ),
  },
  {
    id: 4,
    text: (
      <>
        <h5>Swift Deployment</h5>
        <p>
          Mobile apps built with React Native can be quickly deployed and made
          go live, due to most of the code being reusable, and a seamless
          modular structure.
        </p>
      </>
    ),
  },
  {
    id: 5,
    text: (
      <>
        <h5>Modular Structure</h5>
        <p>
          React Native's modular structure is key to its success: the same
          modules can be used across different APIs for fast results.
        </p>
      </>
    ),
  },
  {
    id: 6,
    text: (
      <>
        <h5>Powerful Performance</h5>
        <p>
          The platform is also powerful when it comes to performance, making use
          of GPU (Graphics Processing Unit) rather than CPU (Central Processing
          Unit) to ensure a smooth experience.
        </p>
      </>
    ),
  },
];

const ReactNativeMobileApp = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>
            React Native app development company India | Origin software
          </title>
          <link rel="canonical" href="./React-Native-App-Development-Company" />
          <meta
            name="description"
            content="Origin software is a coming up React Native app development company in India we offers React Native based app development services."
          />
          <meta
            name="keywords"
            content="react native app development company"
          />
        </Helmet>
        <main className="main wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Mobile Apps"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link to={process.env.PUBLIC_URL + "/Andriod"}>
                                  Android Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link to={process.env.PUBLIC_URL + "/IOS"}>
                                  iOS Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/React-Native-App-Development-Company"
                                  }
                                >
                                  React Native Mobile App
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Xamarin-App-Development"
                                  }
                                >
                                  Xamarin Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Hybrid-App-Development"
                                  }
                                >
                                  Hybrid Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Mobile-App-Design-Services"
                                  }
                                >
                                  Mobile App Design
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Mobile-Ecommerce-App-Development"
                                  }
                                >
                                  Mobile Ecommerce Apps
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">React Native App Development </h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/React Native App Development.png"
                    alt="React-Native-App-Development-Company"
                  />
                  <p>
                    Choosing our React Native app development company means
                    partnering with a team of creative professionals who will
                    amplify your vision and help you quickly achieve business
                    growth with react native app development services.
                    <br />
                    We have the experience and expertise to take your vision and
                    turn it into a reality, faster than you ever thought
                    possible.
                  </p>

                  <h4 className="mb--20">Success Stories</h4>
                  <p>
                    We have inspired success stories from all of the React
                    Native app development services we have used in the past.
                    Our code and design skills have allowed us to change the
                    game for many businesses.
                    <br />
                    You can check our inspiring success stories to see our
                    dedication and passion for what we do.
                  </p>

                  <div className="row">
                    <h4 className="mb--20">Our featured clients</h4>
                    <TestimonialTwo />
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Technologies we Offer</h3>
                    <div>
                      <Technology />
                    </div>
                  </div>

                  <div className="row mb--20">
                    <div className="paper col-xl-12 col-lg-2">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <h4>
                            Advantages of using React Native for mobile app
                            development
                          </h4>
                          <p>
                            <ul className="b">
                              React Native has quickly become one of the most
                              popular mobile app development platforms for
                              developers all over the world. Here are some of
                              the main benefits of using React Native for
                              developing your mobile app:
                              <br />
                              <li>
                                When it comes to creating a fast and responsive
                                user interface, React Native excels.
                              </li>
                              <li>
                                React Native is a cross-platform framework,
                                which means that you can develop your app for
                                both iOS and Android using the same codebase.
                              </li>
                              <li>
                                There is a large community of React Native
                                developers who are always willing to help out or
                                answer any questions you may have.
                              </li>
                              <Link
                                to={process.env.PUBLIC_URL + "/Portfolio"}
                                className="axil-btn btn-fill-primary btn-small"
                              >
                                Portfolio
                              </Link>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb--20">
                    {allData.slice(0, 6).map((item) => (
                      <div className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Our Industries</h3>
                    <div>
                      <Industries />
                    </div>
                  </div>

                  <div className="row">
                    <h4>Mobile App Services</h4>
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className="b">
                          <li>
                            <a href={process.env.PUBLIC_URL + services.link}>
                              {services.title}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>

                  <p>
                    React Native is a popular choice for cross-platform mobile
                    app development, and our React Native app development
                    company offers services to help you make the most of this
                    powerful tool.
                  </p>
                  <p>
                    We offer consultation services to help you choose the right
                    platform and features for your next mobile app project, as
                    well as full-fledged, react native app development services
                    that cover everything from wire-framing and prototyping to
                    design, development, testing, and launch.
                    <br />
                    <Link
                      to={process.env.PUBLIC_URL + "/contact"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      Contact Us
                    </Link>
                  </p>
                  <Accordion>
                    <h4 className="color">React Native App FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="Why are we the top-rated React Native app development company?"
                      body={
                        <>
                          <ul>
                            ► We are the top-rated React Native app development
                            company because we believe in the power of
                            human-centered design combined with the features of
                            React Native.
                            <br />
                            ► Our experience is unmatched, as we have delivered
                            next-gen mobile app development services to hundreds
                            of global clients across different industries for
                            over 13 years. <br />
                            ► We are consistent in our delivery and have a track
                            record of success, having delivered more than 2000
                            mobile apps, digital portals, and business
                            solutions. <br />► Our team is our most valuable
                            asset, as each member brings something unique to the
                            table that helps us create amazing solutions for our
                            clients.
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default ReactNativeMobileApp;
