import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const PromotionalMaterial = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>High-quality Promotional Products | Origin software</title>
          <link rel="canonical" href="./Promotional-Products" />
          <meta
            name="description"
            content="Origin software is a company that provides a unique solution for business promotion by developing customized promotional products."
          />
          <meta name="keywords" content="PROMOTIONAL PRODUCTS" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Coporate Branding"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Logo-Design-Company-in-India"
                                  }
                                >
                                  Logo Design
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Presentation-Design-India"
                                  }
                                >
                                  Presentation
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Brochure-Design-India"
                                  }
                                >
                                  Brochure
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Promotional-Products"
                                  }
                                >
                                  Promotional Material
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Promotional Materials</h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/Promotional Video.png"
                    alt="Promotional-Products"
                  />
                  <p className="mb--20">
                    Looking for the perfect promotional item? Whatever you have
                    in mind, we can make it happen. From mugs to handbags to
                    button pins, we guarantee that our products are of the
                    highest quality.
                  </p>
                  <p>
                    We take care of everything from the logo design to the
                    smallest details, and we offer our services at highly
                    competitive prices. We are a team of professionals who are
                    dedicated to producing the best possible results.
                    <br />
                    <br />
                    Contact us and let’s explore the possibilities.
                    <br />
                    <Link
                      to={process.env.PUBLIC_URL + "/contact"}
                      className="axil-btn btn-fill-primary btn-small"
                    >
                      Contact Us
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default PromotionalMaterial;
