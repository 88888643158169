import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  services: [
    { title: "Android Apps", link: "/Andriod" },
    { title: "iOS Apps", link: "/IOS" },
    {
      title: "React Native Mobile App",
      link: "/React-Native-App-Development-Company",
    },
    { title: "Xamarin Apps", link: "/Xamarin-App-Development" },
    { title: "Hybrid Apps", link: "/Hybrid-App-Development" },
    { title: "Mobile App Design", link: "/Mobile-App-Design-Services" },
    {
      title: "Mobile Ecommerce Apps",
      link: "/Mobile-Ecommerce-App-Development",
    },
  ],
};

const allData = [
  {
    id: 1,
    text: (
      <>
        <h5>The Power of C#</h5>
        <p>
          At Origin Softwares, our experienced developers have mastered the art
          of using Xamarin to create applications with 100% native code. With
          over 85 successfully completed projects under our belt, we know how to
          make the most of this powerful framework. Xamarin offers a vast scope
          of functionality and is widely respected for its native platform
          support. However, this can also make it difficult to handle. But with
          our team of C
        </p>
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        <h5>Industries and Possibilities</h5>
        <p>
          We at Origin Softwares develop mobile applications using Xamarin,
          which is advantageous for apps of any complexity. With Xamarin, you
          get an app with native performance, 100% hardware usage, and high FPS.
          This ensures easy realization and support of the following:
        </p>
      </>
    ),
  },
  {
    id: 3,
    text: "The user interface is extremely important for users. At Origin Softwares, we use Xamarin to offer two ways to implement the interface in your application:",
  },
  {
    id: 4,
    text: "Xamarin.Forms – This results in a cozy and intuitive one-size-fits-all design with totally native controls for Android and iOS.",
  },
  {
    id: 5,
    text: "Xamarin.iOS and Xamarin.Android – your cross-platform application will have truly native interfaces, separately developed by our Xamarin experts.",
  },
  {
    id: 6,
    text: "If you are uncertain about what technology to choose – our architects will carefully examine.",
  },
];

const XamarinApps = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>Xamarin app development services | Origin softwares</title>
          <link rel="canonical" href="./Xamarin-App-Development" />
          <meta
            name="description"
            content="With Xamarin app development services, Origin softwares is offering the most robust and customizable mobile applications that address your business requirements"
          />
          <meta name="keywords" content="xamarin app development" />
        </Helmet>
        <main className="main wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Mobile Apps"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link to={process.env.PUBLIC_URL + "/Andriod"}>
                                  Android Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link to={process.env.PUBLIC_URL + "/IOS"}>
                                  iOS Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/React-Native-App-Development-Company"
                                  }
                                >
                                  React Native Mobile App
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Xamarin-App-Development"
                                  }
                                >
                                  Xamarin Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Hybrid-App-Development"
                                  }
                                >
                                  Hybrid Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Mobile-App-Design-Services"
                                  }
                                >
                                  Mobile App Design
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Mobile-Ecommerce-App-Development"
                                  }
                                >
                                  Mobile Ecommerce Apps
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Xamarin App Development </h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/Xamarin App Development.png"
                    alt="Xamarin-App-Development"
                  />
                  <p>
                    Xamarin app development services: Xamarin is a powerful tool
                    for developing mobile apps that run on both iOS and Android
                    devices.{" "}
                    <span style={{ fontWeight: "bold" }}>
                      Origin Softwares has been delivering mobile apps using
                      Xamarin since 9 Years.
                    </span>
                  </p>

                  <h4 className="mb--20">Our Xamarin Mobile Apps Portfolio</h4>
                  <Link
                    to={process.env.PUBLIC_URL + "/Portfolio"}
                    className="axil-btn btn-fill-primary btn-small"
                  >
                    Portfolio
                  </Link>

                  <div className="row mb--20">
                    {allData.slice(0, 2).map((item) => (
                      <div className="Box col-xl-7 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row mb--20">
                    <h4 className="mb--20">User Interface</h4>
                    {allData.slice(2, 6).map((item) => (
                      <div className="Box3 col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Our Industries</h3>
                    <div>
                      <Industries />
                    </div>
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Technologies We Use</h3>
                    <div>
                      <Technology />
                    </div>
                  </div>

                  <div className="row">
                    <h4 className="mb--20">Mobile App Services</h4>
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className="b">
                          <li>
                            <a href={process.env.PUBLIC_URL + services.link}>
                              {services.title}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>

                  <Accordion>
                    <h4 className="color">Xamarin App FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="Why Origin Softwares?"
                      body={
                        <>
                          <ul>
                            ► At Origin Softwares, we pride ourselves on our
                            ability to deliver high-quality mobile applications.
                            With more than 350 delivered mobile applications,
                            our team has a thorough understanding of the mobile
                            development market. <br />
                            ► In addition to our expertise in full-cycle
                            cross-platform mobile application development, our
                            specialists are also involved in native mobile
                            development. <br />
                            ► We have already delivered projects that have
                            received praise from the public and are used by
                            major corporations such as Fox Entertainment, BBC,
                            T-Mobile, Orange, KFC, and others.
                            <br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="2"
                      header="why Xamarin?"
                      body={
                        <>
                          <ul>
                            ► Xamarin provides developers with a unique
                            opportunity to create cross-platform applications
                            using native code.
                            <br />
                            ► This means that developers can easily access all
                            platform-specific hardware and create applications
                            that look and feel like they belong on each specific
                            platform.
                            <br />
                            ►Additionally, the ability to tailor a UI to the
                            original look and feel of iOS and Android makes it
                            easy to create beautiful, functional applications
                            that will appeal to even the most dedicated platform
                            admirers.
                            <br />
                            ► Contact Xamarin app development services company
                            now.
                            <br />
                            <Link
                              to={process.env.PUBLIC_URL + "/contact"}
                              className="axil-btn btn-fill-primary btn-small"
                            >
                              Contact Us
                            </Link>
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default XamarinApps;
