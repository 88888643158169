import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import SideStickButton from "./SideStickButton";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {<div className="color1">{header}</div>}
      </Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  services: [
    { title: "Android Apps", link: "/Andriod" },
    { title: "iOS Apps", link: "/IOS" },
    {
      title: "React Native Mobile App",
      link: "/React-Native-App-Development-Company",
    },
    { title: "Xamarin Apps", link: "/Xamarin-App-Development" },
    { title: "Hybrid Apps", link: "/Hybrid-App-Development" },
    { title: "Mobile App Design", link: "/Mobile-App-Design-Services" },
    {
      title: "Mobile Ecommerce Apps",
      link: "/Mobile-Ecommerce-App-Development",
    },
  ],
};

const allData = [
  {
    id: 1,
    text: "Hybrid app development is a popular choice for many companies and developers because it takes advantage of native app features while also putting them on the path toward HTML5 mobile app development.",
  },
  {
    id: 2,
    text: "Essentially, a hybrid app is a native app that runs its user interface in an embedded browser component. To users, there is not much difference between native apps and hybrid apps.",
  },
  {
    id: 3,
    text: "They are both downloaded from app stores, stored on mobile devices, and launched in the same way. The main difference is in the development process.",
  },
  {
    id: 4,
    text: "At some of the most recognizable brands on the planet, we inspire possibility and apply innovation.",
  },
];

const HybridApps = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />;
  return (
    <>
      <div>
        <Helmet>
          <title>Hybrid App Development services | Origin softwares</title>
          <link rel="canonical" href="./Hybrid-App-Development" />
          <meta
            name="description"
            content="Hybrid App Development services | Origin software is a top-notch company that provides efficient services for Hybrid mobile app development."
          />
          <meta name="keywords" content="Hybrid app development" />
        </Helmet>
        <main className="main wrapper">
          <HeaderOne />
          <SideStickButton />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Mobile Apps"
                        body={
                          <>
                            <ul className="a">
                              <li>
                                <Link to={process.env.PUBLIC_URL + "/Andriod"}>
                                  Android Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link to={process.env.PUBLIC_URL + "/IOS"}>
                                  iOS Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/React-Native-App-Development-Company"
                                  }
                                >
                                  React Native Mobile App
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Xamarin-App-Development"
                                  }
                                >
                                  Xamarin Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Hybrid-App-Development"
                                  }
                                >
                                  Hybrid Apps
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Mobile-App-Design-Services"
                                  }
                                >
                                  Mobile App Design
                                </Link>
                              </li>
                              <br />
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/Mobile-Ecommerce-App-Development"
                                  }
                                >
                                  Mobile Ecommerce Apps
                                </Link>
                              </li>
                            </ul>
                          </>
                        }
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Hybrid App Development </h1>
                  <img
                    className="img mb--20"
                    src="../images/Slider/Hybrid App Development.png"
                    alt="Hybrid-App-Development"
                  />
                  <p>
                    At Origin Softwares, we understand that when it comes to
                    developing a hybrid app, you need a hybrid app development
                    services team that has more than just the basics covered.
                    That's why our team of experts includes qualified UX and
                    visual designers, as well as software engineers with a deep
                    understanding of mobile web development technologies.
                  </p>
                  <p>
                    With our robust and high-performing solutions, you can be
                    confident that your app will be scalable and secure, able to
                    handle any business or IT needs.
                  </p>

                  <div className="row">
                    <h4>
                      Origin Softwares as a Hybrid App Development Company
                    </h4>
                    {allData.slice(0, 4).map((item) => (
                      <div className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Technologies we Offer</h3>
                    <div>
                      <Technology />
                    </div>
                  </div>

                  <div className="row">
                    <h4 className="mb--20">Mobile App Services</h4>
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className="b">
                          <li>
                            <a href={process.env.PUBLIC_URL + services.link}>
                              {services.title}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className="mb--20">Our Industries</h3>
                    <div>
                      <Industries />
                    </div>
                  </div>

                  <Accordion>
                    <h4 className="color">Hybrid App FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="Why Origin Softwares?"
                      body={
                        <>
                          <ul>
                            ► We're experts in Hybrid Mobile App Development,
                            Hybrid Mobile App Design, PhoneGap Development,
                            AngularJS Development, Backbone.js Development,
                            Ember.js Development, Node.js Development, HTML5
                            Mobile Development, SproutCore Development,
                            Cross-platform Development, HTML5 Development, HTML5
                            Design, and Multi-platform Development.
                            <br />
                            ► We're a company that specializes in Hybrid App
                            Development, and we're dedicated to creating amazing
                            mobile experiences. Our process is agile and
                            collaborative, and we're focused on communication
                            and transparency with our clients.
                            <br /> <br />
                            <h6>Add Portfolio</h6>
                            ► We have a team of 300+ experts in global offices,
                            and our rates are competitive. We also have
                            front-end specialists who are experienced in
                            business analysis, UX/UI design, and more.
                            <br />
                            ► Our company has a proven track record of
                            successful project and engagement delivery. We offer
                            rapid, cost-effective services for mobile web
                            development, and our team of experts rigorously
                            tests all features before “Go-Live” events.
                            <br />
                            ► Our solid open technology expertise across
                            multiple platforms makes us the perfect partner for
                            your next hybrid app development project.
                            <br />
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default HybridApps;
